import React,{useState,useEffect} from 'react'
import { Button,Grid,Box, Container, TextField, Select, MenuItem, InputLabel, FormControl, Paper } from '@mui/material'
import useForm from './useForm'
import { format } from 'date-fns'


const initialFieldValues = {
    _id: '',
    serviceName:'',
    bookingDate:format(new Date(), 'MM/dd/yyyy'),
    bookingTime:'', 
    customerName:'',
    customerPhone:'',
    customerEmail:'',
    //status:''
    
}

const servicesTab = [
    "Damp traditionel",
    "Remise en forme : 3 séances",
    "Damp post accouchement",   
    "Damp post accouchement 4 séances",
    "Damp malikane",
]

const statutTab = [
    "true",
    "false",
]


function ReservationForm({addOrEdit,...props}) {

    const  [times, setTimes] = useState([
        "9h",
        "9h30",
        "10h",
        "10h30",
        "11h",
        "11h30",
        "12h",
        "12h30",
        "13h",
        "13h30",
        "14h",
        "14h30",
        "15h",
        "15h30",
        "16h",
        "16h30",
        "17h",
        "17h30",
        "18h",
        "18h30"
    ]);

  
    const validate = () => {
        let temp = { ...errors }
        temp.bookingDate = values.bookingDate ? "" : "La date de rdv est requise"
        temp.customerName = values.customerName ? "" : "Le nom  est requis"
        temp.customerPhone = values.customerPhone ? "" : "Le numéro téléphone est requis"
        temp.bookingTime = values.bookingTime ? "" : "Choisissez une heure de rdv"
        temp.serviceName = values.serviceName ? "" : "Choisissez un service"
        setErrors({
            ...temp
        })
        return Object.values(temp).every(x => x === "")
    }

    var {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFieldValues)

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values,resetForm)
        } 
    }

  


    return (
        <Container maxWidth="md" >
           
             <form 
                noValidate 
                autoComplete="off" 
                onSubmit={handleSubmit}
             >
                              <Grid container spacing={2} >  
                                  <Grid  item lg={6} xs={6}>
                                 
                                      
                                       
                                        <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Services</InputLabel>
                                                <Select
                                                style={{marginBottom:10}} 
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    name="serviceName"
                                                    value={values.serviceName}
                                                    onChange={handleInputChange}
                                                    fullWidth
                                                    {...(errors.serviceName && { error: true, helperText: errors.serviceName })}
                                                    >
                                                    {servicesTab.map(function(val,index){
                                                        return(
                                                            <MenuItem key={index} value={val}>{val}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                        </FormControl>
                                        <TextField 
                                            style={{marginBottom:10}} 
                                                        id="date"
                                                        label="Date de rendez-vous"
                                                        type="date"
                                                        fullWidth
                                                        defaultValue={values.bookingDate}
                                                        sx={{ width: "100%" }}
                                                        onChange={handleInputChange}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        {...(errors.bookingDate && { error: true, helperText: errors.bookingDate })}
                                            />
                                       <FormControl fullWidth>
                                       <InputLabel id="demo-simple-select-label">Heures de rendez-vous</InputLabel>
                                            <Select
                                            style={{marginBottom:10}} 
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="bookingTime"
                                                value={values.bookingTime}
                                                label="Heures de reservation"
                                                onChange={handleInputChange}
                                                fullWidth
                                                {...(errors.bookingTime && { error: true, helperText: errors.time })}
                                                >
                                                {times.map(function(val,index){
                                                    return(
                                                        <MenuItem key={val} value={val}>{val}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                         </FormControl>
                                      
                                        
                                      {/*
                                          <FormControl fullWidth>
                                           <InputLabel id="demo-simple-select-label">Statut</InputLabel>
                                            <Select
                                            style={{marginBottom:10}} 
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="status"
                                                value={values.status}
                                                onChange={handleInputChange}
                                                fullWidth
                                                {...(errors.status && { error: true, helperText: errors.capacity })}
                                                >
                                                    {
                                                        statutTab.map((item)=>(
                                                                <MenuItem value={item._id}>{item.capacity} </MenuItem>
                                                        ))
                                                    }
                                             
                                            </Select>
                                           
                                         </FormControl>*/}

                                       </Grid>
                                       <Grid  item lg={6} xs={6}>
                                      
                                      
                                       <TextField 
                                          style={{marginBottom:10}} 
                                            fullWidth 
                                            variant="outlined" 
                                            name="customerName" 
                                            label="Nom Client" 
                                            value={values.customerName}
                                            type="text"
                                            onChange={handleInputChange}
                                            {...(errors.customerName && { error: true, helperText: errors.customerName })}
                                        />
                                         <TextField 
                                                style={{marginBottom:10}} 
                                                fullWidth 
                                                variant="outlined" 
                                                name="customerPhone" 
                                                label="Tel Client" 
                                                value={values.customerPhone}
                                                type="text"
                                                onChange={handleInputChange}
                                                {...(errors.customerPhone && { error: true, helperText: errors.customerPhone })}
                                            />
                                            <TextField 
                                                style={{marginBottom:10}} 
                                                fullWidth 
                                                variant="outlined" 
                                                name="customerEmail" 
                                                label="Email Client" 
                                                value={values.customerEmail}
                                                type="text"
                                                onChange={handleInputChange}
                                              
                                            />
                                           
                                       </Grid>
                                        
                                        <Box p={2}>
                                                   <Button
                                                   style={{backgroundColor:'#7faf02'}}
                                                   variant="contained"
                                                   fullWidth
                                                   size="large"
                                                   color="primary"
                                                   type="submit"
                                                   >
                                                      Valider
                                                   </Button>
                                        </Box>
                                    </Grid>
                        </form>
                      
        </Container>
    )
}

export default ReservationForm
