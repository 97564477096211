export const CUSTOMER_LIST_REQUEST = 'CUSTOMER_LIST_REQUEST';
export const CUSTOMER_LIST_SUCCESS = 'CUSTOMER_LIST_SUCCESS';
export const CUSTOMER_LIST_FAIL = 'CUSTOMER_LIST_FAIL';

export const CUSTOMER_DETAILS_REQUEST = 'CUSTOMER_DETAILS_REQUEST';
export const CUSTOMER_DETAILS_SUCCESS = 'CUSTOMER_DETAILS_SUCCESS';
export const CUSTOMER_DETAILS_FAIL = 'CUSTOMER_DETAILS_FAIL';

export const CUSTOMER_SAVE_FAIL = 'CUSTOMER_SAVE_FAIL';
export const CUSTOMER_SAVE_SUCCESS = 'CUSTOMER_SAVE_SUCCESS';
export const CUSTOMER_SAVE_REQUEST = 'CUSTOMER_SAVE_REQUEST'

export const CUSTOMER_DELETE_FAIL = 'CUSTOMER_DELETE_FAIL';
export const CUSTOMER_DELETE_SUCCESS = 'CUSTOMER_DELETE_SUCCESS';
export const CUSTOMER_DELETE_REQUEST = 'CUSTOMER_DELETE_REQUEST'