import { 
  CUSTOMER_DELETE_FAIL,
  CUSTOMER_DELETE_REQUEST,
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_DETAILS_FAIL,
  CUSTOMER_DETAILS_REQUEST,
  CUSTOMER_DETAILS_SUCCESS,
  CUSTOMER_LIST_FAIL,
  CUSTOMER_LIST_REQUEST, 
  CUSTOMER_LIST_SUCCESS, 
  CUSTOMER_SAVE_FAIL, 
  CUSTOMER_SAVE_REQUEST,
  CUSTOMER_SAVE_SUCCESS
} from "../constants/customerConstants" 
import { publiqueRequest, userRequest } from "../requestMethods"


const listCustomers = () => async(dispatch) => {
  
    try {

        dispatch({type: CUSTOMER_LIST_REQUEST})
        const {data}  = await userRequest.get('/customers.php/')
        dispatch({type: CUSTOMER_LIST_SUCCESS,payload: data})
         
    } catch (error) {
        dispatch({type: CUSTOMER_LIST_FAIL,payload: error.message})
    }
       
}

const deleteCustomer = (customerId) => async (dispatch) => {
 
  try {
    dispatch({ type: CUSTOMER_DELETE_REQUEST, payload: customerId });
    const { data } = await userRequest.delete('/customers.php/?_id=' + customerId);
    dispatch({ type: CUSTOMER_DELETE_SUCCESS, payload: data , success:true});
  } catch (error) { 
    dispatch({ type: CUSTOMER_DELETE_FAIL, payload: error.message });
  }
};



  
const saveCustomer = (customer) => async (dispatch) => {  
  try {
    dispatch({ type: CUSTOMER_SAVE_REQUEST, payload: customer });
    if(!customer._id){
      const { data } = await userRequest.post('/customers.php/', customer);   
      dispatch({ type: CUSTOMER_SAVE_SUCCESS, payload: data,success:true}); 

    }else{
      const { data } = await userRequest.put('/customers.php/',customer);    
    dispatch({ type: CUSTOMER_SAVE_SUCCESS, payload: data,success:true});
    }
  } catch (error) {
    dispatch({ type: CUSTOMER_SAVE_FAIL, payload: error.message });
  }
};  



const detailsCustomer = (customerId) => async (dispatch) => {
    try {
      dispatch({ type: CUSTOMER_DETAILS_REQUEST, payload: customerId });
      const { data } = await userRequest.get('/customers.php/?_id=' + customerId);
      dispatch({ type: CUSTOMER_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: CUSTOMER_DETAILS_FAIL, payload: error.message });
    }
  };

 

 export {
    listCustomers,
    detailsCustomer,
    saveCustomer ,
    deleteCustomer
}