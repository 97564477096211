import React, { Fragment, useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { getMonth, translateMonth, translateSmallCalendar } from '../../utils/utils';
import { Typography,Box, IconButton, Grid } from '@mui/material'
import GlobalContext from '../../context/GlobalContext';


function SmallDashboardCalendar() {

    const [currentMonthIndex,setCurrentMonthIndex] = useState(dayjs().month());
    const [currentMonth,setCurrentMonth] = useState(getMonth())
    const {monthIndex,setSmallCalendarMonth,daySelected,setDaySelected,savedEvents} = useContext(GlobalContext)
    
  
    useEffect(() => {
     setCurrentMonth(getMonth(currentMonthIndex))
    }, [currentMonthIndex]);

    useEffect(() => {
        setCurrentMonthIndex(monthIndex)
       }, [monthIndex]);


    function handlePrevMonth() {
        setCurrentMonthIndex(currentMonthIndex -1);
    }
    function handleNextMonth() {
        setCurrentMonthIndex(currentMonthIndex + 1);
    }


    function getDayStyle(day) {
        const format = "DD-MM-YY";
        const nowDay = dayjs().format(format);
        const currDay = day.format(format);
        const slcDay = daySelected && daySelected.format(format);
        const data = savedEvents.find((element) => element.day === day.valueOf())
        if(data){
            return "#7faf02"
        }else if(nowDay === currDay){ 
                return "blue"
            }else if (currDay === slcDay){
                return "#175fac78"
            }else{
                return "white"
            }
       
       
        
    }

    function markDay(day){
        for(let i = 0; i<savedEvents.length;i++){
            
        }
    }

    function getDayColor(day) {
        const format = "DD-MM-YY";
        const nowDay = dayjs().format(format);
        const currDay = day.format(format);
        const slcDay = daySelected && daySelected.format(format);
        if(nowDay === currDay){
            return "white"
        }else if (currDay===slcDay){
            return "#fff"
        }else{
            return "black"
        }
        
    }

    return (
        <Box
           justifyContent="center"
           style={{
               height:'350px',
               alignContent:"center",
              
           }}
        >
            <header
              style={{display:'flex',justifyContent:'space-between'}}
            >
                <Typography  
                    variant="h6" 
                    component="p" 
                    mt={1}
                    style={{
                        textAlign:"center",
                        fontWeight:"bold"
                    }}
                >
                    {translateMonth(dayjs(new Date(dayjs().year(), currentMonthIndex)).format("MMMM"))}  {" "}
                    {dayjs(new Date(dayjs().year(), currentMonthIndex)).format("YYYY")} 
                </Typography>
                <div>
                    <IconButton style={{width:30,fontSize:20}} aria-label="" onClick={handlePrevMonth} >
                    <span> {"<"} </span> 
                    </IconButton>
                    {"  "}
                    <IconButton style={{width:30,fontSize:20}} aria-label="" onClick={handleNextMonth}>
                    <span> {">"} </span> 
                    </IconButton>
                </div>
                
            </header>
            <Grid container style={{height:"90%"}} >
                { currentMonth[0].map((day,i) => (
                        <Grid key={i+day} item lg={1.7} md={1.7}   xs={1.7} >
                                <span
                                    style={{
                                        fontSize:12,
                                        padding:6,
                                        textAlign:'center',
                                        fontWeight:"bold"
                                    }}
                                >
                                   
                                    {translateSmallCalendar(day.format('dd'))}
                                </span>
                        </Grid>
                ))
                } 
                {currentMonth.map((row,i) => (
                    <Fragment key={i} >
                        {row.map((day,idx) => (
                            <Grid key={idx} item lg={1.7} md={1.7}  xs={1.7} >
                                <button
                                    style={{ 
                                        fontSize:10,
                                        padding:1,
                                        textAlign:'center',
                                        width:20,
                                        height:20 ,
                                        cursor: 'pointer',
                                        color:`${getDayColor(day)}` ,
                                        border:'none',
                                        borderRadius:50,
                                        backgroundColor:`${getDayStyle(day)}`
                                    }}
                                    onClick={()=>{
                                       // setSmallCalendarMonth(currentMonthIndex);
                                        //setDaySelected(day)
                                    }}
                                >
                                    {day.format('D')}
                                </button>
                            </Grid>
                         ))   
                        }
                    </Fragment>
                ))
                }
               
            </Grid>
        </Box>
    )
}

export default SmallDashboardCalendar
