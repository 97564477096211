
import axios from 'axios'

const BASE_AXIOS_URL = "https://cdpm.fonqsolutions.com/api-cabinet-dentaire/routes/";

const verifyUserSignin = JSON.parse(localStorage.getItem("persist:root"))
const TOKEN = verifyUserSignin ? JSON.parse(verifyUserSignin?.userSignin)?.userInfo?.accessToken : "";

export const publiqueRequest = axios.create({
    baseURL:BASE_AXIOS_URL  
});  


export const baseHostUrl = "https://cdpm.fonqsolutions.com/";  

export const baseHostUrlProfil = "https://cdpm.fonqsolutions.com/api-cabinet-dentaire/uploads/profil/";

export const baseHostUrlFiles = "https://cdpm.fonqsolutions.com/api-cabinet-dentaire/uploads/pieces_jointes/";


export const userRequest = axios.create({
    baseURL:BASE_AXIOS_URL,
    headers:{token:`Bearer ${TOKEN}`}
}); 

export const privateRequestHost = axios.create({
    baseURL:baseHostUrl,
    headers:{token:`Bearer ${TOKEN}`}
});

export const privateRequestProfil = axios.create({
    baseURL:baseHostUrlProfil,
    headers:{token:`Bearer ${TOKEN}`}
});

export const privateRequestFiles = axios.create({
    baseURL:baseHostUrlFiles,
    headers:{token:`Bearer ${TOKEN}`}
});
