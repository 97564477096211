import React, {useState,useEffect,useContext} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { 
    IconButton, 
    Typography ,
    Select,
    Button, 
    MenuItem, 
    InputLabel, 
    FormControl,
    Box,
    Grid,
    TextField,
    Container
} from '@mui/material';
import {CloseOutlined } from '@mui/icons-material';
import GlobalContext from '../../context/GlobalContext';
import { useDispatch, useSelector } from 'react-redux';
import { saveRdv,deleteRdv } from '../../../actions/rendezvousActions';
import { Check,SaveAltOutlined,Delete, Schedule } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { publiqueRequest, userRequest } from '../../../requestMethods';
import { translateLongDay, translateMonth } from '../../utils/utils';
import { scheduleRdv } from '../../../actions/scheduleActions';
import styled from 'styled-components'
import Img from '../../../images/Numérotation_dentaire.jpg'




const colorClass = ["blue","red","green","indigo","gray","purple","orange","maroon","orangered","springgreen","yellowgreen","black","olive","chocolate"];

const statusTab = 
    [
        {_id:1,value:"done",name:"fait"},
        {_id:2,value:"planned",name:"à venir"},
        {_id:3,value:"canceled",name:"annulé"}
    ]

const Autocomplete = styled.div`
     display: flex;
     justify-content:flex-start;
     cursor:pointer;
     background-color:#ccc;
     padding:2px;

     &:hover{
         background-color: #1ea7d1;
     }

`;

function EventModal() {

    const  [times, setTimes] = useState([
        "9h",
        "9h30",
        "10h",
        "10h30",
        "11h",
        "11h30",
        "12h",
        "12h30",
        "13h",
        "13h30",
        "14h",
        "14h30",
        "15h",
        "15h30",
        "16h",
        "16h30",
        "17h",
        "17h30",
        "18h",
        "18h30"
    ]);

    const userSignin = useSelector((state) => state.userSignin);

    const {
            setShowEventModal,
            daySelected,
            showEventModal,
            savedEvents,
            setSavedEvents,
            selectedEvent, 
            setShowDialog,
            getRdv,
            setFetchData
    } = useContext(GlobalContext)

    const dispatch = useDispatch();
    const [intervention,setIntervention] = useState(selectedEvent ? selectedEvent.intervention : "");
    const [another_intervention,setAnother_intervention] = useState(selectedEvent ? selectedEvent.another_intervention : "");
    const [customerId,setCustomerId] = useState(selectedEvent ? selectedEvent.customerId : "");
    const [customerName,setCustomerName] = useState(selectedEvent ? selectedEvent.customerName : "");
    const [bookingDate,setBookingDate] = useState(selectedEvent ? selectedEvent.bookingDate : "");
    const [bookingTime,setBookingTime] = useState(selectedEvent ? selectedEvent.bookingTime : "");
    const [teeth,setTeeth] = useState(selectedEvent ? selectedEvent.teeth : "");
    const [coefficient,setCoefficient] = useState(selectedEvent ? selectedEvent.coefficient : "");
    const [price,setPrice] = useState(selectedEvent ? selectedEvent.price : 0);
    const [owied,setOwied] = useState(selectedEvent ? selectedEvent.owied : 0);
    const [received,setReceived] = useState(selectedEvent ? selectedEvent.received : 0);
    const [note,setNote] = useState(selectedEvent ? selectedEvent.note : "");
    const [status,setStatus] = useState(selectedEvent ? selectedEvent.status : ""); 
  

   

    const [services,setServices] = useState([]);
    const [customers,setCustomers] = useState([]);
    const [suggestions,setSuggestions] = useState([]);

    const rendezvousSave = useSelector(state => state.rendezvousSave);
    const {loading: loadingSave,rendezvous,success: successSave, error:errorSave} = rendezvousSave;

    const rendezvousDelete = useSelector(state => state.serviceDelete);
    const {loading: loadingDelete,rendezvous:messageDelete,success: successDelete, error:errorDelete} = rendezvousDelete;

    const [selectedColor,setSelectedColor] = useState( 
        selectedEvent 
            ? colorClass.find((lbl) => lbl === selectedEvent.color)
            : colorClass[0]
    )
 
  

     
      useEffect(() => {
        const getServices = async () => {
            try {
             const res = await userRequest.get("/services.php/");
             setServices(res.data);
            } catch (err) {
              console.log(err)
            }
          }; 
           getServices();
          
          
         }, [])
        
       useEffect(() => {
                const getCustomers = async () => {
                    try {
                    const res = await userRequest.get("/customers.php/");
                    setCustomers(res.data);
                    } catch (err) {
                    console.log(err)
                    }
                };
            
               getCustomers();   
         }, [])

        const onSuggestHandler = (text) => {

            setCustomerName(text)
           // setCustomerId(item._id)
            setSuggestions([])
        }
        
       const onChangeHandler = (text) =>{
           let matches = []
           if(text.length>0){
               matches = customers.filter(usr => {
                   const regex = new RegExp(`${text.toLowerCase()}`,"gi")
                   return usr.firstname.match(regex)
               })
           }
           
           setSuggestions(matches)
           setCustomerName(text)
       }
      
         //console.log(services)
    function handleSubmit(e) {
        e.preventDefault()
        const calendarEvent = {
            intervention,
            another_intervention,
            customerId,
            customerName,
            bookingDate:daySelected.format('DD/MM/YYYY'),
            bookingTime,
            teeth,
            coefficient,
            price,
            owied, 
            received,
            note,
            day:daySelected.valueOf(),
            color: selectedColor,
            status,                                                                                                                                                                                                                                                                                                                                                             
            _id: selectedEvent ? selectedEvent._id : ""
        };

       
       
        if (calendarEvent._id === "") {
               dispatch(saveRdv(calendarEvent));
               if(rendezvous.message !== true){
                    setShowDialog(true)
               }else{ 
                    toast("Rendez-vous ajouté  avec succès!!!", {
                    type: "success",
                    theme: "colored"
                });
                setFetchData(savedEvents)
               }
         }else{
           dispatch(saveRdv(calendarEvent));
           if(rendezvous.message==true){
            toast("Rendez-vous mis à jour avec succès!!!", {
                type: "success",
                theme:"colored"
            });
          
            setFetchData(savedEvents)
           }
        } 
        setShowEventModal(false);
       
    }

    return (
        <Container maxWidth="md" >

        <Dialog 
          open={showEventModal} 
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle style={{textAlign:'center'}} >
            <div style={{display:'flex'}}>
                  <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                         Créer une nouvelle intervention
                  </Typography> 
                  {
                            selectedEvent && (
                            userSignin.userInfo.rules === "true" && (
                              <span 
                                onClick={() => {
                                    //console.log(selectedEvent);
                                    dispatch(deleteRdv(selectedEvent._id));
                                    setShowEventModal(false)
                                    if(messageDelete.message==true){
                                        toast("Rendez-vous supprimé avec succès!!!", {
                                          type: "info",
                                          theme: "colored"
                                        });
                                       
                                        setFetchData(savedEvents)
                                    }  
                                    
                                }}
                                style={{color:'red',cursor:'pointer'}} 
                              >
                                <Delete/>
                              </span>
                              )
                    )}
                    <IconButton
                          color="warning"
                          onClick={() => setShowEventModal(false)}>
                          <CloseOutlined />
                    </IconButton>
            </div>
          </DialogTitle>
          <DialogContent dividers>
              <Container>
                  <Grid conatiner>
                      <form 
                          noValidate 
                          autoComplete="off" 
                          onSubmit={handleSubmit}
                       >
                       <Grid  item lg={12} xs={12}>
                           <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Intervention</InputLabel>
                              <Select
                                 style={{marginBottom:20}} 
                                 labelId="demo-simple-select-label"
                                 id="demo-simple-select"
                                 name="intervention"
                                 value={intervention}
                                 onChange={(e)=>setIntervention(e.target.value)}
                                 fullWidth
                               >
                                  {services.map(function(val,index){
                                      return(
                                              <MenuItem onClick={()=>{setCoefficient(val.coefficient?val.coefficient:"");setPrice(val.price?val.price:"0")}} key={val._id} value={val.name}>{val.name}</MenuItem>
                                       )
                                  })}
                             </Select>
                         </FormControl>
                         <TextField
                                    style={{marginBottom:5}}
                                    fullWidth
                                    variant="outlined"
                                    label="Autre intervention"
                                    name="another_intervention"
                                    value={another_intervention}
                                    onChange={(e)=>setAnother_intervention(e.target.value)}
                                    
                                
                                    
                                />
                         <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Heure de RDV</InputLabel>
                              <Select
                                 style={{marginBottom:10}} 
                                 labelId="demo-simple-select-label"
                                 id="demo-simple-select"
                                 name="bookingTime"
                                 value={bookingTime}
                                 onChange={(e)=>setBookingTime(e.target.value)}
                                 fullWidth
                               >
                                  {times.map(function(val,index){
                                      return(
                                              <MenuItem key={index} value={val}>{val}</MenuItem>
                                       )
                                  })}
                          </Select>
                              </FormControl>
                              
                              <Box display="flex" justifyContent="space-between" >
                                      <span> <Schedule/> </span>
                                      <Typography 
                                          variant="subtitle2" 
                                          component="p"
                                      >
                                          {translateLongDay(daySelected.format("dddd"))} {", "}
                                          {daySelected.format("DD")} {" "}
                                          {translateMonth(daySelected.format("MMMM"))}
                                        
                                      </Typography>
                              </Box>
                                <Grid item xs >
                                <TextField
                                    style={{marginBottom:5}}
                                    fullWidth
                                    variant="outlined"
                                    label="Nom du patient"
                                    name="customerName"
                                    value={customerName}
                                    required
                                    onChange={(e)=>onChangeHandler(e.target.value)}
                                />
                                {suggestions && suggestions.map((item,i)=>
                                    <Autocomplete key={item._id}
                                        onClick={() => {onSuggestHandler(item.firstname);setCustomerId(item._id)}}
                                    >
                                        {item.firstname} | {item.lastname} | {item.telephone} | {item.date_naissance}
                                    </Autocomplete>
                                )}
                                </Grid>    
                            <TextField
                                  style={{marginBottom:5}}
                                  fullWidth
                                  variant="filled"
                                  label="Dent"
                                  name="teeth"
                                
                                  defaultValue={teeth}
                                  required
                                  onChange={(e)=>setTeeth(e.target.value)}
                              />
                        <Box display="flex" justifyContent="center" >
                            
                            <img style={{
                                  width: "250px",
                                  height: "250px",
                                  //filter: "invert(70%) sepia(74%) saturate(502%) hue-rotate(35deg) brightness(97%) contrast(78%)"
                                                              
                              }} src={Img} alt="logo bm repair" />
                          </Box>
                            
                               <TextField
                                  style={{marginBottom:5}}
                                  fullWidth
                                  variant="filled"
                                  label="Doit"
                                  name="owied"
                                  defaultValue={owied}
                                  
                                  onChange={(e)=>setOwied(e.target.value)}
                              />
                               <TextField
                                  style={{marginBottom:5}}
                                  fullWidth
                                  variant="filled"
                                  label="Reçu"
                                  name="received"
                                  defaultValue={received}
                                 
                                  onChange={(e)=>setReceived(e.target.value)}
                              />

                                <TextField
                                  style={{marginBottom:5}}
                                  fullWidth
                                  variant="filled"
                                  label="Note"
                                  name="note"
                                  defaultValue={note}
                                  multiline={true}
                                  rows={4}
                                  onChange={(e)=>setNote(e.target.value)}
                              />

                            
                              
                            
                                  <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">Statut</InputLabel>
                                          <Select
                                            style={{marginBottom:10}} 
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="status"
                                            value={status}
                                            onChange={(e)=>setStatus(e.target.value)}
                                            fullWidth
                                          >
                                              {statusTab.map(function(val,index){
                                                  return(
                                                          <MenuItem key={val._id} value={val.value}>{val.name}</MenuItem>
                                                  )
                                              })}
                                      </Select>
                              </FormControl>
                              <Box  display="flex" p={1}>
                                      {
                                          colorClass.map((clr,i) => (
  
                                              <span
                                                  key={i}
                                                  onClick={() => setSelectedColor(clr)}
                                                  style={{
                                                      backgroundColor:`${clr}`,
                                                      borderRadius: 50,
                                                      width:25,
                                                      height:25,
                                                      alignItems:"center",
                                                      justifyContent:'center',
                                                      color:'white',
                                                      marginRight:4,
                                                      justifyItems:'center',
                                                      cursor:'pointer',
                                                      textAlign:'center'
                                                      
                                                  }}
                                              >
                                                  {selectedColor === clr &&  (<Check fontSize="small" />)}
                                                  
                                              </span>
                                          ))
                                      }
                                  
                              </Box>
                              
                           
                              <footer    
                                  style={{
                                      display:'flex',
                                      textAlign:'center',
                                      justifyContent:'center'
                                  }}
                              >
                                  <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  startIcon={<SaveAltOutlined/>}
                                  onClick={handleSubmit}
                                  
                                  >
                                  Enrégistrer
                                  </Button>
                               </footer>
  
                                 </Grid>
                         </form>
                  </Grid>
              </Container>
          </DialogContent>
        </Dialog>
      </Container>
       
    )
}

export default EventModal
