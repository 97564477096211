import React, { useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { 
    Container, 
    Grid, 
    Paper,  
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    Divider,
    IconButton
} from '@mui/material';
import CartItem from '../components/Cards/CartItem';
import { DateRange, MedicalServices, Person, Visibility } from '@mui/icons-material';
import { useSelector,useDispatch } from 'react-redux';
import {Link, useNavigate} from 'react-router-dom'
import { publiqueRequest, userRequest } from '../requestMethods';
import ChartBar from '../components/charts/ChartBar';
import SmallDashboardCalendar from '../agenda/components/calendar/SmallDashboardCalendar';
import { Steps, Hints } from "intro.js-react";
import "intro.js/introjs.css";
import GlobalContext from '../agenda/context/GlobalContext';
import MixedChart from '../components/charts/MixedChart';
import { PersonCard } from '../components/Cards/PersonCard';
import TableCard from '../components/Cards/TableCard';





export const MainContainer = styled.div`
    width: auto;
    height: 100%;
    margin-left: 12rem;
    margin-top: 16px;
    position: relative; 
    padding: 0 1rem;
    padding-bottom: 1rem;
    color:#000;
    background-color: #e8eaeb;

    @media screen  and (max-width:1140px){
      height: 100%;
   }

    @media screen  and (max-width:1198px){
       height: 100%;
    }

    @media screen  and (max-width:1115px){
       height: 100%;
    }

    @media screen  and (max-width:1115px){
       height: 100%;
    }
   

    @media screen  and (max-width:768px){
        margin-left: 0px;
        padding: 0 0rem;
        height: 100%;
    }

    @media screen  and (max-width:1336px){
      height: 100%;
   }

`;

function Dashboard() {

      const {
        dispatchInfoEvent,
        savedInfoEvents
    } = useContext(GlobalContext)

    const [users,setUsers] = useState([]);
    const [service,setServices] = useState([]);
    const [rdv,setRdv] = useState([]);
    const [countService,setCountService] = useState(0);
    const [countUser,setCountUser] = useState(0);
    const [stats,setStats] = useState([]);
    const [countRdv,setCountRdv] = useState(0);
    const [countRdvToday,setCountRdvToday] = useState(0);

    const [stepsEnabled,setStepsEnabled] = useState(true)
    const [initialStep, setInitialStep] = useState(0)
    const [hintsEnabled,setHintsEnabled] = useState(true)
    const [steps,setSetps] = useState([
      {
        element: ".user",
        intro: "Ici vous avez le total d'utilsateurs de la plateforme"
      },
      {
        element: ".services",
        intro: "Ici vous avez le total de services"
      },
      {
        element: ".rdvDay",
        intro: "Ici vous avez le total de rendez-vous pris aujourd'hui"
      },
      {
        element: ".rdvToDay",
        intro: "Ici vous avez le total de rendez-vous  d'aujourd'hui"
      },
      {
        element: ".diagramme",
        intro: "Ce diagramme indique le total de RDV pris par mois  dans l'année en cours"
      },
      {
        element: ".calendar",
        intro: "Dans ce calendrier, les jours de rendez-vous seront marqués en vert"
      }
    ])
    const hints = [
      {
        element: ".hello",
        hint: "Salut bienvenue sur votre tableau de bord",
        hintPosition: "middle-right"
      }
    ]

    const navigate = useNavigate();
    const userSignin = useSelector((state) => state.userSignin);
   
 
    const dispatch = useDispatch();

    const MONTHS = useMemo(
      () => [
        "Jan",
        "Fev",
        "Mar",
        "Avr",
        "Mai",
        "Jui",
        "Juil",
        "Aut",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      []
    );

    

   

    useEffect(() => {
      if(userSignin.userInfo == null){
        navigate('/connexion')
      }
    }, [userSignin])

    

    useEffect(() => {
      if(savedInfoEvents !== null && savedInfoEvents[0]?.info == true){
          setStepsEnabled(false)
      }
    }, [])
  
    useEffect(() => {
        const getServices = async () => {
           try {
            const res = await userRequest.get("/services.php/");
             setServices(res.data);
           } catch (err) {
             console.log(err)
           }
         }; 
         getServices();
        
       }, [])

       useEffect(() => {
        const getCount = async () => {
           try {
            const res = await userRequest.get("/services-count.php/");
             setCountService(res.data[0].total);
             
           } catch (err) {
             console.log(err)
           }
         }; 
         getCount();
        
        
       }, [])

       useEffect(() => {
        const getCount = async () => {
           try {
            const res = await userRequest.get("/users-count.php/");
             setCountUser(res.data[0].total);
           } catch (err) {
             console.log(err)
           }
         }; 
         getCount();
       }, [])

       useEffect(() => {
        const getCount = async () => {
           try {
            const res = await userRequest.get("/rendezvous-day.php/");
             setCountRdv(res.data[0].total);
           } catch (err) {
             console.log(err)
           }
         }; 
         getCount();
       
       }, []) 

       useEffect(() => {
        const getCount = async () => {
           try {
            const res = await userRequest.get("/rendezvous-today.php/");
             setCountRdvToday(res.data[0].total);
           } catch (err) {
             console.log(err)
           }
         }; 
         getCount();
        
       }, []) 

       useEffect(() => {
        const getStat = async () => {
           try {
            const res = await userRequest.get("/rendezvous-year.php/");
            res.data.map((item) =>
              setStats((prev) => [
                ...prev,
                { name: MONTHS[item.mois - 1], "Total": item.total },
              ])
            );
           } catch (err) {
             console.log(err)
           }
         }; 
         getStat();
         
       }, [MONTHS])


       useEffect(() => {
        const getUsers = async () => {
           try {
            const res = await userRequest.get("/users.php/");
             setUsers(res.data);
           } catch (err) {
             console.log(err)
           }
         }; 
         getUsers();
        
       }, [])

       useEffect(() => {
        const getRdv = async () => {
           try {
            const res = await publiqueRequest.get("/rendez-vous.php/");
             setRdv(res.data);
           } catch (err) {
             console.log(err)
           }
         }; 
         getRdv();
        
       }, [])

     

    function renderRdv(){
       
      if(rdv.length > 0){
        return(
          rdv.slice(0,5).map((item)=>(
                 <TableRow key={item._id}
                  sx={{'&:last:child td,&:last-child th': {border:0}}}
                 >
                          <TableCell component="th" scope="row" >{item.intervention}</TableCell>
                          <TableCell >{item.customerName}</TableCell>
                          <TableCell >{item.bookingDate}</TableCell>
                          <TableCell >{item.bookingTime}</TableCell>
                          <TableCell>
                          <Link to={'/rendez-vous/'+item._id} >
                              <IconButton style={{color:'blue'}} >
                                      <Visibility fontSize="small"  />
                              </IconButton>
                          </Link>
                          </TableCell>
                         
                  </TableRow>
             ))
          )   
      }
  
   } 


    function renderUsers(){
        if(users.length > 0){
          return(
            users.slice(0,5).map((item)=>(
                   <TableRow key={item._id}
                    sx={{'&:last:child td,&:last-child th': {border:0}}}
                   >
                            <TableCell component="th" scope="row" >{item.pseudo}</TableCell>
                            <TableCell >{item.email}</TableCell>
                           
                    </TableRow>
               ))
            )   
           
        }
    } 

   const onExit = () => {
      setStepsEnabled(false);
      const infoEvent = {
        info:false
    };
    dispatchInfoEvent({type:'push',payload:infoEvent});
    };
;
  
    return (
        <>
        
          <MainContainer>
           
            <Container maxWidth={false} >
           
            
                <Grid container spacing={2}>
                     <Grid container spacing={2} item lg={6} xs={12}>
                        <Grid item lg={6} xs={6}  className="user"  >
                        
                           <Link to="/utilisateurs" style={{textDecoration:'none'}} >
                            <CartItem
                                title="Utilisateurs"
                                value={countUser}
                                backgroundColor="#00aeea"
                                icon={<Person style={{color:'#00aeea'}} />}
                            />
                          </Link>
                        </Grid>
                        <Grid item lg={6} xs={6}   className="services" >
                           <Link to="/services" style={{textDecoration:'none'}} >
                           <CartItem
                                title="Interventions"
                                value={countService}
                                backgroundColor="#7faf02"
                                icon={<MedicalServices style={{color:'#7faf02'}}  />} 
                            />
                            </Link>
                        </Grid>
                        <Grid item lg={6} xs={6}  className="rdvDay" >
                         <Link to="/rendez-vous" style={{textDecoration:'none'}}>
                           <CartItem
                                title="RDV pris du jour"
                                value={countRdv ? countRdv : 0 }
                                backgroundColor="#7faf02"
                                icon={<DateRange style={{color:'#7faf02'}}   />} 
                            />
                          </Link>
                        </Grid>
                        <Grid item lg={6} xs={6}   className="rdvToday" >
                        <Link to="/agenda" style={{textDecoration:'none'}} >
                        <CartItem
                             title="RDV du jour"
                             value={countRdvToday ? countRdvToday : 0 }
                             backgroundColor="#00aeea"
                             icon={<DateRange style={{color:'#00aeea'}}   />} 
                         />
                          </Link>
                        </Grid>
                     </Grid>
                     <Grid container  spacing={2} item lg={6} xs={12}>
                          <Grid   item lg={6} xs={12}  >
                                <Paper className="calendar" 
                                 style={{
                                      alignContent:'center',
                                      padding:10,
                                      display: "flex",
                                      borderRadius:20,
                                  
                                  }} 
                                  elevation={2}
                                 >
                                        <SmallDashboardCalendar/>
                                </Paper> 
                          </Grid>
                          <Grid item lg={6} xs={12}  className="rdvToday" >
                                <Paper 
                                    style={{
                                      width:"100%",
                                      borderRadius:20,
                                   }}
                                >                
                                        <MixedChart />           
                                </Paper>
                          </Grid>
                          
                            
                      </Grid>

                      <Grid item lg={6} xs={12} >
                           <PersonCard  users={users} />
                        </Grid>
                        <Grid item lg={6} xs={12} >
                           <TableCard  rdv={rdv} />
                          
                        </Grid>
                </Grid>
            </Container>
        </MainContainer>
        </>
      
    )
}

export default Dashboard
