export const RDV_LIST_REQUEST = 'RDV_LIST_REQUEST';
export const RDV_LIST_SUCCESS = 'RDV_LIST_SUCCESS';
export const RDV_LIST_FAIL = 'RDV_LIST_FAIL';

export const RDV_DETAILS_REQUEST = 'RDV_DETAILS_REQUEST';
export const RDV_DETAILS_SUCCESS = 'RDV_DETAILS_SUCCESS';
export const RDV_DETAILS_FAIL = 'RDV_DETAILS_FAIL';

export const RDV_SAVE_FAIL = 'RDV_SAVE_FAIL';
export const RDV_SAVE_SUCCESS = 'RDV_SAVE_SUCCESS';
export const RDV_SAVE_REQUEST = 'RDV_SAVE_REQUEST'

export const RDV_DELETE_FAIL = 'RDV_DELETE_FAIL';
export const RDV_DELETE_SUCCESS = 'RDV_DELETE_SUCCESS';
export const RDV_DELETE_REQUEST = 'RDV_DELETE_REQUEST'