export const PROFIL_LIST_REQUEST = 'PROFIL_LIST_REQUEST';
export const PROFIL_LIST_SUCCESS = 'PROFIL_LIST_SUCCESS';
export const PROFIL_LIST_FAIL = 'PROFIL_LIST_FAIL';

export const PROFIL_DETAILS_REQUEST = 'PROFIL_DETAILS_REQUEST';
export const PROFIL_DETAILS_SUCCESS = 'PROFIL_DETAILS_SUCCESS';
export const PROFIL_DETAILS_FAIL = 'PROFIL_DETAILS_FAIL';

export const PROFIL_SAVE_FAIL = 'PROFIL_SAVE_FAIL';
export const PROFIL_SAVE_SUCCESS = 'PROFIL_SAVE_SUCCESS';
export const PROFIL_SAVE_REQUEST = 'PROFIL_SAVE_REQUEST';

export const PROFIL_DELETE_FAIL = 'PROFIL_DELETE_FAIL';
export const PROFIL_DELETE_SUCCESS = 'PROFIL_DELETE_SUCCESS';
export const PROFIL_DELETE_REQUEST = 'PROFIL_DELETE_REQUEST';