import React, { useEffect,useState } from 'react';
import { 
  Container, Grid, Toolbar, Button, IconButton,TextField, Typography ,
  Dialog, DialogTitle, DialogContent
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from '@mui/x-data-grid'
import {
  CancelOutlined, 
  CloseOutlined, 
  EditOutlined, 
  LockClockTwoTone, 
  SaveAltOutlined
} from '@mui/icons-material';
import styled from 'styled-components';
import ConfirmDialog from '../components/ConfirmDialog';
import { Box } from '@mui/system';
import UserForm from '../components/Forms/UserForm';
import Popup from '../components/Popup';
import { deleteUser, saveUser } from '../actions/userActions';
import { toast } from 'react-toastify';
import { publiqueRequest, userRequest } from '../requestMethods';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';



export const MainContainer = styled.div`
    width: auto;
    height: 100vh;
    margin-left: 12rem;
    position: relative; 
   // padding: 0 1rem;
    color:#000;
    background-color: #e8eaeb;

    @media screen  and (max-width:1028px){
       height: 100%;
    }
   

    @media screen  and (max-width:768px){
        margin-left: 0px;
        padding: 0 0rem;
    }
`;


function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display:"flex",
        justifyContent:"space-between"
      }}
    >
       <GridToolbarContainer className={gridClasses.toolbarContainer}>
           <GridToolbarExport color="success" />
        </GridToolbarContainer>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: 'auto',
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          '& .MuiSvgIcon-root': {
            mr: 0.5,
          },
          '& .MuiInput-underline:before': {
            borderBottom: 1,
            borderColor: 'divider',
          },
        }}
      />
    </Box>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  
};

 function Utilisateurs(){

    const dispatch = useDispatch();
    const [users,setUsers] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [showModal,setShowModal] = useState(false);

    const userSave = useSelector(state => state.userSave);
    const {loading: loadingSave,success: successSave,userInfo:messageSave, error:errorSave} = userSave;

    const userDelete = useSelector(state => state.userDelete);
    const {loading: loadingDelete,success: successDelete,userInfo:messageDelete, error:errorDelete} = userDelete;


    const [confirmDialog, setConfirmDialog] = useState({ open: false});
    const [openPopup, setOpenPopup] = useState(false);
    const [recordForEdit, setRecordForEdit] = useState(null);

    const navigate = useNavigate();
    const userSignin = useSelector((state) => state.userSignin);

    const [id,setId] = useState(null);
    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");
    const [repassword,setRepassword] = useState("")
   
    
    
    useEffect(() => {
      if(userSignin.userInfo == null || userSignin.userInfo.rules === "false"){
           navigate('/connexion')
      }else if(userSignin.userInfo.rules === "false"){
        navigate('-1')
      }
    }, [userSignin])

   
    const getUsers = async () => {
      try {
       const res = await userRequest.get("/users.php/");
        setUsers(res.data);
      } catch (err) {
        console.log(err)
      }
    }; 
    useEffect(() => {
       
         getUsers();
        
       }, [messageSave,messageDelete])

       const openInPopup = item => {
        setRecordForEdit(item)
        setOpenPopup(true)
      };

      const onDelete = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            open: false
        });
        
        if(dispatch(deleteUser(id))){
            toast("Utilisateur supprimé avec succès!!!", {
              type: "info",
              theme:'dark'
            });
        }else{
          toast("Erreur lors de la suppression!!!", {
            type: "error",
            theme:'dark'
          });
        }  
        getUsers();
     };

     const requestSearch = (searchValue) => {
      if(searchValue!==""){
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = users.filter((row) => {
          return Object.keys(row).some((field) => {
            return searchRegex.test(row[field].toString());
            
          });  
        
        });
      
        setUsers(filteredRows);
      }else{
        setSearchText("")
        getUsers();
      }
     
      
    }; 
    

      const addOrEdit = async (obj,resetForm) => {
      
        try {
 
            if (obj._id === "" ) {
                  
                  if(dispatch(saveUser(obj))){
                    toast("Utilisateur ajouté  avec succès!!!", {
                        type: "success",
                        theme:"dark"
                    });
              
                  }
              }else{
                
                if(dispatch(saveUser(obj))){
                 toast("Utilisateur mis à jour avec succès!!!", {
                     type: "success",
                     theme:"dark"
                 });
            
                }
             }
            resetForm()
            setRecordForEdit(null)
            getUsers();
            setOpenPopup(false)
           
        } catch (error) {
            console.error(error);
          }
       }

       function handleSubmit(e) {
        e.preventDefault()
        const user = {
          _id:id,
          email:email,
          password:password,
          repassword:repassword,
        } 
       
       
        if( dispatch(saveUser(user))){
            toast("Mot de passe mis à jour  avec succès!!!", {
              type: "success",
              theme:"dark"
          });
        }else{
            toast("Erreur lors de la mise à jour!!!", {
              type: "error",
              theme:"dark"
          });
        } 
          
        
        setId("");
        setEmail("")
        setPassword("");
        setRepassword("");
        setShowModal(false)

       }

       const renderData = (data) => {
        setShowModal(true);
        setId(data._id);
        setEmail(data.email)
       }

       const columns = [
        {
          field: "action",
          headerName: "Action",
          width: 150,
          renderCell: (params) => {
            return (
              <>
                
                <IconButton 
                  style={{color:'blue'}}
                  onClick={() => {openInPopup(params.row)}}
                  >
                        <EditOutlined fontSize="small"  />
                </IconButton>
             
                <IconButton 
                  style={{color:'red'}} 
                  onClick={() => {
                    setConfirmDialog({
                        open: true,
                        onConfirm: () => {onDelete(params.row._id)}
                      })
                    }}
                  >
                        <CancelOutlined fontSize="small"  />
                </IconButton>
              </>
            );
          },
        },
        {field: "pseudo", headerName: "Pseudo", width: 200 },
        {field: "email",headerName: "Email",width: 250},
        {field: "phone",headerName: "Téléphone",width: 120},
        {field: "rules",headerName: "Rôle",width: 120},
        {
          field: "unlock",
          headerName: "Mot de passe",
          width: 250,
          renderCell: (params) => {
            return (
              <>
              {
                 userSignin.userInfo.rules === "true" && (
                 <Button
                   variant="outlined"
                   color="warning"
                   startIcon={<LockClockTwoTone/>}
                   style={{
                     textTransform:'none'
                   }}

                   onClick={()=>renderData(params.row)}
                   
                 >
                   Changer le mot de passe
                 </Button>
                 )}
              </>
            );
          },
        },
     
      ];

    return (
        <>
        <MainContainer>
            <Container   maxWidth={false}>
                <Grid container  >
                      <Grid  item xs={12}>
                         <Toolbar>
                                
                                <Box display="flex" justifyContent="space-between"  sx={{flexGrow:1,display:{xs:'flex',md:'flex'}}} >
                                <Typography
                                  variant="h6"
                                  noWrap
                                  component="div"
                                  sx={{mr:2,display:{xs:'none',md:'flex'}}}
                                 >
                                   Utilisateurs
                                  </Typography>
                                  <Button variant="outlined" color="success"
                                    onClick={() => {setOpenPopup(true); setRecordForEdit(null); }}
                                  >
                                    Ajouter
                                  </Button>
                                </Box>
                            </Toolbar>
                      </Grid>
                      <Grid  item xs={12} >
                        <div style={{flex:4,height:'75vh', backgroundColor:'white'}} >
                                <DataGrid
                                    components={{ Toolbar: QuickSearchToolbar }}
                                    rows={users}
                                    disableSelectionOnClick
                                    columns={columns}
                                    getRowId={(row) => row._id}
                                    pageSize={8}
                                    checkboxSelection
                                    componentsProps={{
                                      toolbar: {
                                        value: searchText,
                                        onChange: (event) => requestSearch(event.target.value),
                                        clearSearch: () => requestSearch(''),
                                      },
                                    }}
                                />
                        </div>
                      </Grid>

                </Grid>
                <Popup
                   title="Formulaire d'utilisateur"
                   openPopup={openPopup}
                   setOpenPopup={setOpenPopup}
                 > 
                    <UserForm
                        recordForEdit={recordForEdit}
                        addOrEdit={addOrEdit}
                    />
                </Popup>
                <ConfirmDialog
                  confirmDialog={confirmDialog}
                  setConfirmDialog={setConfirmDialog}
                />
            </Container>
            <Dialog 
                        open={showModal} 
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <DialogTitle style={{textAlign:'center'}} >
                          <div style={{display:'flex'}}>
                                <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                                      Changer le mot de passe
                                </Typography> 
                              
                                  <IconButton
                                        color="warning"
                                        onClick={() => setShowModal(false)}>
                                        <CloseOutlined />
                                  </IconButton>
                          </div>
                        </DialogTitle>
                        <DialogContent dividers>
                            <Container>
                                <Grid container>
                                    <form 
                                        noValidate 
                                        autoComplete="off" 
                                        onSubmit={handleSubmit}
                                    >
                                    <Grid  item lg={12} xs={12}>
                                      
                                             <TextField 
                                                   style={{marginBottom:10}} 
                                                   fullWidth 
                                                   variant="outlined"
                                                   name="email" 
                                                   label="Email" 
                                                   value={email}
                                                   type="email"
                                                   required
                                                   disabled
                                                   onChange={(e)=>setEmail(e.target.value)}
                                                   
                                               />

                                                <TextField 
                                                   style={{marginBottom:10}} 
                                                   fullWidth 
                                                   variant="outlined"
                                                   name="password" 
                                                   label="Ancien password" 
                                                   value={password}
                                                   type="password"
                                                   required
                                                   onChange={(e)=>setPassword(e.target.value)}
                                                   
                                               />
                                                 <TextField 
                                                   style={{marginBottom:10}} 
                                                   fullWidth 
                                                   variant="outlined"
                                                   name="repassword" 
                                                   label="Nouveau password" 
                                                   value={repassword}
                                                   type="password"
                                                   required
                                                   onChange={(e)=>setRepassword(e.target.value)}
                                                  
                                               />
                                            <footer    
                                                style={{
                                                    display:'flex',
                                                    textAlign:'center',
                                                    justifyContent:'center'
                                                }}
                                            >
                                                <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                startIcon={<SaveAltOutlined/>}
                                                onClick={handleSubmit}
                                                
                                                >
                                                    Modifier
                                                </Button>
                                            </footer>
                
                                              </Grid>
                                      </form>
                                </Grid>
                            </Container>
                        </DialogContent>
                    </Dialog>

        </MainContainer>
        </>
       
       
    );
}


export default Utilisateurs;