import React from 'react';
import * as RiIcons from 'react-icons/ri';
import * as GiIcons from 'react-icons/gi';
import * as MdIcons from 'react-icons/md';
import * as ImIcons from 'react-icons/im';
import * as FaIcons from 'react-icons/fa';
import * as BsIcons from 'react-icons/bs';





export const SidebarData = [
 
  {
    title: 'Tableau de bord',
    path: '/',
    icon: <MdIcons.MdDashboard />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: 'Interventions',
    path: '/services',
    icon: <FaIcons.FaBriefcaseMedical />,
   
  },
  {
    title: 'Fiches Patient',
    path: '/fiche-clients',
    icon: <FaIcons.FaNotesMedical />,
   
  },
  {
    title: 'Factures',
    path: 'factures',
    icon: <GiIcons.GiPayMoney />,
   
  },
  {
    title: 'Historique',
    path: '/rendez-vous',
    icon: <MdIcons.MdOutlineDateRange />,
   
  },

  {
    title: 'Agenda',
    path: '/agenda',
    icon: <BsIcons.BsCalendar2WeekFill />,
   
  },
 
  {
    title: 'Utilisateurs',
    path: '/utilisateurs',
    icon: <ImIcons.ImUsers />,
   
  },
  {
    title: 'Profil',
    path: '/profil',
    icon: <ImIcons.ImProfile />
   
  },
 /* {
    title: 'Catégories',
    path: '/dashboard/categories',
    icon: <BiIcons.BiCategoryAlt />,
   
  },*/
 
 
 
  
  
]; 