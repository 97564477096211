import React,{useEffect,useState,useRef}  from 'react';
import { Add } from '@mui/icons-material';
import { Button, Container, Grid, Paper, Toolbar, Typography ,TextField,Box,IconButton} from '@mui/material';
import styled from 'styled-components'
import Img from '../images/36_113676.svg'
import Popup from '../components/Popup';
import { toast } from 'react-toastify';
import { publiqueRequest, userRequest } from '../requestMethods';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';





export const MainContainer = styled.div`
    width: auto;
    height: 100vh;
    margin-left: 14rem;
    position: relative; 
   // padding: 0 1rem;
    color:#000;
    background-color: #e8eaeb;

    @media screen  and (max-width:1028px){
       height: 100%;
    }
   

    @media screen  and (max-width:768px){
        margin-left: 0px;
        padding: 0 0rem;
    }
`;

function Profil() {

  const [openPopup, setOpenPopup] = useState(false);
  const [count,setCount] = useState(0);
  const [isMounted,setIsMounted] = useState(0);
  const [profil,setProfil] = useState([]);

  const [id,setId] = useState("");
  const [name,setName] = useState("");
  const [contact1,setContact1] = useState("");
  const [contact2,setContact2] = useState("");
  const [adresse,setAdresse] = useState("");
  const [picture,setPicture] = useState("");
  const selectedFile = useRef();

  const navigate = useNavigate();
  const userSignin = useSelector((state) => state.userSignin);

  useEffect(() => {
    if(userSignin.userInfo == null || userSignin.userInfo.rules === "false"){
         navigate('/connexion')
    }else if(userSignin.userInfo.rules === "false"){
      navigate('-1')
    }
  }, [userSignin])

  useEffect(() => {
    let isAmounted = true;
    const getProfil = async () => {
        try {
         const res = await userRequest.get("/profil.php/");
          setProfil(res.data);
          setPicture(res.data[0].photo)
        } catch (err) {
          console.log(err)
        }
      }; 
      getProfil();
     return ()=>{isAmounted = false}
   }, [isMounted]);

   const sendNull = () =>{
    setId("")
    setName("")
    setContact1("")
    setContact2("")
    setAdresse("")
 }

  const uploadFile = async () => {
       const fd = new FormData();
      if(id===""){
        fd.append('file', selectedFile.current.files[0]);
        fd.append('name',name);
        fd.append('contact_1',contact1);
        fd.append('contact_2',contact2);
        fd.append('adresse',adresse);
        await publiqueRequest.post('/profil.php/',fd,{
          onUploadProgress:progressEvent=>{
            console.log("téléchargement:"+ Math.round( progressEvent.loaded/progressEvent.total*100)+"%");
            setCount(Math.round( progressEvent.loaded/progressEvent.total*100))
            setIsMounted(100)
          }
        }).then(res=>{
          console.log(res);
        })
       
      }else{
        
        const fd = new FormData();
        fd.append('file', selectedFile.current.files[0]);
        fd.append('_id',id);
        fd.append('name',name);
        fd.append('contact_1',contact1);
        fd.append('contact_2',contact2);
        fd.append('adresse',adresse);
        await publiqueRequest.put('/profil.php/',fd,{
          onUploadProgress:progressEvent=>{
            console.log("téléchargement:"+ Math.round( progressEvent.loaded/progressEvent.total*100)+"%");
            setCount(Math.round( progressEvent.loaded/progressEvent.total*100))
            setIsMounted(100)
          }
        }).then(res=>{
          console.log(res);
        })
      }
      setOpenPopup(false)
      if(count >= 100){
        toast("Données téléchargée avec succès!!!", {
          type: "success",
          theme: "dark"
        });
      }
      setIsMounted(0) 
    sendNull()
  }

  const sendData = () =>{
     setId(profil[0]._id)
     setName(profil[0].name)
     setContact1(profil[0].contact_1)
     setContact2(profil[0].contact_2)
     setAdresse(profil[0].adresse)
  }


  const renderProfil = () => {
    if(profil.length>0){
      return(
        profil.map((item,index) => (
            <div key={index}>
                <Typography p={2}> <b>Nom : </b> {item.name} </Typography>
                <Typography p={2}>  <b>Contact 1 : </b> {item.contact_1} </Typography>
                <Typography p={2}> <b>Contact 2 : </b> {item.contact_2} </Typography>
                <Typography p={2}> <b>Adresse : </b> {item.adresse} </Typography>
                <Box p={2}   display="flex" justifyContent="space-between">
                     <Button
                       style={{backgroundColor:'#7faf02'}}
                       variant="contained"
                       color="primary"
                       onClick={()=>{setOpenPopup(true);sendData()}}
                       
                  >
                      Modifier
                   </Button>
                   <IconButton 
                   
                    style={{color:"#ff1744"}}
                    onClick={
                      async () =>{
                          const res = await publiqueRequest.delete("/profil.php/?_id="+item._id);
                         
                          if(res.statusText==="OK"){
                            toast("Profil supprimé avec succès!!!", {
                              type: "success",
                              theme: "dark"
                            });
                           
                            setIsMounted(201)
                        }  
                        console.log(res)
                    }
                  }
                   >
                       <DeleteIcon/>
                   </IconButton>
               </Box>
                
            </div>
         ))
      )
    }else{
      return(
       
          <Typography p={2} style={{color:'gray'}} variant="h6" component="h6" > Aucune donnée ! </Typography>
     
      )
    }
  }

  return (
    <MainContainer>
        <Container   maxWidth={false}>
              <Grid container spacing={1} >
                    <Grid item xs={12}>
                       <Toolbar style={{justifyContent:'flex-end'}} >
                             <Button
                                        variant="outlined"
                                        color="success"
                                        startIcon={<Add/>}
                                        style={{
                                            textTransform:'none'
                                        }}
                                        onClick={() => setOpenPopup(true)}
                                      >
                                        Editer votre profil
                             </Button>
                       </Toolbar>
                    </Grid>
                    <Grid item lg={6} xs={12} >
                          <Paper elevation={1}  >
                               {renderProfil()}
                          </Paper>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                          <Paper 
                              elevation={1}
                              style={{
                                display: 'flex',
                                margin: 'auto',
                                padding: 'auto',
                                alignItems:'center',
                                justifyContent:'center'
                              }}
                          >
                                         <img 
                                            style={{
                                                width: "42%",
                                                height: "42%",
                                                borderRadius:"50%",
                                                padding: 10
                                                
                                            }} 
                                            src={`http://localhost/api-cabinet-dentaire/uploads/profil/${picture}`} alt="logo dent" 
                                        />
                          </Paper>
                    </Grid>

              </Grid>
              <Popup
                   title="Profil"
                   openPopup={openPopup}
                   setOpenPopup={setOpenPopup}
                  
                 > 
                    <Grid container>
                     
                                       <Grid  item lg={12} xs={12}>
                                               
                                               <TextField 
                                                   style={{marginBottom:10}} 
                                                   fullWidth 
                                                   variant="outlined" 
                                                   name="name" 
                                                   label="Nom de l'entreprise" 
                                                   value={name}
                                                   required
                                                   type="text"
                                                   onChange={(e)=>setName(e.target.value)}
                                                  
                                               />
                                        
                                              

                                                <TextField 
                                                   style={{marginBottom:10}} 
                                                   fullWidth 
                                                   variant="outlined"
                                                   name="contact1" 
                                                   label="Contact 1" 
                                                   value={contact1}
                                                   type="text"
                                                   required
                                                   onChange={(e)=>setContact1(e.target.value)}
                                                  
                                               />
                                           
                                              <TextField 
                                                   style={{marginBottom:10}} 
                                                   fullWidth 
                                                   variant="outlined"
                                                   name="contact2" 
                                                   label="Contact 2" 
                                                   value={contact2}
                                                   type="text"
                                                   onChange={(e)=>setContact2(e.target.value)}
                                                  
                                               />
                                                <TextField 
                                                   style={{marginBottom:10}} 
                                                   fullWidth 
                                                   variant="outlined"
                                                   name="adresse" 
                                                   label="Adresse" 
                                                   value={adresse}
                                                   type="text"
                                                   required
                                                   onChange={(e)=>setAdresse(e.target.value)}
                                                   
                                               />
                                                 <input 
                                                   style={{marginBottom:10}} 
                                                   type="file"
                                                   ref={selectedFile}
                                                  
                                               />
                                               
                                               <Box p={2}>
                                                   <Button
                                                   style={{backgroundColor:'#7faf02'}}
                                                   variant="contained"
                                                   fullWidth
                                                   size="large"
                                                   color="primary"
                                                   onClick={uploadFile}
                                                   >
                                                       {id ? "Modifier" : "Ajouter"}
                                                   </Button>
                                               </Box>
                                       </Grid>
                                   
                                
                       </Grid>
                </Popup>
        </Container>
    </MainContainer>
    
  )
  
            
}

export default Profil;
