import React from 'react'
import {
    SidebarContainer, 
    Icon, 
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarLink,
    IconMobile,
} from './MobileElements'
import * as RiIcons from 'react-icons/ri';
import * as MdIcons from 'react-icons/md';
import * as ImIcons from 'react-icons/im';
import * as FaIcons from 'react-icons/fa';
import * as BsIcons from 'react-icons/bs';




export const items = [
 
  {
    title: 'Tableau de bord',
    path: '/',
    icon: <MdIcons.MdDashboard />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

   
  },
  {
    title: 'Interventions',
    path: '/services',
    icon: <FaIcons.FaBriefcaseMedical />,
   
  },
  {
    title: 'Fiches Patient',
    path: '/fiche-clients',
    icon: <FaIcons.FaNotesMedical />,
   
  },
  {
    title: 'Historique',
    path: '/rendez-vous',
    icon: <MdIcons.MdOutlineDateRange />,
   
  },

  {
    title: 'Agenda',
    path: '/agenda',
    icon: <BsIcons.BsCalendar2WeekFill />,
   
  },
 
  {
    title: 'Utilisateurs',
    path: '/utilisateurs',
    icon: <ImIcons.ImUsers />,
   
  },
  {
    title: 'Profil',
    path: '/profil',
    icon: <ImIcons.ImProfile />
   
  },
 /* {
    title: 'Catégories',
    path: '/dashboard/categories',
    icon: <BiIcons.BiCategoryAlt />,
   
  },*/
 
 
 
  
]; 


const MobileSidebar = ({isOpen,toggle}) => {
    return (
        <>
            <SidebarContainer isOpen={isOpen}  onClick={toggle} >
                <Icon onClick={toggle}>
                    <CloseIcon />
                </Icon>
                <SidebarWrapper> 
                    <SidebarMenu>
                     {items.map((item,index) => (
                        <SidebarLink
                         key={index}
                         to={item.path}
                        >
                           <IconMobile>{item.icon}</IconMobile>
                           {item.title}
                        </SidebarLink>
                    ))}
                    </SidebarMenu>
                </SidebarWrapper>
            </SidebarContainer>
        </>
    )
}

export default MobileSidebar
