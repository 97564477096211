import React, { useEffect,useState } from 'react'
import { toast } from 'react-toastify';
import { 
    Container,
    Box,
    TextField, 
    Grid, 
    Toolbar, 
    Button, 
    IconButton, 
    Typography ,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Card,
    Avatar,
    Stack,
    Divider,
    Chip,
    Switch,
    Alert,
    FilledInput,
    InputAdornment,
    FormControl,
    InputLabel
} from '@mui/material';
import styled from 'styled-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { publiqueRequest } from '../requestMethods';
import SendIcon from '@mui/icons-material/Send';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { 
        DataGrid,
} from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { Class, DateRange, Delete, Edit, EditOutlined, FactCheck, LocalOffer, LocationOn, MedicalServices, Message, Person, Visibility } from '@mui/icons-material';
import { addToCart, clearCart, editAssurance, editItem, removeItem, saveFacture } from '../actions/factureActions';
import Popup from '../components/Popup';
import FactureForm from '../components/Forms/FactureForm';
import AssuranceForm from '../components/Forms/AssuranceForm';
import Slide from '@mui/material/Slide';
import FullPopup from '../components/FullPopup';
import { makeStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import { Row, Item } from '@mui-treasury/components/flex';
import { Info, InfoTitle, InfoSubtitle } from '@mui-treasury/components/info';
import { useTutorInfoStyles } from '@mui-treasury/styles/info/tutor';
import { useSizedIconButtonStyles } from '@mui-treasury/styles/iconButton/sized';
//import './facturation.css'

const useStyles = makeStyles(() => ({
  action: {
    backgroundColor: '#fff',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.12)',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#7faf02',
    },
  },


 root:{
    "& .MuiDataGrid-viewport":{
      "& .MuiDataGrid-renderingZone":{
        "& .MuiDataGrid-row" : {
          maxHeight:"fit-content !important",
          outlineWidth:"none !important",
          borderBottom: "none",

        }
      }
     
    },

    "& .MuiDataGrid-cell" : {
      maxHeight: "fit-content !important",
      overflow: "auto",
      maxHeight: "inherit",
      whiteSpace: "initial !important",
      display:" flex !important",
      alignItems: "center",
      paddingTop:" 10px !important",
      paddingBottom: "10px !important",
  
    },


    "& .css-30pzqk-MuiDataGrid-root": {
     "& .MuiDataGrid-cell" : {
          display:"block",
          overflow:"hidden",
          textOverflow:"ellipsis",
          whiteSpace:"nowrap",
          borderBottom:"1px solid rgba(246, 246, 246, 0.159)",
          height:"100px",
        }
    },


    "& .MuiDataGrid-root" : {
      "& .MuiDataGrid-colCell:focus" : {
        outline: "none !important",
        borderBottom: "none"
       }
    },
    

    "& .MuiDataGrid-root" : {
      "& .paxton-table--row" : {
        border: "none",
        backgroundColor: "none",
        }
     }

    }

}));



export const MainContainer = styled.div`
    width: auto;
    height: 100vh;
    margin-left: 12rem;
    position: relative; 
    margin-top: 14px;
   // padding: 0 1rem;
    color:#000;
    background-color: #e8eaeb;

    @media screen  and (max-width:1028px){
       height: 100%;
    }
   

    @media screen  and (max-width:768px){
        margin-left: 0px;
        padding: 0 0rem;
    }
`;

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }
  
  function QuickSearchToolbar(props) {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
       
        <TextField
          variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder="Search…"
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          sx={{
            width: {
              xs: 1,
              sm: 'auto',
            },
            m: (theme) => theme.spacing(1, 0.5, 1.5),
            '& .MuiSvgIcon-root': {
              mr: 0.5,
            },
            '& .MuiInput-underline:before': {
              borderBottom: 1,
              borderColor: 'divider',
            },
          }}
        />
      </Box>
    );
  }
  
  QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });




function Facturation() {

    const location = useLocation();
    const dispatch = useDispatch();
   
    const cart = useSelector(state=>state.cart);


    const customerId = location.pathname.split("/")[2];
    const [customerRdv,setCustomerRdv] = useState([]);
    const [customer,setCustomer] = useState({});
    const [searchText, setSearchText] = useState('');
    const [message,setMessage] = useState("");

    const styles = useStyles();
    const iconBtnStyles = useSizedIconButtonStyles({ padding: 6 });
   

    const factureSave = useSelector(state => state.factureSave);
    const {loading,success, error,facture} = factureSave;


    const [openPopup, setOpenPopup] = useState(false);
    const [open, setOpen] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [record, setRecord] = useState(null);
    const [recordInfo, setRecordInfo] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('sm');
  




          const handleAddToCart = (id) => {
              
                  dispatch(addToCart(id))
            }

           const handleRemove = (id) => {
              dispatch(
                removeItem(id)
              )
                
            }

            

           const getCustomerRdv = async () => {
              try {
              const res = await publiqueRequest.get("/rendez-vous.php/?customerId="+customerId);
                setCustomerRdv(res.data);
              } catch (err) {
                console.log(err)
              }
            }; 

            const getCustomer = async () => {
              try {
              const res = await publiqueRequest.get("/customers.php/?_id="+customerId);
                setCustomer(res.data);
              } catch (err) {
                console.log(err)
              }
            }; 

          const requestSearch = (searchValue) => {
            if(searchValue!==""){
              setSearchText(searchValue);
              const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
              const filteredRows = customerRdv.filter((row) => {
                return Object.keys(row).some((field) => {
                  return searchRegex.test(row[field].toString());
                    }); 
                });
              setCustomerRdv(filteredRows);
              }else{
                    setSearchText("")
                    getCustomerRdv();
              }
           };

          useEffect(() => {
            let isAmounted = true;
            
             getCustomerRdv();
             return ()=>{isAmounted = false}
           }, []);

           useEffect(() => {
            let isAmounted = true;
            
             getCustomer();
             return ()=>{isAmounted = false}
           }, []);

           const openInPopup = item => {
              setRecordForEdit(item)
              setOpenPopup(true)
           };

           const openInPopupInfo = item => {
            setRecordInfo(item)
            setOpenInfo(true)
         };


        

           const addOrEditAssurance = async (obj,resetForm) => {
            try {
              
                dispatch(editAssurance(obj.assurance))
                resetForm()
                setRecord(null)
                setOpen(false)
              
            } catch (error) {
                console.error(error);
              }
          }
      

          const addOrEdit = async (obj,resetForm) => {
            try {
                
                dispatch(editItem(obj._id,obj))
                resetForm()
                setRecordForEdit(null)
                setOpenPopup(false)
              
            } catch (error) {
                console.error(error);
              }
          }

          const handleClickOpen = () => {
            setRecord({assurance:cart?.assurance})
            setOpen(true);
          };
        
          const handleClose = () => {
            setOpen(false);
          };

          const handleClickOpenModal = () => {
            setOpenModal(true);
          };

          const handleCloseModal = () => {
            setOpenModal(false);
          };

          const handleMaxWidthChange = (event) => {
            setMaxWidth(
              // @ts-expect-error autofill of arbitrary value is not handled.
              event.target.value,
            );
          };
        
          const handleFullWidthChange = (event) => {
            setFullWidth(event.target.checked);
          };

        
          function renderCart(){
            if(cart.addedItems.length > 0){
              return(
                   cart.addedItems.map((item)=>(
                    <TableRow key={item._id} >
                            <TableCell style={{fontSize:12}}>{item.bookingDate}</TableCell>
                            <TableCell style={{fontSize:12}}>
                                 {item.teeth}
                            </TableCell>
                            <TableCell  style={{fontSize:12}} >
                              {item.intervention}
                              
                            </TableCell>
                            <TableCell style={{fontSize:12}}>
                             
                               {item.coefficient}
                              
                              </TableCell>
                            <TableCell style={{fontSize:12}}> 
                             
                               {item.price}
                              
                            </TableCell>
                            <TableCell  style={{display:"flex"}} >
                                  <IconButton
                                    onClick={() => {openInPopup(item)}}
                                  >
                                    <EditOutlined color="primary" fontSize="small" />
                                  </IconButton>
                                   <IconButton
                                         onClick={()=>handleRemove(item._id)}
                                         
                                    >
                                        <Delete color="error" fontSize="small"/>
                                    </IconButton>
                            </TableCell>
                    </TableRow>
                     
                   ))
                )   
               
            }
          } 

         
          const columns = [
            {
                field: "ajouter",
                headerName: "Ajouter",
                width: 350,
                renderCell: (params) => {
                  
                  return (
                    <>
                           
                           
                            <Row  >
                              <Info position={'middle'} style={{width:250}} useStyles={useTutorInfoStyles}>
                               
                                <InfoSubtitle style={{fontSize:13, color:"#000"}} >{params.row.intervention?params.row.intervention.substring(0,30): params.row.another_intervention?params.row.another_intervention.substring(0,20):"" }... </InfoSubtitle>
                                <InfoSubtitle style={{fontSize:13, color:"#000"}} >{params.row.bookingDate} </InfoSubtitle>
                              </Info>
                              <Item ml={1} position={'middle'}>
                                <IconButton 
                                    
                                    onClick={()=>handleAddToCart(params.row._id)}
                                    className={styles.action} classes={iconBtnStyles}
                                  
                                    >
                                  <Add  />
                                </IconButton>
                                {" "}
                                <IconButton 
                                    
                                    onClick={()=>(openInPopupInfo(params.row))}
                                    className={styles.action} classes={iconBtnStyles}
                                    style={{color:"#7faf02"}}
                                    >
                                  <Visibility  />
                                </IconButton>
                              </Item>
                            </Row>
                           
                       
                    </>
                  );
                },
              }
         
          ];

          const factureCustomer = async ()  =>{
               const factureData = {
                  id_patient:customerId,
                  nom_patient:customer?.lastname+" "+customer?.firstname,
                  assurance:cart.assurance,
                  patient:cart.patient,
                  total:cart.total,
                  facture:cart.addedItems,
                  message:message
              }

              if(dispatch(saveFacture(factureData))){
                toast("Client facturer avec succès!!!", {
                  type: "success",
                  theme:"dark"
                });
              }else{
                toast("Erreur de facturation", {
                  type:"error",
                  theme:"dark"
                });
              }

              dispatch(clearCart())

              const timer = setTimeout(()=>{
                  handleClickOpenModal()
              }, 500)
              return ()=> clearTimeout(timer)
             
             
          }

          const renderFactureBtn = () => {
        
            if(cart.addedItems.length > 0){
              return(
                <>
                  <TableCell>
                      <Button 
                        onClick={()=>dispatch(clearCart())}
                        variant="contained"
                        color="primary" 
                        endIcon={<ClearIcon/>}
                        size="large"
                        style={{
                          textTransform:"none",
                          width:100,
                          height:40,
                          color:"white",
                          borderRadius:2,
                          backgroundColor:"#d43025"
                        }}
                      >
                        Effacer
                      </Button>
                </TableCell>
                <TableCell > 
                       <Button 
                          onClick={()=>factureCustomer()}
                          variant="contained"
                          color="primary" 
                          endIcon={<SendIcon/>}
                          size="large"
                          style={{
                            textTransform:"none",
                            width:100,
                            height:40,
                            color:"white",
                            borderRadius:2,
                            backgroundColor:"#1976d2"
                          }}
                          >
                          Facturer
                        </Button>                  
                 </TableCell>
                 </>
               
              )
            }
            
          
        }
 
    

        return (
        <MainContainer className="facturation" >
            <Container>
                 
                <Grid container  spacing={2}>
                  <Grid item xs={12} >
                    <Toolbar>  
                     <Typography  variant='h5' > Patient : {customer?.lastname}  {customer?.firstname} </Typography>
                  </Toolbar>
                  </Grid>
                
                    <Grid item  lg={4} xs={12} >
                      
                       <Paper  sx={{
                                    "& .MuiDataGrid-viewport":{
                                      "& .MuiDataGrid-renderingZone":{
                                        "& .MuiDataGrid-row" : {
                                          maxHeight:"fit-content !important",
                                          outlineWidth:"none !important",
                                          borderBottom: "none",
                                
                                        }
                                      }
                                     
                                    },

                                    "& .css-30pzqk-MuiDataGrid-root": {
                                      "& .MuiDataGrid-cell" : {
                                           display:"block",
                                           overflow:"hidden",
                                           textOverflow:"ellipsis",
                                           whiteSpace:"nowrap",
                                           borderBottom:"1px solid rgba(246, 246, 246, 0.159)",
                                           height:"100px",
                                         }
                                     },

                                    "& .css-30pzqk-MuiDataGrid-root": {
                                      "& .MuiDataGrid-cell" : {
                                           display:"block",
                                           overflow:"hidden",
                                           textOverflow:"ellipsis",
                                           whiteSpace:"nowrap",
                                           borderBottom:"1px solid rgba(246, 246, 246, 0.159)",
                                           height:"100px",
                                           backgroundColor:"#7faf02"
                                         }
                                     }
                                  }} 
                                   elevation={1} style={{flex:4,height:'77vh',marginBottom:12, backgroundColor:'white'}} >
                                <DataGrid
                                   
                                    components={{ Toolbar: QuickSearchToolbar }}
                                    rows={customerRdv}
                                    columns={columns}
                                    getRowId={(row) => row._id}
                                    pageSize={10}
                                    componentsProps={{
                                        toolbar: {
                                          value: searchText,
                                          onChange: (event) => requestSearch(event.target.value),
                                          clearSearch: () => requestSearch(''),
                                        },
                                      }}

                                 
                                   
                                />
                        </Paper>
                    </Grid>
                    <Grid item  lg={8}> 
                        <Paper>
                            <Table  aria-label="simple table" >
                                       <TableHead>
                                         <TableRow  >
                                                <TableCell> <b>Date</b> </TableCell>
                                                <TableCell> <b>Dent</b> </TableCell>
                                                <TableCell> <b>Soins</b> </TableCell>
                                                <TableCell> <b>Coef</b> </TableCell>
                                                <TableCell> <b>Montant</b> </TableCell>
                                                <TableCell> <b>Action</b> </TableCell>
                                                <TableCell>  </TableCell>
                                         </TableRow> 
                                         </TableHead>
                                     <TableBody >
                                     {renderCart()}
                                     
                                     <TableRow>
                                       <TableCell></TableCell>
                                       <TableCell></TableCell>
                                       <TableCell></TableCell>
                                       <TableCell> <b>Total  </b> </TableCell>
                                       <TableCell>{cart?.total?cart?.total:0} </TableCell>
                                       <TableCell></TableCell>
                                     </TableRow>
                                     <TableRow>
                                       <TableCell></TableCell>
                                       <TableCell></TableCell>
                                       <TableCell></TableCell>
                                       <TableCell> <b>Assurance</b> </TableCell>
                                       <TableCell> {cart?.assurance} % </TableCell>
                                          <TableCell  style={{display:"flex"}} >
                                            <IconButton
                                              onClick={handleClickOpen}
                                             >
                                              <EditOutlined color="primary" fontSize="small" />
                                            </IconButton>
                                       </TableCell>
                                     </TableRow>
                                     <TableRow>
                                       <TableCell></TableCell>
                                       <TableCell></TableCell>
                                       <TableCell></TableCell>
                                       <TableCell> <b>Patient  </b> </TableCell>
                                       <TableCell>{cart?.patient} </TableCell>
                                       <TableCell></TableCell>
                                     </TableRow>
                                     <TableRow>
                                       <TableCell></TableCell>
                                       <TableCell></TableCell>
                                       <TableCell></TableCell>
                                       <TableCell></TableCell>
                                       {renderFactureBtn()}
                                     </TableRow>   
                                </TableBody>
                                
                          </Table>
                             <div style={{padding:2, maxWidth:"100%",minWidth:300, dispaly:"flex",justifyContent:"flex-start"}}>  
                                     <TextField 
                                        fullWidth label="Entrer le message d'entête pour la facture" 
                                        id="fullWidth" 
                                        value={message}
                                        onChange={(e)=>setMessage(e.target.value)}
                                        multiline
                                        rows={2}
                                     />
                             </div>
                             <div style={{padding:3, maxWidth:"100%",minWidth:300, fontSize:12}}>  
                                   <Typography variant="p" component="p">
                                        Ex : Description de la facture  et/ou des produits à facturer
                                  </Typography>
                             </div>
                        </Paper>
                    </Grid>
                  
                </Grid>
                <Popup
                  title="Editer la facture"
                  openPopup={openPopup}
                  setOpenPopup={setOpenPopup}
                >
                      <FactureForm
                       recordForEdit={recordForEdit}
                       addOrEdit={addOrEdit}
                     />
                </Popup>
                <Dialog 
                  fullWidth={fullWidth}
                  maxWidth={maxWidth}
                  open={openInfo} 
                  onClose={()=>setOpenInfo(false)}
                >
                  
                    <DialogTitle>Informations</DialogTitle>
                    <DialogContent>
                   
                     <Card>
                         <Box sx={{ p: 2, display: 'flex' }}>
                         
                            <Person style={{color:"#7faf02"}} />
                            <Stack spacing={0.5}>
                              <Typography fontWeight={700}> {recordInfo?.customerName} </Typography>
                            </Stack>
                          </Box>
                      
                          <Box sx={{ p: 2, display: 'flex' }}>
                            <DateRange style={{color:"#7faf02"}}/>
                            <Stack spacing={0.5}>
                              <Typography fontWeight={700}> {recordInfo?.bookingDate} </Typography>
                            </Stack>
                          </Box>
                          {
                          recordInfo?.teeth 
                          ?
                              <Box sx={{ p: 2, display: 'flex' }}>
                              <MedicalServices style={{color:"#7faf02"}}/>
                              <Stack spacing={0.5}>
                                <Typography fontWeight={700}> {recordInfo?.teeth} </Typography>
                              </Stack>
                            </Box>
                          :
                          ""
                          }
                          {
                          recordInfo?.intervention 
                          ?
                              <Box sx={{ p: 2, display: 'flex' }}>
                              <MedicalServices style={{color:"#7faf02"}}/>
                              <Stack spacing={0.5}>
                                <Typography fontWeight={700}> {recordInfo?.intervention} </Typography>
                              </Stack>
                            </Box>
                          :
                          ""
                          }
                          {
                          recordInfo?.another_intervention 
                          ?
                              <Box sx={{ p: 2, display: 'flex' }}>
                              <MedicalServices style={{color:"#7faf02"}}/>
                              <Stack spacing={0.5}>
                                <Typography fontWeight={700}> {recordInfo?.another_intervention} </Typography>
                              </Stack>
                            </Box>
                          :
                          ""
                          }
                          {
                          recordInfo?.coefficient 
                          ?
                              <Box sx={{ p: 2, display: 'flex' }}>
                              <Class style={{color:"#7faf02"}}/>
                              <Stack spacing={0.5}>
                                <Typography fontWeight={700}> {recordInfo?.coefficient} </Typography>
                              </Stack>
                            </Box>
                          :
                          ""
                          }
                           {
                          recordInfo?.price 
                          ?
                              <Box sx={{ p: 2, display: 'flex' }}>
                              <LocalOffer style={{color:"#7faf02"}}/>
                              <Stack spacing={0.5}>
                                <Typography fontWeight={700}> {recordInfo?.price} </Typography>
                              </Stack>
                            </Box>
                          :
                          ""
                          }
                         
                         
                         
                         
                        </Card>
                        </DialogContent>
                    <DialogActions>
                      <Button style={{backgroundColor:"#7faf02",color:"#fff"}} onClick={()=>setOpenInfo(false)}>
                        Fermer
                      </Button>
                     
                    </DialogActions>
                  
                </Dialog>
               
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Assurance</DialogTitle>
                    <DialogContent>
                        <AssuranceForm
                          recordForEdit={record}
                          addOrEdit={addOrEditAssurance}
                        />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>
                        Fermer
                      </Button>
                     
                    </DialogActions>
                </Dialog>
                
                <FullPopup
                  title=""
                  openModal={openModal}
                  handleCloseModal={handleCloseModal}
                >
                     <Link to={'/facture/'+facture.facture}  
                       style={{
                          textDecoration:"none", 
                          display:'flex', 
                          justifyContent:'center'
                      }}>
                          
                          <Button variant="contained" endIcon={<FactCheck />}>
                               VOIR LE PDF 
                          </Button>
                     </Link>
                </FullPopup>
                     
                    
           

            </Container>
        </MainContainer>

  )
}

export default Facturation