import { 
    SRV_DELETE_FAIL,
    SRV_DELETE_REQUEST,
    SRV_DELETE_SUCCESS,
    SRV_DETAILS_FAIL,
    SRV_DETAILS_REQUEST,
    SRV_DETAILS_SUCCESS,
    SRV_LIST_FAIL,
    SRV_LIST_REQUEST,
    SRV_LIST_SUCCESS,
    SRV_SAVE_FAIL,
    SRV_SAVE_REQUEST,
    SRV_SAVE_SUCCESS

 } from "../constants/srvConstants"

function serviceListReducer(state = { services: [] }, action) {
    switch (action.type) {
    case SRV_LIST_REQUEST: 
      return { loading: true,services: [] };
    case SRV_LIST_SUCCESS:
      return { loading: false, success: true, services: action.payload };
    case SRV_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
    }
  }




function serviceDeleteReducer(state = { service: {} }, action) {
 switch (action.type) {
    case SRV_DELETE_REQUEST:
        return { loading: true }; 
    case SRV_DELETE_SUCCESS:
        return { loading: false, service: action.payload, success: true };
    case SRV_DELETE_FAIL:
        return { loading: false, error: action.payload };
    default:
        return state;
  }
}

function serviceSaveReducer(state = { service: {} }, action) {
  switch (action.type) {
   case SRV_SAVE_REQUEST:
     return { loading: true };
   case SRV_SAVE_SUCCESS:
     return { loading: false, service: action.payload, success: true };
   case SRV_SAVE_FAIL:
     return { loading: false, error: action.payload };
   default:
     return state;
   }
  }

function serviceDetailsReducer(state = { service: {} }, action) {
switch (action.type) {
 case SRV_DETAILS_REQUEST:
   return { loading: true };
 case SRV_DETAILS_SUCCESS:
   return { loading: false, service: action.payload };
 case SRV_DETAILS_FAIL:
   return { loading: false, error: action.payload };
 default:
   return state;
}
}



export {
    serviceListReducer ,
    serviceDetailsReducer ,
    serviceDeleteReducer,
    serviceSaveReducer 
}