import React, { useEffect,useState } from 'react';
import { Container, Grid, Toolbar, IconButton, Typography, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from '@mui/x-data-grid'
import {CancelOutlined, Schedule, Visibility} from '@mui/icons-material'
import styled from 'styled-components'
import ConfirmDialog from '../components/ConfirmDialog';
import { Box } from '@mui/system';
import Popup from '../components/Popup';
import { deleteRdv, saveRdv } from '../actions/rendezvousActions';
import { toast } from 'react-toastify';
import {Link, useNavigate} from 'react-router-dom'
import { publiqueRequest } from '../requestMethods';
import ReservationForm from '../components/Forms/ReservationForm';
import { scheduleRappel } from '../actions/scheduleActions';
import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';


export const MainContainer = styled.div`
    width: auto;
    height: 100vh;
    margin-left: 12rem;
    position: relative; 
   // padding: 0 1rem;
    color:#000;
    background-color: #e8eaeb;

    @media screen  and (max-width:1028px){
       height: 100%;
    }
   

    @media screen  and (max-width:768px){
        margin-left: 0px;
        padding: 0 0rem;
    }
`;

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display:"flex",
        justifyContent:"space-between"
      }}
    >
       <GridToolbarContainer className={gridClasses.toolbarContainer}>
           <GridToolbarExport color="success" />
        </GridToolbarContainer>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: 'auto',
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          '& .MuiSvgIcon-root': {
            mr: 0.5,
          },
          '& .MuiInput-underline:before': {
            borderBottom: 1,
            borderColor: 'divider',
          },
        }}
      />
    </Box>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  
};


 function Rendezvous(){
 
    const dispatch = useDispatch();
    const [rendezvous,setRendezvous] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [rerender, setRerender] = useState(false);

  

    const rendezvousSave = useSelector(state => state.scheduler);
    const {loading: loadingSave,rendezvous:messageSave,success: successSave, error:errorSave} = rendezvousSave;

    const rendezvousDelete = useSelector(state => state.serviceDelete);
    const {loading: loadingDelete,rendezvous:messageDelete,success: successDelete, error:errorDelete} = rendezvousDelete;

    const schedulerSave = useSelector(state => state.scheduler);
    const {loading: loadingScheduler,success: successScheduler, error:errorScheduler} = rendezvousSave;


    const [confirmDialog, setConfirmDialog] = useState({ open: false});
    const [openPopup, setOpenPopup] = useState(false);
    const [recordForEdit, setRecordForEdit] = useState(null);

    const navigate = useNavigate();
    const userSignin = useSelector((state) => state.userSignin);


    const requestSearch = (searchValue) => {
      if(searchValue!==""){
      setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredRows = rendezvous.filter((row) => {
        return Object.keys(row).some((field) => {
           return searchRegex.test(row[field].toString());
          
        });
       
      });
       setRendezvous(filteredRows);
      }else{
        setSearchText("")
        getRdv();
      }
    };
    
    useEffect(() => {
      if(userSignin.userInfo == null){
        navigate('/connexion')
      }
    }, [userSignin])


      const getRdv = async () => {
        try {
          const res = await publiqueRequest.get("/rendez-vous.php/");
              setRendezvous(res.data);
          } catch (err) {
            console.log(err)
          }
      }; 


       useEffect(() => {
          getRdv();
        
       }, [rerender])

       const openInPopup = item => {
        setRecordForEdit(item)
        setOpenPopup(true)
      };

      const onDelete = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            open: false
        });
        if(dispatch(deleteRdv(id))){
          toast("Rendez-vous supprimé avec succès!!!", {
            type: "info",
            theme:"dark"
          });
        }
       
       
        setRerender(!rerender)
        
     };

     const handleSchedule =  async (rdv) => {  

              dispatch(scheduleRappel(rdv));
                  toast("Rappel avec succès!!!", {
                    type: "success",
                    theme: "colored"
                }); 
     }

      const addOrEdit = async (obj,resetForm) => {
       
        try {
 
            if (obj._id === "") {
                
                 if(dispatch(saveRdv(obj))){
                   toast("Rendez-vous ajouté  avec succès!!!", {
                       type: "success",
                       theme:"dark"
                   });
              
                  }
              }else{
                
                if(dispatch(saveRdv(obj))){
                 toast("Rendez-vou mis à jour avec succès!!!", {
                     type: "success",
                     theme:"dark"
                 });
            
                }
             }
            resetForm()
            setRecordForEdit(null)
            setOpenPopup(false)
            getRdv();
        } catch (error) {
            console.error(error);
          }
       }
 

       const columns = [
       
     
       
        {
          field: "action",
          headerName: "Action",
          width: 150,
          renderCell: (params) => {
            return (
              <>

             {
                 userSignin.userInfo.rules === "true" && (
                <IconButton 
                  style={{color:'red'}} 
                  onClick={() => {
                    setConfirmDialog({
                        open: true,
                        onConfirm: () => {onDelete(params.row._id) }
                      })
                    }}
                  >
                        <CancelOutlined fontSize="small"  />
                </IconButton>
                 )}
                <Link to={'/rendez-vous/'+params.row._id} >
                      <IconButton style={{color:'blue'}} >
                              <Visibility fontSize="small"  />
                      </IconButton>
                </Link>
              </>
            );
          },
        },
        {
          field: "status",
          headerName: "Statut",
          width: 120,
          renderCell:(params)=>{
           
              if(params.row.status === "planned"){
                  return(
                    <div  
                    style={{
                      display:'flex',
                      justifyContent:'space-between',
                      position: 'relative'
                    }}
                  >
                      <span style={{color:`${params.row.statusColor}`}} >
                         À venir
                     </span>
                     <span  style={{
                        position: "absolute",
                        backgroundColor:`${params.row.statusColor}`,
                        width: 15,
                        height: 15,
                        borderRadius:"50px",
                        top:"30%",
                        left:"150%"
                     }} >
                     </span>
                  </div>
                  )
              }else if(params.row.status === "canceled"){
                return(
                  <div  
                    style={{
                      display:'flex',
                      justifyContent:'space-between',
                      position: 'relative'
                    }}
                  >
                      <span style={{color:`${params.row.statusColor}`}} >
                         Annulé
                     </span>
                     <span  style={{
                        position: "absolute",
                        backgroundColor:`${params.row.statusColor}`,
                        width: 15,
                        height: 15,
                        borderRadius:"50px",
                        top:"30%",
                        left:"150%"
                     }} >
                     </span>
                  </div>
                  
                )
              }else {
                return(
                <div  
                style={{
                  display:'flex',
                  justifyContent:'space-between',
                  position: 'relative'
                }}
              >
                  <span style={{color:`${params.row.statusColor}`}} >
                    Fait
                 </span>
                 <span  style={{
                    position: "absolute",
                    backgroundColor:`${params.row.statusColor}`,
                    width: 15,
                    height: 15,
                    borderRadius:"50px",
                    top:"30%",
                    left:"150%"
                 }} >
                 </span>
              </div>
              )
              }
            
          }
        },
        {field: "intervention", headerName: "Intervention", width: 220 },
        {field: "another_intervention",headerName: "Autre intervention",width: 220},
        {field: "customerName", headerName: "Patient", width: 220 },
        {field: "bookingDate",headerName: "Date RDV",width: 120},
        {field: "bookingTime",headerName: "Heure RDV",width: 120},
        {field: "teeth",headerName: "Dent",width: 100},
        {field: "owied",headerName: "Doit",width: 120},
        {field: "received",headerName: "Reçu",width: 100},
        {field: "note",headerName: "Note",width: 220},
       
      ];

    return (
        <>
        <MainContainer>
            <Container   maxWidth={false}>
                <Grid container  >
                      <Grid  item xs={12}>
                      <Toolbar>
                                
                                <Box display="flex" justifyContent="space-between"  sx={{flexGrow:1,display:{xs:'flex',md:'flex'}}}>
                                  <Typography
                                      variant="h6"
                                      noWrap
                                      component="div"
                                      sx={{mr:2,display:{xs:'none',md:'flex'}}}
                                   >
                                       Rendez-vous
                                  </Typography>
                                </Box>
                            </Toolbar>
                      </Grid>
                      <Grid  item xs={12} >
                        <div style={{flex:4,height:'75vh', backgroundColor:'white'}} >
                                <DataGrid
                                    components={{ Toolbar: QuickSearchToolbar }}
                                    rows={rendezvous}
                                    disableSelectionOnClick
                                    columns={columns}
                                    getRowId={(row) => row._id}
                                    pageSize={8}
                                    checkboxSelection
                                    componentsProps={{
                                      toolbar: {
                                        value: searchText,
                                        onChange: (event) => requestSearch(event.target.value),
                                        clearSearch: () => requestSearch(''),
                                      },
                                    }}
                                />
                        </div>
                      </Grid>

                </Grid>
              
                <Popup
                   title="Formulaire de Rendez-vous"
                   openPopup={openPopup}
                   setOpenPopup={setOpenPopup}
                 > 
                   <ReservationForm
                       recordForEdit={recordForEdit}
                       addOrEdit={addOrEdit}
                   />
                </Popup>
                <ConfirmDialog
                  confirmDialog={confirmDialog}
                  setConfirmDialog={setConfirmDialog}
                />
              
            </Container>

        </MainContainer>
        </>
       
       
    );
}


export default Rendezvous;