import React, {useEffect, useMemo, useReducer, useState} from 'react';
import dayjs from 'dayjs';
import GlobalContext from './GlobalContext';
import { publiqueRequest, userRequest } from '../../requestMethods';
import { useDispatch, useSelector } from 'react-redux';


function savedInfoEventsReducer(state,{type,payload}) {
    switch (type) {
        case "push":
            return [...state, payload]
        case "update":
            return state.map(evt => evt.id === payload.id  ? payload : evt)
        case "delete":
            return state.filter(evt => evt.id !== payload.id)
        default:
            throw new Error();
    }
} 


function initEvents() {
    const storageInfoEvents = localStorage.getItem("savedInfoEvents");
    const parsedEvents = storageInfoEvents ? JSON.parse(storageInfoEvents) : [];
    return parsedEvents;
}

function ContextWrapper(props) { 

   
    // const [rendezvous,setRendezvous] = useState([]);
    const [isMounted, setIsMounted] = useState(false);
    const [monthIndex,setMonthIndex] = useState(dayjs().month());
    const [yearIndex,setYearIndex] = useState(dayjs().year());
    const [smallCalendarMonth,setSmallCalendarMonth] = useState(null);
    const [smallCalendarYear,setSmallCalendarYear] = useState(null);
    const [daySelected,setDaySelected] = useState(dayjs());
    const [showEventModal,setShowEventModal] = useState(false);
    const [showDialog,setShowDialog] = useState(false);
    const [selectedEvent,setSelectedEvent] = useState(null);
    const [colors,setColors] = useState([])
    const [savedEvents,setSavedEvents] = useState([]) 
    const [fetchData,setFetchData] = useState([]) 
    const [savedInfoEvents,dispatchInfoEvent] = useReducer(
            savedInfoEventsReducer,
            [],
            initEvents
    );

    
    const rendezvousSave = useSelector(state => state.rendezvousSave);
    const {loading: loadingSave,rendezvous:messageSave,success: successSave, error:errorSave} = rendezvousSave;

    const rendezvousDelete = useSelector(state => state.serviceDelete);
    const {loading: loadingDelete,rendezvous:messageDelete,success: successDelete, error:errorDelete} = rendezvousDelete;

    const  filteredEvents = useMemo(() => {
        return savedEvents.filter((evt)=>
            colors
            .filter((lbl)=>lbl.checked)
            .map((lbl)=>lbl.color)
            .includes(evt.color)
        )
    },[savedEvents,colors,fetchData]);

     const getRdv = async () => {
        try {
         const res = await userRequest.get("/rendezvous-status.php/");
               setSavedEvents(res.data);
        } catch (err) {
          console.log(err)
        }
      }; 

      useEffect(() => {
           getRdv();
      }, [messageSave,messageDelete])

    useEffect(() => {
       localStorage.setItem('savedInfoEvents', JSON.stringify(savedInfoEvents));
     }, [savedInfoEvents]);

     useEffect(() => {
         setColors((prevColors)=>{
             return [...new Set(savedEvents.map(evt => evt.color))].map(color => {
                     const currentColor = prevColors.find(
                     (lbl) => lbl.color === color
                 );
                 return {
                     color,
                     checked: currentColor ? currentColor.checked : true,
                 };
             })
         })
      }, [savedEvents,fetchData]);

      useEffect(() => {
         if(!showEventModal){
             setSelectedEvent(null)
         }
      }, [showEventModal]);

    useEffect(() => {
       if(smallCalendarMonth !== null){
           setMonthIndex(smallCalendarMonth)
       }
    }, [smallCalendarMonth]);


    function updatedColor(color) {
        setColors(colors.map((lbl)=> lbl.color === color.color ? color : lbl))
    }


    return (
        <GlobalContext.Provider  
            value={{
                    monthIndex,
                    setMonthIndex,
                    smallCalendarMonth,
                    setSmallCalendarMonth,
                    yearIndex,
                    setYearIndex,
                    smallCalendarYear,
                    setSmallCalendarYear,
                    daySelected,
                    setDaySelected,
                    showEventModal,
                    setShowEventModal,
                    savedInfoEvents,
                    dispatchInfoEvent,
                    savedEvents,
                    setSavedEvents,
                    selectedEvent,
                    setSelectedEvent,
                    setColors,
                    colors,
                    updatedColor,
                    filteredEvents,
                    showDialog,
                    setShowDialog,
                    getRdv,
                    fetchData,
                    setFetchData
                }} 
            >
            {props.children}
        </GlobalContext.Provider>
    )
}

export default ContextWrapper
