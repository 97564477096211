import React from 'react'
import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../components/footer/Footer'
import MobileSidebar from '../components/MobileSidebar/Index'
import NavbarDash from '../components/NavbarDash/Navbar'
import Sidebar from '../components/Sidebar/Sidebar'


function DashLayout({children}) {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

   
    return (
        
        <>
            <Sidebar    />
                <MobileSidebar isOpen={isOpen}  toggle={toggle}/>
                <NavbarDash   toggle={toggle} />
                <Outlet />
            <Footer/>
        </>
    )
}  

export default DashLayout
