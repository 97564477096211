import React,{useContext} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import GlobalContext from '../../context/GlobalContext';




export default function DraggableDialog() {


  const {
    setShowDialog,
    showDialog
} = useContext(GlobalContext)


  const handleClickOpen = () => {
    setShowDialog(true);
  };

  const handleClose = () => {
     setShowDialog(false)
  };

  return (
    <div>
     
      <Dialog
        open={showDialog}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle  id="draggable-dialog-title">
         RDV indisponible
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Désolé cette heure est déjà prise pour cette date, veuillez choisir une autre!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         
          <Button variant="contained" color="error" onClick={handleClose}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}