import React from 'react';
import {BrowserRouter as Router,Route, Routes} from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashLayout from './pages/DashLayout';
import Dashboard from './pages/Dashboard';
import Utilisateurs from './pages/Utilisateurs';
import Connexion from './pages/Connexion';
import Rendezvous from './pages/Rendezvous';
import Services from './pages/Services';
import Agenda from './pages/Agenda';
import FicheClients from './pages/FicheClients';
import FicheClient from './pages/FicheClient';
import SingleRendezvous from './pages/SingleRendevous';
import Profil from './pages/Profil';
import Erreur from './pages/Erreur';
import Facturation from './pages/Facturation';
import Factures from './pages/Factures';
import Facture from './pages/Facture';






function App() {
  return (
     
      <>
    
          <Router basename='/' >
              
                  <Routes>
                        {/*<Route  path="/"  element={<Home/>}  />*/}
                        <Route  path="connexion"  element={<Connexion/>}  />
                        <Route path='*' element={<Erreur />} />
                        <Route  path="/" element={<DashLayout/>}>
                            <Route  path="utilisateurs"  element={<Utilisateurs/>}  />
                            <Route  path="rendez-vous"  element={<Rendezvous/>}  />
                            <Route  path="rendez-vous/:id"  element={<SingleRendezvous/>}  />
                            <Route  path="services"  element={<Services/>}  />
                            <Route  path="agenda"  element={<Agenda/>}  />
                            <Route  path="profil"  element={<Profil/>}  />
                            <Route  path="facturation/:id"  element={<Facturation/>}  />
                            <Route  path="factures/"  element={<Factures/>}  />
                            <Route  path="facture/:id"  element={<Facture/>}  />
                            <Route  path="fiche-clients"  element={<FicheClients/>}  />
                            <Route  path="fiche-client/:id"  element={<FicheClient/>}  />
                            <Route index element={<Dashboard/>}  />
                       </Route>
                  </Routes>
              
              
                  
              
               <ToastContainer/> 
          </Router>
       
        </>
     
    
  );
}

export default App;
