import React,{useEffect, useState} from 'react'
import {   
    Paper,
    Container,
    Box,
    Grid,
    TextField,
    Typography,
    Button,
    
    } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { signin } from '../actions/userActions';
import { Link, useNavigate } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import Img from '../images/logo.png'
import ImgBack from '../images/atikah-akhtar-XJptUS8nbhs-unsplash.jpg'




 

function Connexion(props) {

   
    const [email, setEmail] = useState('') ;
    const [password, setPassword] = useState('');
    const userSignin = useSelector(state => state?.userSignin);
    const {loading,userInfo, error} = userSignin;
    const dispatch = useDispatch(); 
    const navigate = useNavigate()
   // const redirect = props.location.search?props.location.search.split("=")[1]:'/';

   

    useEffect(() => { 
       
        if(userInfo){
            navigate('/')
        }
       
  }, [userInfo]);


    const handleClick = (e) => {
        e.preventDefault()
        dispatch(signin(email,password))
      }; 

    
    return (
        <Box
        
          
            sx={{
               // backgroundColor: 'background.default',
                minHeight: '100%',
          }}
       >
            <form onSubmit={handleClick}
                            style={{
                               
                                //backgroundColor:'#7faf02',
                                background:`url(${ImgBack})`,
                                height: "100vh",
                                
                                zIndex: 1

                               
                               
                            }}
                     >
       
           <Container  maxWidth="sm">
                
                    
                <Grid container >

                     <Grid item lg={12} xs={12}
                        style={{
                            backgroundColor:'#7faf02',
                            marginTop:100,
                            borderRadius:10,
                            boxShadow: "12px 12px 2px 1px #00000033",
                            zIndex: 2
                        }}
                      
                     >
                    
                        <Box 
                        pt={10} 
                        mb={3}
                        
                        >
                            <div
                                    style={{
                                        display:'flex',
                                        zIndex: 10,
                                        marginBottom: "10px", 
                                        textAlign:'center',
                                        alignItems:'center',
                                        justifyContent:'center'
                                        
                                    }}
                            >
                                <img 
                                    src={Img} 
                                    alt="Logo Fonq Meedi+" 
                                    style={{
                                        height:" 6rem",
                                        width: "6rem",
                                        display: "block",
                                        borderRadius: "50px",
                                        objectFit: "cover",
                                        backgroundColor:'white'
                                       
                                    }}
                                />
                            </div>
                            <Typography
                                color="textPrimary"
                                variant="h4"
                                justifyContent="center"
                                style={{
                                    textAlign:'center',
                                    color:"white"
                                }}
                                
                            >
                             Se Connecter
                            </Typography>
                            
                            <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="body2"
                            >
                                {loading && <div>Chargement...</div> }
                                 {error && <div>{error}</div> }
                            </Typography>
                           
                            </Box>
                        
                        <Grid  container >
                        
                            <Grid item lg={12}  xs={12} display="flex" justifyContent="center" >
                                
                                    <TextField
                                        label="Email"
                                        margin="normal"
                                        type="email"
                                        onChange={(e)=> setEmail(e.target.value)}
                                        variant="filled"
                                        
                                        size="medium"
                                        required
                                    />
                                    </Grid>
                                <Grid item lg={12}  xs={12} display="flex" justifyContent="center" >
                                    <TextField
                                            label="Mot de passe"
                                            margin="normal"
                                            type="password"
                                            onChange={(e)=> setPassword(e.target.value)}
                                            variant="filled"
                                            size="medium"
                                            required
                                    />

                            
                                </Grid>
                            </Grid>
                        
                            <Box my={2} display="flex" justifyContent="center">
                            <Button
                               
                               
                                style={{backgroundColor: '#007bff'}}
                                size="medium"
                                type="submit"
                                variant="contained"
                            >
                                Se connecter
                            </Button>
                            </Box>
               
                     </Grid>

                </Grid>
                
                    
           </Container>
           </form>
         </Box>
     
    )
}

export default Connexion 