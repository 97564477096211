import React,{useEffect} from 'react'
import {
     Button,
     Grid,
     Box, 
     Container, 
     TextField,
     Select, 
     MenuItem, 
     InputLabel, 
     FormControl,
    } from '@mui/material'
import useForm from './useForm'
import { format } from 'date-fns'


const initialFieldValues = {
    _id: '',
    firstname: '',
    lastname:'',
    genre:'',
    date_naissance:format(new Date(), 'dd/MM/yyyy'),
    adresse:'',
    telephone:'',
    telephone_2:'',
    profession:'',
    observation:'' 
}

const genreTab = [
    "homme",
    "femme"
]

const situationTab = [
    "Célibataire",
    "Mariée",
    "Divorcée/Veuve"
]


function CustomerForm({recordForEdit,addOrEdit,...props}) {

   
    const validate = () => {
        let temp = { ...errors }
        temp.firstname = values.firstname ? "" : "Le nom du client est requis"
        temp.lastname = values.lastname ? "" : "Le prénom du client est requis"
        temp.telephone = values.telephone ? "" : "Le contact du client est requis"
        temp.observation = values.observation ? "" : "L'observation du client est requise"
        temp.genre = values.genre ? "" : "Le genre du client est requis"
        //temp.situation = values.situation ? "" : "L'âge du client est requis"
       
        setErrors({
            ...temp
        })
        return Object.values(temp).every(x => x === "")
    }

    var {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleInputFileChange,
        resetForm
    } = useForm(initialFieldValues)

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values,resetForm)
        } 
    }

    useEffect(() => {
        if (recordForEdit !== null){
            setValues({
                ...recordForEdit
            })
            setErrors({})
        } 
    }, [recordForEdit])


    return (
        <Container>
            <Grid container>
             <form 
                noValidate 
                autoComplete="off" 
                onSubmit={handleSubmit}
             >
                                       
                                       <Grid  item lg={12} xs={12}>
                                               
                                               <TextField 
                                                   style={{marginBottom:10}} 
                                                   fullWidth 
                                                   variant="outlined" 
                                                   name="firstname" 
                                                   label="Nom" 
                                                   value={values.firstname}
                                                   type="text"
                                                   onChange={handleInputChange}
                                                   {...(errors.firstname && { error: true, helperText: errors.firstname })}
                                               />
                                                <TextField 
                                                   style={{marginBottom:10}} 
                                                   fullWidth 
                                                   variant="outlined" 
                                                   name="lastname" 
                                                   label="Prénom" 
                                                   value={values.lastname}
                                                   type="text"
                                                   onChange={handleInputChange}
                                                   {...(errors.lastname && { error: true, helperText: errors.lastname })}
                                                  
                                               />
                                                <TextField 
                                                    style={{marginBottom:10}} 
                                                                id="date_naissance"
                                                                label="Date de naissance"
                                                                type="date"
                                                                name="date_naissance"
                                                                fullWidth
                                                                defaultValue={values.date_naissance}
                                                                sx={{ width: 220 }}
                                                                onChange={handleInputChange}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                               
                                                    />
                                                <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Genre</InputLabel>
                                                        <Select
                                                        style={{marginBottom:10}} 
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            name="genre"
                                                            value={values.genre}
                                                            onChange={handleInputChange}
                                                            fullWidth
                                                            {...(errors.genre && { error: true, helperText: errors.genre })}
                                                            >
                                                            {genreTab.map(function(val,index){
                                                                return(
                                                                    <MenuItem key={index} value={val}>{val}</MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                </FormControl>
                                                <TextField 
                                                   style={{marginBottom:10}} 
                                                   fullWidth 
                                                   variant="outlined"
                                                   name="adresse" 
                                                   label="Adresse" 
                                                   value={values.adresse}
                                                   type="text"
                                                   onChange={handleInputChange}
                                                  
                                               />
                                                <TextField 
                                                   style={{marginBottom:10}} 
                                                   fullWidth 
                                                   variant="outlined" 
                                                   name="telephone" 
                                                   label="Téléphone" 
                                                   value={values.telephone}
                                                   type="text"
                                                   onChange={handleInputChange}
                                                   {...(errors.telephone && { error: true, helperText: errors.telephone })}
                                               />
                                               <TextField 
                                                   style={{marginBottom:10}} 
                                                   fullWidth 
                                                   variant="outlined" 
                                                   name="telephone_2" 
                                                   label="Téléphone" 
                                                   value={values.telephone_2}
                                                   type="text"
                                                   onChange={handleInputChange}
                                                  
                                               />
                                                  <TextField 
                                                   style={{marginBottom:10}} 
                                                   fullWidth 
                                                   variant="outlined"
                                                   name="profession" 
                                                   label="Profession" 
                                                   value={values.profession}
                                                   type="text"
                                                   onChange={handleInputChange}
                                                  
                                                  
                                               />
                                             <TextField 
                                                   style={{marginBottom:10}} 
                                                   fullWidth 
                                                   variant="outlined"
                                                   name="observation" 
                                                   label="Observation" 
                                                   value={values.observation}
                                                   type="text"
                                                   onChange={handleInputChange}
                                                   {...(errors.observation && { error: true, helperText: errors.observation })}
                                                  
                                               />
                                           {/*
                                                <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                                        <Select
                                                        style={{marginBottom:10}} 
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            name="age"
                                                            value={values.age}
                                                            onChange={handleInputChange}
                                                            fullWidth
                                                            {...(errors.age && { error: true, helperText: errors.age })}
                                                            >
                                                            {ageTab.map(function(val,index){
                                                                return(
                                                                    <MenuItem key={index} value={val}>{val}</MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                </FormControl>
                                                        */ }
                                              {/* <TextField 
                                                   style={{marginBottom:10}} 
                                                   fullWidth 
                                                   variant="outlined"
                                                   name="situation" 
                                                   label="Situation matrimaniale" 
                                                   value={values.situation}
                                                   type="text"
                                                   onChange={handleInputChange}
                                                   {...(errors.situation && { error: true, helperText: errors.situation })}
                                              />*/}
                                             
                                            
                                             
                                               
                                               <Box p={2}>
                                                   <Button
                                                   style={{backgroundColor:'#7faf02'}}
                                                   variant="contained"
                                                   fullWidth
                                                   size="large"
                                                   color="primary"
                                                   type="submit"
                                                   >
                                                       {recordForEdit ? "Modifier" : "Ajouter"}
                                                   </Button>
                                               </Box>
                                       </Grid>
                                   
                                 </form>
                </Grid>
        </Container>
    )
}

export default CustomerForm