import React, { useContext } from 'react'
import { Typography, Button, IconButton } from '@mui/material'
import GlobalContext from '../../context/GlobalContext'
import dayjs from 'dayjs'
import { Add, DateRange } from '@mui/icons-material'
import { translateMonth } from '../../utils/utils'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import styled from 'styled-components'



export const Header = styled.header`
    display:flex;
    align-items:center;
    justify-content:space-between;
    height: 45px;
   
    @media screen  and (max-width:1198px){
        display:none;
    }
`;

export const HeaderMini = styled.header`
    display:none;
    padding:1px;
    
   
    @media screen  and (max-width:1198px){
        display:flex;
        align-items:center;
        justify-content:space-between;
        height: 45px;
    }
`;

function CalendarHeader() {

    const {monthIndex,setMonthIndex,yearIndex,setYearIndex} = useContext(GlobalContext)
    
    function handlePrevMonth() {
        setMonthIndex(monthIndex -1);
    }
    function handleNextMonth() {
        setMonthIndex(monthIndex + 1);
    }

    function handlePrevYear() {
        setYearIndex(yearIndex -1);
    }
    function handleNextYear() {
        setYearIndex(yearIndex + 1);
    }


    function handleReset() {
        setMonthIndex( monthIndex === dayjs().month() ? monthIndex + Math.random(): dayjs().month() )
        setYearIndex( yearIndex === dayjs().year() ? yearIndex : dayjs().year() )
    }
    return (
        <>
         <Header >
              
                <div style={{display:'flex'}}>
                    <Button variant="outlined"  style={{width:30,fontSize:15,borderColor:'#7faf02', backgroundColor:"#7faf02", color:'#ffffff'}} onClick={handlePrevMonth} startIcon={<ArrowBackIosNewIcon />} />
                    <Button variant="outlined"  style={{width:30,fontSize:15,borderColor:'#7faf02',borderLeftColor:"#777575",backgroundColor:"#7faf02", color:'#ffffff'}} onClick={handleNextMonth} startIcon={<ArrowForwardIosIcon />} />
                </div>
                <Typography fontSize={15} py={4} variant="inherit" component="p" style={{color:'#000'}} >
                   {translateMonth(dayjs(new Date(dayjs().year(),monthIndex)).format("MMMM"))} {" "}
               </Typography>
                <Button 
                   variant="outlined"
                   size="small"
                   style={{
                       color:'#fff',
                       marginRight:5,
                       marginLeft:10,
                       textTransform:'none',
                       borderColor:'#7faf02',
                       backgroundColor:"#7faf02"
                   }} 
                  onClick={handleReset} 
                >
                {" "} Aujourd'hui
               </Button>
             
               <Typography fontSize={15} py={4} variant="inherit" component="p" style={{color:'#000'}} >
               {" "} {yearIndex}
               </Typography>
                <div style={{display:'flex'}}>
                    <Button variant="outlined"  style={{width:30,fontSize:15,borderRightColor:"#777575",backgroundColor:"#7faf02",  borderColor:'#7faf02', color:'#ffffff'}} onClick={handlePrevYear} startIcon={<ArrowBackIosNewIcon />} />
                    <Button variant="outlined"  style={{width:30,fontSize:15,borderLeftColor:"#777575", backgroundColor:"#7faf02",  borderColor:'#7faf02', color:'#ffffff'}} onClick={handleNextYear} startIcon={<ArrowForwardIosIcon />} />
                </div>
              
             
         </Header>
         <HeaderMini >
              
              <div style={{display:'flex'}}>
                  <Button variant="outlined"  style={{maxWidth:20, maxHeight:20,minWidth:20,minHeight:15, fontSize:10,borderColor:'#7faf02', backgroundColor:"#7faf02", color:'#ffffff'}} onClick={handlePrevMonth} startIcon={<ArrowBackIosNewIcon />} />
                  <Button variant="outlined"   style={{maxWidth:20, maxHeight:20,minWidth:20,minHeight:15, fontSize:10,borderColor:'#7faf02',borderLeftColor:"#777575",backgroundColor:"#7faf02", color:'#ffffff'}} onClick={handleNextMonth} startIcon={<ArrowForwardIosIcon />} />
              </div>
              <Typography fontSize={12} py={4} variant="inherit" component="p" style={{color:'#000'}} >
                 {translateMonth(dayjs(new Date(dayjs().year(),monthIndex)).format("MMMM"))} {" "}
             </Typography>
              <Button 
                 variant="outlined"
                 size="small"
                 style={{
                     color:'#fff',
                     marginRight:5,
                     marginLeft:10,
                     textTransform:'none',
                     borderColor:'#7faf02',
                     backgroundColor:"#7faf02",
                     fontSize:10
                 }} 
                onClick={handleReset} 
              >
              {" "} Aujourd'hui
             </Button>
           
             <Typography fontSize={12} py={4} variant="inherit" component="p" style={{color:'#000'}} >
             {" "} {yearIndex}
             </Typography>
              <div style={{display:'flex'}}>
                  <Button variant="outlined" style={{maxWidth:20, maxHeight:20,minWidth:20,minHeight:15, fontSize:10,borderColor:'#7faf02', backgroundColor:"#7faf02", color:'#ffffff'}}  onClick={handlePrevYear} startIcon={<ArrowBackIosNewIcon />} />
                  <Button variant="outlined"  style={{maxWidth:20, maxHeight:20,minWidth:20,minHeight:15, fontSize:10,borderColor:'#7faf02', backgroundColor:"#7faf02", color:'#ffffff'}}  onClick={handleNextYear} startIcon={<ArrowForwardIosIcon />} />
              </div>
            
           
          </HeaderMini>
        </>
       
    )
}

export default CalendarHeader
