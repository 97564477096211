import React,{useEffect} from 'react';
import { IconContext } from 'react-icons/lib';
import { 
   Nav, 
   NavBtn,
   NavIcon, 
   NavMenu, 
   LogoIcon,
   NavItem,
   NavItemMobile,
   NavIconA,
   NavIconMobile,
   Logo
   }  
from './NavbarElements';
import {Button } from '@mui/material';
import { useSelector,useDispatch } from 'react-redux';
import { logout } from '../../actions/userActions'; 
import Img from '../../images/Fonq-2.png';
import { useNavigate } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';



const NavbarDash = ({toggle}) => {


  const userSignin = useSelector((state) => state?.userSignin);
  const { userInfo } = userSignin;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  var current_time = new Date().getTime() / 1000;


  


  const handleLogout = () => {
    dispatch(logout());
   
  };

  useEffect(() => {
    
    if(userInfo !== undefined || userInfo !==null){
       if(userInfo?.expireAt < current_time){
         dispatch(logout())
         navigate('/connexion')
       } 
    }
 }, []);
 
  
  
  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav> 
            <LogoIcon onClick={toggle}>
              <FaIcons.FaBars  />
            </LogoIcon>
            <NavIcon to='/'>
               <Logo 
                 src={Img} 
                 alt="logo fonq" 
                />
             </NavIcon>
           <NavMenu>
          
           {userInfo ? (
              <>
               <NavItemMobile>
                   <NavIconMobile to='/connexion'>
                      <FaIcons.FaUser/>
                      {userInfo.pseudo}
                    </NavIconMobile>
                </NavItemMobile>
                <NavItemMobile> 
                    <Button
                        variant="text"
                        color="inherit"
                        startIcon={<AiIcons.AiOutlineLogout/>}
                        onClick={()=>handleLogout()}
                      />
                </NavItemMobile>
                </>
              ) : (
                <>
                <NavItemMobile>
                    <NavIconMobile to='/connexion'>
                        <FaIcons.FaUser/>
                          Se connecter 
                      </NavIconMobile>
                </NavItemMobile>
                </>
             )} 
          </NavMenu>
          <NavBtn>
            {userInfo ? (
              <>
              <NavIcon to='/'>
                 <FaIcons.FaUser/>
                  {userInfo.pseudo}
              </NavIcon>
              
               <NavIcon to='#'>
                 <Button
                   variant="text"
                   color="inherit"
                   startIcon={<AiIcons.AiOutlineLogout/>}
                   onClick={()=>handleLogout()}
                 />
              </NavIcon>
              </>
            ) : (
              <>
              <NavIcon to='/connexion'>
                  <FaIcons.FaUser  />
                   Se connecter
              </NavIcon>
            {/*  <NavIcon to='/inscription'>
                  S'inscrire
            </NavIcon>  */}
              </>
             )} 
          </NavBtn>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default NavbarDash;