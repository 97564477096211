import React, { useEffect,useState,useRef } from 'react';
import { Container, Divider, Grid, Paper, Toolbar, Typography, Button, Box } from '@mui/material';
import styled from 'styled-components'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { Add, AttachFile, PictureAsPdf, SaveAlt, Visibility, CancelOutlined, Delete } from '@mui/icons-material';
import Img from '../images/36_113676.svg'
import ReactToPrint from 'react-to-print';
import { publiqueRequest,baseHostUrlFiles,userRequest, baseHostUrlProfil } from '../requestMethods';
import { 
   DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses, } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { styled as styledMui} from '@mui/material/styles';
import Popup from '../components/Popup';
import * as MdIcons from 'react-icons/md';
import { deleteRdv } from '../actions/rendezvousActions';
import ConfirmDialog from '../components/ConfirmDialog';
import { toast } from 'react-toastify';





export const MainContainer = styled.div`
    width: auto;
    height: 100%;
    margin-left: 12rem;
    position: relative; 
    margin-top: 14px;
   // padding: 0 1rem;
    color:#000;
    background-color: #e8eaeb;

    @media screen  and (max-width:1028px){
       height: 100%;
    }
   

    @media screen  and (max-width:768px){
        margin-left: 0px;
        padding: 0 0rem;
    }
`;

export const DivBox = styled.div`
    @media screen  and (max-width:768px){
        margin-left: 0px;
        padding: 0 0rem;
        height: 100vh;
    }
`;


function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }
  
  function QuickSearchToolbar(props) {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
         <GridToolbarContainer className={gridClasses.toolbarContainer}>
           <GridToolbarExport color="success" />
        </GridToolbarContainer>
        <TextField
          variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder="Search…"
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          sx={{
            width: {
              xs: 1,
              sm: 'auto',
            },
            m: (theme) => theme.spacing(1, 0.5, 1.5),
            '& .MuiSvgIcon-root': {
              mr: 0.5,
            },
            '& .MuiInput-underline:before': {
              borderBottom: 1,
              borderColor: 'divider',
            },
          }}
        />
      </Box>
    );
  }
  
  QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  };

  const Input = styledMui('input')({
    display: 'none',
  });

function FicheClient() {

    const location = useLocation();
    const dispatch = useDispatch();
    const [customer,setCustomer] = useState({});
    const [customerRdv,setCustomerRdv] = useState([]);
    const [pieces,setPieces] = useState([]);
    const customerId = location.pathname.split("/")[2];
    const [searchText, setSearchText] = useState('');
    const [name,setName] = useState("");
    const selectedFile = useRef()
    const [count,setCount] = useState(0);
    const [openPopup, setOpenPopup] = useState(false);
    const [reponseFilename,setReponseFilename] = useState("");
    const [reponseCurrent,setReponseCurrent] = useState("");
    const [picture,setPicture] = useState("");
    const [profil,setProfil] = useState([]);

    const [confirmDialog, setConfirmDialog] = useState({ open: false});
    const [recordForEdit, setRecordForEdit] = useState(null);

    const rendezvousSave = useSelector(state => state.scheduler);
    const {loading: loadingSave,success: successSave, error:errorSave} = rendezvousSave;

    const rendezvousDelete = useSelector(state => state.serviceDelete);
    const {loading: loadingDelete,rendezvous:messageDelete,success: successDelete, error:errorDelete} = rendezvousDelete;

   
    const [renderUpload,setRenderUpload] = useState("");

    const navigate = useNavigate();
    const userSignin = useSelector((state) => state.userSignin);

    const componentRef = useRef();

    const requestSearch = (searchValue) => {
      if(searchValue!==""){
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = customerRdv.filter((row) => {
          return Object.keys(row).some((field) => {
             return searchRegex.test(row[field].toString());
          }); 
        });
        setCustomerRdv(filteredRows);
      }else{
            setSearchText("")
            getCustomerRdv();
      }
      };
  
    useEffect(() => {
        if(userSignin.userInfo == null){
          navigate('/connexion')
        }
      }, [userSignin])

      useEffect(() => {
        let isAmounted = true;
        const getProfil = async () => {
            try {
             const res = await publiqueRequest.get("/profil.php/");
              setProfil(res.data);
              setPicture(res.data[0].photo)
            } catch (err) {
              console.log(err)
            }
          }; 
          getProfil();
         return ()=>{isAmounted = false}
       }, []);

      useEffect(() => {
        let isAmounted = true;
        const getCustomer = async () => {
            try {
             const res = await publiqueRequest.get("/customers.php/?_id="+customerId);
              setCustomer(res.data);
            } catch (err) {
              console.log(err)
            }
          }; 
         getCustomer();
         return ()=>{isAmounted = false}
       }, []);

       useEffect(() => {
        let isAmounted = true;
        const getPieces = async () => {
            try {
             const res = await publiqueRequest.get("/uploads.php/?customerId="+customerId);
              setPieces(res.data);
            } catch (err) {
              console.log(err)
            }
          }; 
          getPieces();
         return ()=>{isAmounted = false}
       }, [renderUpload]);

       const getCustomerRdv = async () => {
        try {
         const res = await publiqueRequest.get("/rendez-vous.php/?customerId="+customerId);
          setCustomerRdv(res.data);
        } catch (err) {
          console.log(err)
        }
      }; 
       useEffect(() => {
        let isAmounted = true;
        
         getCustomerRdv();
         return ()=>{isAmounted = false}
       }, [successDelete]);
      


    


    const uploadFile = async () => {
      if(name === ""){
        setReponseFilename("Le titre du fichier est obligatoire")
      }
      if(selectedFile.current.files.length===0){
        setReponseCurrent("Choisisser un fichier")
      }else{
        const fd = new FormData();
        fd.append('file', selectedFile.current.files[0]);
        fd.append('customerId',customerId);
        fd.append('name',name);
        await publiqueRequest.post('/uploads.php/',fd,{
          onUploadProgress:progressEvent=>{
            console.log("téléchargement:"+ Math.round( progressEvent.loaded/progressEvent.total*100)+"%");
            setCount(Math.round( progressEvent.loaded/progressEvent.total*100))
          }
        }).then(res=>{
          setRenderUpload(res.statusText)
        })
        setOpenPopup(false)
        if(count >= 100){
          toast("Fichier téléchargé avec succès!!!", {
            type: "success",
            theme: "colored"
          });
        }
      }
     
    }

    const renderFile = () => {

      if(pieces.length>0){
        return(
          pieces.map((item,index) => (
            <div style={{display:'flex',justifyContent:"space-between"}}>
            { userSignin.userInfo.rules === "true" && (
                       <span 
                            onClick={ async () => {
                               const res = await userRequest.delete('/uploads.php/?_id='+item._id)
                                if(res.statusText==="OK"){
                                    toast("Pièce jointe supprimée avec succès!!!", {
                                      type: "info",
                                      theme: "colored"
                                    });
                                   
                                    setRenderUpload(res.status)
                                }  
                            }}
                            style={{color:'red',cursor:'pointer'}} 
                          >
                            <Delete/>
                          </span>
                     )}
                   <a target='_blank' href={baseHostUrlFiles+`${item.name}`} style={{
                      display:'flex',
                      textDecoration:'none',
                      padding:5,
                      justifyContent:'space-between'
    
                  }} 
                  key={item._id}
                >
                    <MdIcons.MdFileCopy size={40} color="gray" />
                    <span style={{padding:3,color:'blue'}} > {item.name} </span>
                </a>
          </div>
            ))
        )
      }else{
        return(
            <Typography p={2} style={{color:'gray'}} variant="h6" component="h6" > Aucune pièce jointe ! </Typography>
        )
      }
    }

    const onDelete = (id) => { 
      setConfirmDialog({
          ...confirmDialog,
          open: false
      });
     
      dispatch(deleteRdv(id));
      if(messageDelete.message==true){
          toast("Intervention supprimée avec succès!!!", {
            type: "info",
          });
      }  
     
     };

    const columns = [
      {
        field: "action",
        headerName: "Action",
        width: 150,
        renderCell: (params) => {
          return (
            <>
              <Link to={'/rendez-vous/'+params.row._id} >
                    <IconButton style={{color:'blue'}} >
                            <Visibility fontSize="small"  />
                    </IconButton>
              </Link>
             
              {
                 userSignin.userInfo.rules === "true" && (
                <IconButton 
                  style={{color:'red'}} 
                  onClick={() => {
                    setConfirmDialog({
                        open: true,
                        onConfirm: () => {onDelete(params.row._id) }
                      })
                    }}
                  >
                        <CancelOutlined fontSize="small"  />
                </IconButton>
                 )}
            </>
          );
        },
      },
      {
        field: "status",
        headerName: "Statut",
        width: 120,
        renderCell:(params)=>{
         
            if(params.row.status === "planned"){
                return(
                  <div  
                  style={{
                    display:'flex',
                    justifyContent:'space-between',
                    position: 'relative'
                  }}
                >
                    <span style={{color:`${params.row.statusColor}`}} >
                       À venir
                   </span>
                   <span  style={{
                      position: "absolute",
                      backgroundColor:`${params.row.statusColor}`,
                      width: 15,
                      height: 15,
                      borderRadius:"50px",
                      top:"30%",
                      left:"150%"
                   }} >
                   </span>
                </div>
                )
            }else if(params.row.status === "canceled"){
              return(
                <div  
                  style={{
                    display:'flex',
                    justifyContent:'space-between',
                    position: 'relative'
                  }}
                >
                    <span style={{color:`${params.row.statusColor}`}} >
                       Annulé
                   </span>
                   <span  style={{
                      position: "absolute",
                      backgroundColor:`${params.row.statusColor}`,
                      width: 15,
                      height: 15,
                      borderRadius:"50px",
                      top:"30%",
                      left:"150%"
                   }} >
                   </span>
                </div>
                
              )
            }else {
              return(
              <div  
              style={{
                display:'flex',
                justifyContent:'space-between',
                position: 'relative'
              }}
            >
                <span style={{color:`${params.row.statusColor}`}} >
                  Fait
               </span>
               <span  style={{
                  position: "absolute",
                  backgroundColor:`${params.row.statusColor}`,
                  width: 15,
                  height: 15,
                  borderRadius:"50px",
                  top:"30%",
                  left:"150%"
               }} >
               </span>
            </div>
            )
            }
          
        }
      },
      {field: "customerName", headerName: "Patient", width: 200 },
      {field: "bookingDate", headerName: "Date d'intervention", width: 150 },
      {field: "teeth",headerName: "Dent",width: 100},
      {field: "intervention",headerName: "Intervention",width: 220},
      {field: "another_intervention",headerName: "Autre intervention",width: 220},
      {field: "owied",headerName: "Doit",width: 100},
      {field: "received",headerName: "Reçu",width: 100},
      {field: "note",headerName: "Note",width: 220},
   
     
    ];
    return ( 
        <MainContainer>
            <Container   maxWidth={false} >
              
                <Grid container  spacing={2}>
                    <Grid  item xs={12} >
                        <Toolbar
                            style={{
                                justifyContent:'space-between'
                            }}
                        >
                            <Link to="/agenda" style={{textDecoration:'none'}} >
                              <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<Add/>}
                                        style={{
                                            textTransform:'none'
                                        }}
                                        
                                      >
                                        Ajouter intervention
                             </Button>
                             </Link>
                  
                                  <Popup
                                   title="Télécharger une pièce jointe"
                                   openPopup={openPopup}
                                   setOpenPopup={setOpenPopup}
                                  >
                                    <Grid container spacing={2}> 
                                        <Grid item xs={12}>
                                            <TextField  variant="outlined" label="nom du fichier" type="text" onChange={(e)=>setName(e.target.value)} />
                                            <Typography  color="red" >{reponseFilename} </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <input  type="file" ref={selectedFile} />
                                            <Typography  color="red" > {reponseCurrent} </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <Button  
                                                  startIcon={<SaveAlt/>} 
                                                  color="primary" 
                                                  variant="contained"
                                                  style={{
                                                    textTransform:'none'
                                                }}
                                                onClick={uploadFile}
                                                >
                                                     Télécharger
                                              </Button>
                                          </Grid>
                                    </Grid>
                                  </Popup>
                                 
                               
                             <ReactToPrint
                                    trigger={() => 
                                       <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<PictureAsPdf/>}
                                    
                                        style={{
                                            textTransform:'none'
                                        }}
                                        
                                      >
                                        Imprimer
                                      </Button>
                                    }
                                    content={() => componentRef.current}
                             />
                           
                        </Toolbar>
                    </Grid>

                    <Grid item lg={6} xs={12}>
                            <Paper 
                                style={{
                                    width:'100%',
                                    justifyContent:'center',
                                }}
                                elevation={1}
                                ref={componentRef}
                            >
                                 <Box  p={2} display="flex" justifyContent="space-between" >
                                        <div>
                                          {
                                           profil ?
                                            profil.map((item) => (
                                              <div key={item._id} >
                                              <Typography> {item.name} </Typography>
                                              <Typography> Tel 1 : {item.contact_1} </Typography>
                                             { item.contact_2 ? <Typography> Tel 2 :  {item.contact_2} </Typography> : ""}
                                              <Typography> Adresse : {item.adresse} </Typography>
                                              </div>
                                            ))
                                           :
                                           <div>
                                              <Typography> Cabinet Dentaire </Typography>
                                              <Typography> Tel : </Typography>
                                              <Typography> Adresse : </Typography>
                                           </div>
                                          }
                                        </div>
                                        <div  style={{alignItems:'center',display:"block",justifyContent:'center'}} >
                                             <Typography  align='center' variant="subtitle1" component="h6" >
                                                <b>Fiche Patient</b> 
                                            </Typography>
                                            <img 
                                                style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    borderRadius:"50%",
                                                    //filter: "invert(70%) sepia(74%) saturate(502%) hue-rotate(35deg) brightness(97%) contrast(78%)"
                                                    
                                                }} 
                                                src={ picture ? baseHostUrlProfil+`${picture}` : Img} alt="logo dent" 
                                            />
                                           
                                        </div>
                                       
                                       {/* <img 
                                            style={{
                                              width: "10%",
                                              height: "10%"
                                            }}
                                            
                                            ={preview} 
                                            alt={file.name}
                                          />*/}
                                       
                                </Box>
                                  
                                 <Divider/>
                                 <DivBox>
                                 <Box style={{marginLeft:10,marginTop:20}}>
                                        <Typography p={1} variant="subtitle1" >
                                            <b>Nom : </b> {customer.firstname}
                                        </Typography>
                                        <Typography p={1}  variant="subtitle1" >
                                            <b>Prénom : </b> {customer.lastname}
                                        </Typography>
                                        <Typography p={1}  variant="subtitle1" >
                                            <b>Genre : </b> {customer.genre}
                                        </Typography>
                                        <Typography p={1}  variant="subtitle1" >
                                            <b>Date de naissance : </b> {customer.date_naissance}
                                        </Typography>
                                        <Typography p={1}  variant="subtitle1" >
                                            <b>Adresse : </b> {customer.adresse}
                                        </Typography>
                                        <Typography  p={1}  variant="subtitle1" >
                                            <b>Téléphone 1 : </b> {customer.telephone}
                                        </Typography>
                                        <Typography  p={1}  variant="subtitle1" >
                                            <b>Téléphone 2 : </b> {customer.telephone_2}
                                        </Typography>
                                        <Typography p={1}  variant="subtitle1" >
                                            <b>Profession : </b> {customer.profession}
                                        </Typography>
                                        <Typography p={1}  variant="subtitle1" >
                                            <b>Observation : </b> {customer.observation}
                                        </Typography>
                                 </Box>
                                 </DivBox>
                            </Paper>
                    </Grid>

                    <Grid item lg={6} xs={12}>
                          <Paper 
                                elevation={1}
                                style={{
                                  width:'100%',
                                  justifyContent:'center',
                              }}
                           >
                                <Box  p={5} display="flex" justifyContent="space-between" >  
                                        <Button  
                                              startIcon={<AttachFile/>} 
                                              color="primary" 
                                              aria-label="upload picture"
                                              component="span"
                                              variant="contained"
                                              style={{
                                                textTransform:'none',
                                                height:45
                                            }}
                                            onClick={()=>setOpenPopup(true)}
                                            >
                                          Ajouter une pièce jointe
                                          </Button>
                                        <Typography p={2} align='center' variant="h6" component="h6" >
                                            <b>Pièces jointes</b> 
                                        </Typography>
                                </Box>
                                <Divider/>
                                <DivBox>
                                  <Box style={{overflowY:'auto'}} >
                                      { renderFile() }
                                  </Box>
                                </DivBox>
                          </Paper>
                    </Grid>
                   
                    <Grid item xs={12} >
                        <Box>
                            <Typography  variant="h6" component="h6"  >Interventions</Typography>
                        </Box>
                       <div style={{flex:4,height:'65vh',marginBottom:12, backgroundColor:'white'}} >
                                <DataGrid
                                    components={{ Toolbar: QuickSearchToolbar }}
                                    rows={customerRdv}
                                    columns={columns}
                                    getRowId={(row) => row._id}
                                    pageSize={8}
                                    componentsProps={{
                                        toolbar: {
                                          value: searchText,
                                          onChange: (event) => requestSearch(event.target.value),
                                          clearSearch: () => requestSearch(''),
                                        },
                                      }}
                                />
                        </div>
                    </Grid>

                </Grid>
                <ConfirmDialog
                  confirmDialog={confirmDialog}
                  setConfirmDialog={setConfirmDialog}
                />
            </Container>
        </MainContainer>
    )
}

export default FicheClient
