import React, { useEffect,useState,useRef} from 'react';
import { Container, Divider, Grid, Paper, Toolbar, Typography, Button } from '@mui/material';
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PictureAsPdf } from '@mui/icons-material';
import Img from '../images/36_113676.svg'
import { Box } from '@mui/system';
import ReactToPrint from 'react-to-print';
import { baseHostUrlProfil, publiqueRequest } from '../requestMethods';



export const MainContainer = styled.div`
    width: auto;
    height: 100vh;
    margin-left: 12rem;
    position: relative; 
    margin-top: 15px;
   // padding: 0 1rem;
    color:#000;
    background-color: #e8eaeb;

    @media screen  and (max-width:1198px){
       height: 110vh;
    }
   
    @media screen  and (max-width:1028px){
       height: 100vh;
    }
    @media screen  and (max-width:768px){
        margin-left: 0px;
        padding: 0 0rem;
    }
`;

export const DivBox = styled.div`
height: 100%;
    @media screen  and (max-width:768px){
        margin-left: 0px;
        padding: 0 0rem;
        height: 100vh;
    }
`;

function SingleRendezvous() {

    const location = useLocation();
    const [rdv,setRdv] = useState({});
    const rdvId = location.pathname.split("/")[2];
  
    
    const navigate = useNavigate();
    const userSignin = useSelector((state) => state.userSignin);
    const componentRef = useRef();
    const [picture,setPicture] = useState("");
    const [profil,setProfil] = useState([]);

    useEffect(() => {
        if(userSignin.userInfo == null){
          navigate('/connexion')
        }
      }, [userSignin])

      useEffect(() => {
        let isAmounted = true;
        const getProfil = async () => {
            try {
             const res = await publiqueRequest.get("/profil.php/");
              setProfil(res.data);
              setPicture(res.data[0].photo)
            } catch (err) {
              console.log(err)
            }
          }; 
          getProfil();
         return ()=>{isAmounted = false}
       }, []);

    useEffect(() => {
        let isAmounted = true;
        const getRdv = async () => {
            try {
             const res = await publiqueRequest.get("/rendez-vous.php/?_id="+rdvId);
              setRdv(res.data);
            } catch (err) {
              console.log(err)
            }
          }; 
         getRdv();
         return ()=>{isAmounted = false}
       }, []);

       const renderRdv = (text) =>{

           if(text==="planned"){
               return "À venir"
           }else if(text==="canceled"){
               return "Annule"
           }else{
             return  "Fait" 
           }
       }

    return (
        <MainContainer>
            <Container   maxWidth={false}>
                <Grid container  spacing={2}>
                    <Grid  item xs={12} >
                        <Toolbar
                            style={{
                                justifyContent:'flex-end'
                            }}
                        >
                           <ReactToPrint
                                    trigger={() => 
                                        <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<PictureAsPdf/>}
                                        style={{
                                            textTransform:'none'
                                        }}
                                        
                                      >
                                        Imprimer
                                      </Button>
                                    }
                                    content={() => componentRef.current}
                             />
                        </Toolbar>
                            <Paper 
                                style={{
                                    width:'100%',
                                    justifyContent:'center',
                                   
                                }}
                                elevation={0}
                                ref={componentRef}
                               
                            >
                               <Box  p={5} display="flex" justifyContent="space-between" >
                               <div>
                                          {
                                           profil ?
                                            profil.map((item) => (
                                              <div key={item._id} >
                                              <Typography> {item.name} </Typography>
                                              <Typography> Tel 1 : {item.contact_1} </Typography>
                                              { item.contact_2 ? <Typography> Tel 2 :  {item.contact_2} </Typography> : ""}
                                              <Typography> Adresse : {item.adresse} </Typography>
                                              </div>
                                            ))
                                           :
                                           <div>
                                              <Typography> Nom : </Typography>
                                              <Typography> Tel 1 :   </Typography>
                                              <Typography> Tel 2 : </Typography>
                                              <Typography> Adresse : </Typography>
                                           </div>
                                          }
                                        </div>
                                        <div  style={{alignItems:'center',display:"block",justifyContent:'center'}} >
                                             <Typography  align='center' variant="subtitle1" component="h6" >
                                                <b>Fiche Rendez-vous</b> 
                                            </Typography>
                                            <img 
                                                style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    borderRadius:"50%",
                                                    //filter: "invert(70%) sepia(74%) saturate(502%) hue-rotate(35deg) brightness(97%) contrast(78%)"
                                                    
                                                }} 
                                                src={picture ? baseHostUrlProfil+`${picture}` : Img} alt="logo dent" 
                                            />
                                           
                                        </div>
                                </Box>
                                  
                                 <Divider/>
                                <DivBox>
                                <Box  style={{marginLeft:50,marginTop:20}}>

                                        <Typography p={1} variant="subtitle1" >
                                            <b>Intervention : </b> {rdv.intervention}
                                        </Typography>
                                        <Typography p={1} variant="subtitle1" >
                                            <b>Autre intervention : </b> {rdv.another_intervention}
                                        </Typography>
                                        <Typography p={1} variant="subtitle1" >
                                            <b>Nom : </b> {rdv.customerName}
                                        </Typography>
                                        <Typography p={1}  variant="subtitle1" >
                                            <b>Date RDV : </b> {rdv.bookingDate}
                                        </Typography>
                                        <Typography p={1}  variant="subtitle1" >
                                            <b>Heure RDV : </b> {rdv.bookingTime}
                                        </Typography>
                                        <Typography p={1}  variant="subtitle1" >
                                            <b>Dent : </b> {rdv.teeth}
                                        </Typography>
                                        <Typography p={1}  variant="subtitle1" >
                                            <b>Doit : </b> {rdv.owied}
                                        </Typography>
                                        <Typography p={1}  variant="subtitle1" >
                                            <b>Reçu : </b> {rdv.received}
                                        </Typography>
                                        <Typography p={1}  variant="body" >
                                            <b>Note : </b> {rdv.note}
                                        </Typography>
                                        
                                        <Typography p={1}  variant="subtitle1" >
                                                <b>Statut : </b>
                                                {
                                                  
                                                    renderRdv(rdv.status)
                                                }
                                        </Typography>
                                </Box>
                                </DivBox>
                                
                            </Paper>
                    </Grid>
                </Grid>
            </Container>
        </MainContainer>
    )
}

export default SingleRendezvous
