export const SRV_LIST_REQUEST = 'SRV_LIST_REQUEST';
export const SRV_LIST_SUCCESS = 'SRV_LIST_SUCCESS';
export const SRV_LIST_FAIL = 'SRV_LIST_FAIL';

export const SRV_DETAILS_REQUEST = 'SRV_DETAILS_REQUEST';
export const SRV_DETAILS_SUCCESS = 'SRV_DETAILS_SUCCESS';
export const SRV_DETAILS_FAIL = 'SRV_DETAILS_FAIL';

export const SRV_SAVE_FAIL = 'SRV_SAVE_FAIL';
export const SRV_SAVE_SUCCESS = 'SRV_SAVE_SUCCESS';
export const SRV_SAVE_REQUEST = 'SRV_SAVE_REQUEST'

export const SRV_DELETE_FAIL = 'SRV_DELETE_FAIL';
export const SRV_DELETE_SUCCESS = 'SRV_DELETE_SUCCESS';
export const SRV_DELETE_REQUEST = 'SRV_DELETE_REQUEST'