import React, { useContext, useEffect, useState } from 'react'
import { ButtonBase, Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'
import GlobalContext from '../../context/GlobalContext'
import { translate } from '../../utils/utils'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';


function Day({day,rowIndx}) {
 
    function  getCurrentDayBackground(){

        return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY") ? "blue" : ""
    }

    function  getCurrentDayColor(){

        return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY") ? "white" : "black"
    }
    
    const [dayEvents,setDayEvents] = useState([]);
    const {
        setShowEventModal,
        setDaySelected,
        filteredEvents,
        setSelectedEvent,
    } = useContext(GlobalContext); 

  
    useEffect(() => {
        const events  = filteredEvents.filter(
            (evt) => dayjs(evt.day).format("DD-MM-YY") === day.format("DD-MM-YY")
        );
        setDayEvents(events)
        //console.log(events)
    }, [filteredEvents,day]);

    return (
    <Grid 
        container
        justifyContent='center'
       
        style={{
            border:'1px solid #ddddddb7', 
            cursor: 'pointer',
            backgroundColor:'white'
           
        }} 
        onClick={()=>{
            setDaySelected(day);
            setShowEventModal(true)
        }}
        >
            <Grid style={{ 
                alignItems:'center',
                fontSize:13, 
                justifyContent:'center',
                
            }}>
                {rowIndx === 0 &&
                  ( <Typography    ml={1}  variant="inherit" mt={1}  >{translate(day.format('ddd')).toUpperCase()} </Typography>)
                }
               
                <Typography  
                    ml={0.5}
                    style={{
                        backgroundColor:`${getCurrentDayBackground()}`,
                        color:`${getCurrentDayColor()}`,
                        borderRadius:"20%",
                        height:15,
                        width:20,
                    }} 
                    //color={`${getCurrentDayClass()}`} 
                    padding={1} 
                    marginY={1} 
                    align='center'
                    variant="caption" 
                    component="p"
                >
                    {day.format('DD')}
                </Typography>
            </Grid>
            <div
                style={{
                    //display:'block',
                    cursor: 'pointer',
                    width: '100%',
                    overflow: 'auto',
                    height:70,
                    padding: 5
                }}
              /*  onClick={()=>{
                    setDaySelected(day);
                    setShowEventModal(true)
                }}*/

                 /*<div
                   key={idx} 
                   style={{
                    backgroundColor:`${evt.color}`,
                    width: '100%',
                    height:"50%",
                    color: 'white',
                    marginBottom:5,
                    textAlign:"left",
                    fontSize:12,
                   

                }} 
                onClick={()=>{
                    setSelectedEvent(evt)
                }}
                   >
                    {evt.bookingTime}, {evt.intervention}
            </div>*/
            >
            {dayEvents.map((evt,idx)=>(
           
              <Stack 
                    sx={{ width: '100%', display:"flex", marginBottom:2 }} 
                    onClick={()=>{
                        setSelectedEvent(evt)
                    }}
                    >
                    <Alert variant="filled" icon={false}  severity="info" 
                        style={{display:"flex", backgroundColor:`${evt.color}`, fontSize:12,}}
                     >
                        <strong> {evt.bookingTime} - {evt.intervention.substring(0, 13)}</strong>
                    </Alert>
               </Stack>
                ))}
            </div>
           
        </Grid>
    )
}

export default Day
