import { 
    RDV_DELETE_FAIL,
    RDV_DELETE_REQUEST,
    RDV_DELETE_SUCCESS,
    RDV_DETAILS_FAIL,
    RDV_DETAILS_REQUEST,
    RDV_DETAILS_SUCCESS,
    RDV_LIST_FAIL,
    RDV_LIST_REQUEST,
    RDV_LIST_SUCCESS,
    RDV_SAVE_FAIL,
    RDV_SAVE_REQUEST,
    RDV_SAVE_SUCCESS

 } from "../constants/rdvConstants"

function rendezvousListReducer(state = { rendezvous: [] }, action) {
    switch (action.type) {
    case RDV_LIST_REQUEST: 
      return { loading: true,rendezvous: [] };
    case RDV_LIST_SUCCESS:
      return { loading: false, success: true, rendezvous: action.payload };
    case RDV_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
    }
  }





function rendezvousDetailsReducer(state = { rendezvous: {} }, action) {
switch (action.type) {
 case RDV_DETAILS_REQUEST:
   return { loading: true };
 case RDV_DETAILS_SUCCESS:
   return { loading: false, rendezvous: action.payload };
 case RDV_DETAILS_FAIL:
   return { loading: false, error: action.payload };
 default:
   return state;
}
}

function rendezvousSaveReducer(state = { rendezvous: {} }, action) {
    switch (action.type) {
      case RDV_SAVE_REQUEST:
        return { loading: true };
      case RDV_SAVE_SUCCESS:
        return { loading: false,  rendezvous: action.payload,success: true };
      case RDV_SAVE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
};


function rendezvousDeleteReducer(state = { rendezvous: {} }, action) {
    switch (action.type) {
      case RDV_DELETE_REQUEST:
          return { loading: true }; 
      case RDV_DELETE_SUCCESS:
          return { loading: false, rendezvous: action.payload, success: true };
      case RDV_DELETE_FAIL:
          return { loading: false, error: action.payload };
      default:
          return state;
   }
 };

export {
    rendezvousListReducer ,
    rendezvousDetailsReducer ,
    rendezvousDeleteReducer,
    rendezvousSaveReducer 
}