import React, { useContext, useState,useEffect } from 'react';
import styled from 'styled-components'
import CalendarHeader from '../agenda/components/calendar/CalendarHeader';
import { getMonth } from '../agenda/utils/utils'
import Sidebar from '../agenda/components/sidebar/Sidebar';
import Month from '../agenda/components/month/Month';
import {Grid} from '@mui/material'
import GlobalContext from '../agenda/context/GlobalContext';
import EventModal from '../agenda/components/modal/EventModal';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DraggableDialog from '../agenda/components/modal/Dialog';


export const MainContainer = styled.div`
    width: auto;
    height: 100vh;
    margin-left: 12rem;
   // margin-top: 9px;
    position: relative; 
    padding: 0 1rem;
    padding-bottom: 1rem;
    color:#000;
    background-color: #e8eaeb;
   
    @media screen  and (max-width:1198px){
       height: 100vh;
    }
    @media screen  and (max-width:768px){
        margin-left: 0px;
        padding: 0 0rem;
    }

    @media screen  and (max-width:1028px){
       height: 100vh;
    }

`;


function Agenda() {

    const [currentMonth,setCurrentMonth] = useState(getMonth());
    const {monthIndex,yearIndex,filteredEvents} = useContext(GlobalContext);
    const {showEventModal,showDialog} = useContext(GlobalContext); 
    const navigate = useNavigate();
    const userSignin = useSelector((state) => state.userSignin);

    useEffect(() => {
        if(userSignin?.userInfo == null){
          navigate('/connexion')
        }
      }, [userSignin])

    useEffect(() => {
        setCurrentMonth(getMonth(monthIndex,yearIndex))
    }, [monthIndex,yearIndex]); 

    const renderSidebar = () => {
        if(filteredEvents.length > 0 &&  filteredEvents.filter((item) => item.status === "planned" !== "")){
            return(
             <Grid item lg={2.5}>
                  <Sidebar/> 
             </Grid>
            )
        }
     }

    return (
        <MainContainer>
          
                        <Grid  container >
                            <Grid item xs={12} >
                                   <CalendarHeader/>
                            </Grid>   
                        </Grid>       
                       <Grid 
                            container 
                            alignItems="center"
                            display="flex"
                            spacing={2}
                            justifyContent='center'
                        >
                             {showEventModal && <EventModal/>}
                             <Grid 
                                item 
                                lg={2.5}
                               
                            >
                                 <Sidebar/> 
                             </Grid>
                       
                             <Grid item container lg={9.5} >
                                    <Month  month={currentMonth} />  
                            </Grid>
                            
                      
                    </Grid>
                    {showDialog && <DraggableDialog/>}
           
        </MainContainer>
    )
}

export default Agenda
 