import { 
    SCHEDULE_SEND_FAIL,
    SCHEDULE_SEND_REQUEST,
    SCHEDULE_SEND_SUCCESS ,
    SCHEDULE_RAPPEL_FAIL,
    SCHEDULE_RAPPEL_REQUEST,
    SCHEDULE_RAPPEL_SUCCESS 
} from "../constants/scheduleConstants";


function scheduleReducer(state = { rendezvous: {} }, action) {
    switch (action.type) {
     case SCHEDULE_SEND_REQUEST:
       return { loading: true };
     case SCHEDULE_SEND_SUCCESS:
       return { loading: false, rendezvous: action.payload, success: true };
     case SCHEDULE_SEND_FAIL:
       return { loading: false, error: action.payload };
     default:
       return state;
     }
    };



function scheduleRappelReducer(state = { rendezvous: {} }, action) {
  switch (action.type) {
   case SCHEDULE_RAPPEL_REQUEST:
     return { loading: true };
   case SCHEDULE_RAPPEL_SUCCESS:
     return { loading: false, rendezvous: action.payload,success: true };
   case SCHEDULE_RAPPEL_FAIL:
     return { loading: false, error: action.payload };
   default:
     return state;
   }
  };

    export {
        scheduleReducer,
        scheduleRappelReducer
    }