import React,{useEffect} from 'react'
import {
     Button,
     Grid,
     Box, 
     Container, 
     TextField,
     Select, 
     MenuItem, 
     InputLabel, 
     FormControl,
     InputAdornment,
    } from '@mui/material'
import useForm from './useForm'


const initialFieldValues = {
   assurance:0

}




function AssuranceForm({recordForEdit,addOrEdit}) {

   
    const validate = () => {
        let temp = { ...errors }
        temp.assurance = values.assurance ? "" : "L'assurance est requise"
       
       
        setErrors({
            ...temp
        })
        return Object.values(temp).every(x => x === "")
    }

    var {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleInputFileChange,
        resetForm
    } = useForm(initialFieldValues)

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values,resetForm)
        } 
       
    }

    useEffect(() => {
        if (recordForEdit !== null){
            setValues({
                ...recordForEdit
            })
            setErrors({})
        } 
    }, [recordForEdit])

  

    return (
      
          
             <form 
                noValidate 
                autoComplete="off" 
                onSubmit={handleSubmit}
             >
             
                       <TextField 
                          style={{marginBottom:10}} 
                          id="assurance"
                          label="Assurence %"
                          type="number"
                          name="assurance"
                          variant="standard"
                          fullWidth
                          value={values.assurance}
                          inputProps={{
                              startAdornment:
                                <InputAdornment position="start">
                                   Dent
                                </InputAdornment> 
                          }}
                          onChange={handleInputChange}
                            InputLabelProps={{
                            shrink: true,
                         }}
                      />
             
               
                   <Box 
                        p={2} 
                        display="flex" 
                        justifyContent="center" 
                    >
                         <Button
                            style={{
                                backgroundColor:'#7faf02',
                                textTransform:'none',
                                color:"#fff",
                                width:200

                            }}
                            variant="contained"
                            type="submit"
                            size="large"
                            >
                               Editer
                         </Button>            
                    </Box>
                      
                    
                                 
                                           
        </form>

    )
}

export default AssuranceForm