
import { 
    RDV_DELETE_FAIL,
    RDV_DELETE_REQUEST,
    RDV_DELETE_SUCCESS,
    RDV_DETAILS_FAIL,
    RDV_DETAILS_SUCCESS,
    RDV_LIST_FAIL, 
    RDV_LIST_REQUEST,
    RDV_LIST_SUCCESS,
    RDV_SAVE_FAIL,
    RDV_SAVE_SUCCESS

 } from "../constants/rdvConstants"
import { publiqueRequest, userRequest } from "../requestMethods"


const listRdv = () => async(dispatch) => {
  
    try {

        dispatch({type: RDV_LIST_REQUEST})
        const {data}  = await userRequest.get('/rendez-vous.php/')
        dispatch({type: RDV_LIST_SUCCESS,payload: data})
         
    } catch (error) {
        dispatch({type: RDV_LIST_FAIL,payload: error.message})
    }
       
}

const deleteRdv = (rdvId) => async (dispatch) => {
 
  try {
    dispatch({ type: RDV_DELETE_REQUEST, payload: rdvId });
    const { data } = await userRequest.delete('rendez-vous.php/?_id=' + rdvId);
    dispatch({ type: RDV_DELETE_SUCCESS, payload: data ,success:true});
  } catch (error) { 
    dispatch({ type: RDV_DELETE_FAIL, payload: error.message });
  }
};
 


  
const saveRdv = (rdv) => async (dispatch) => {  
  try {
    dispatch({ type: RDV_SAVE_SUCCESS, payload: rdv });
    if(!rdv._id){
      const { data } = await userRequest.post('/rendez-vous.php/', rdv);   
      dispatch({ type: RDV_SAVE_SUCCESS, payload: data, success:true}); 

    }else{
      const { data } = await userRequest.put('/rendez-vous.php/',rdv);    
      dispatch({ type: RDV_SAVE_SUCCESS, payload: data,success:true});
    }
  } catch (error) {
    dispatch({ type: RDV_SAVE_FAIL, payload: error.message });
  }
};  



const detailsRdv = (rdvId) => async (dispatch) => {
    try {
      dispatch({ type: RDV_DETAILS_SUCCESS, payload: rdvId });
      const { data } = await userRequest.get('/rendez-vous.php/?_id=' + rdvId);
      dispatch({ type: RDV_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: RDV_DETAILS_FAIL, payload: error.message });
    }
  };



export {
    listRdv,
    detailsRdv,
    saveRdv,
    deleteRdv
}