import React from 'react';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { useContainedCardHeaderStyles } from '@mui-treasury/styles/cardHeader/contained';
import { useSoftRiseShadowStyles } from '@mui-treasury/styles/shadow/softRise';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import {Link} from 'react-router-dom'
import { IconButton } from '@mui/material';
import { Visibility } from '@mui/icons-material';


const useStyles = makeStyles(({ spacing }) => ({
  card: {
    marginTop: 40,
    borderRadius: spacing(0.5),
    transition: '0.3s',
    width: '90%',
    overflow: 'initial',
    background: '#ffffff',
    padding:20
  },
  content: {
    paddingTop: 0,
    textAlign: 'left',
    overflowX: 'auto',
    '& table': {
      marginBottom: 0,
    }
  },
}));


;

export const TableCard = React.memo(function ElevatedHeaderCard({rdv}) {
  const classes = useStyles();
  const cardHeaderStyles = useContainedCardHeaderStyles();
  const cardShadowStyles = useSoftRiseShadowStyles({ inactive: true });
  const cardHeaderShadowStyles = useFadedShadowStyles();

  function renderRdv(){
       
    if(rdv.length > 0){
      return(
        rdv.slice(0,5).map((item)=>(
               <TableRow key={item._id}
                sx={{'&:last:child td,&:last-child th': {border:0}}}
               >
                        <TableCell >{item.intervention}</TableCell>
                        <TableCell >{item.customerName}</TableCell>
                        <TableCell >{item.bookingDate}</TableCell>
                        <TableCell >{item.bookingTime}</TableCell>
                        <TableCell>
                        <Link to={'/rendez-vous/'+item._id} >
                            <IconButton style={{color:'#00aeea'}} >
                                    <Visibility fontSize="small"  />
                            </IconButton>
                        </Link>
                        </TableCell>
                       
                </TableRow>
           ))
        )   
    }

 } 


  return (
    <Card style={{borderRadius:20}} className={cx(classes.card, cardShadowStyles.root)}>
      <CardHeader
        className={cardHeaderShadowStyles.root}
        classes={cardHeaderStyles}
        style={{backgroundColor:"#7faf02"}}
        title={'Rendez-vous'}
        subheader={'Les 5 derniers'}
      />
      <CardContent   className={classes.content}>
        <Table  >
          <TableHead>
            <TableRow>
              <TableCell>Intervention</TableCell>
              <TableCell >Patient</TableCell>
              <TableCell >Date</TableCell>
              <TableCell >Heure</TableCell>
              <TableCell ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRdv()}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
});

export default TableCard