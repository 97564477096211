import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { 
    rendezvousDeleteReducer, 
    rendezvousDetailsReducer, 
    rendezvousListReducer, 
    rendezvousSaveReducer 
} from '../reducers/rdvReducer';
import { 
    serviceDeleteReducer, 
    serviceDetailsReducer, 
    serviceListReducer, 
    serviceSaveReducer 
} from '../reducers/srvReducer';
import { 
    categoryDeleteReducer,
    categorySaveReducer ,
    categoryListReducer 
} from '../reducers/catReducer';
import { 
    userDeleteReducer,
    userRegisterReducer, 
    userSaveReducer, 
    userSigninReducer 
} from '../reducers/userReducer';
import { 
    customerDeleteReducer, 
    customerListReducer, 
    customerSaveReducer 
} from '../reducers/customerReducer';
import { 
    scheduleRappelReducer, 
    scheduleReducer 
} from '../reducers/scheduleReducer';
import { profilDeleteReducer, profilListReducer, profilSaveReducer } from '../reducers/profilReducer';
import { 
    cartReducer, 
    factureDeleteReducer, 
    factureListReducer, 
    factureSaveReducer 
} from '../reducers/factureReducer';



const rootReducer = combineReducers({
        categoryList:categoryListReducer,
        categorySave:categorySaveReducer,
        categoryDelete:categoryDeleteReducer,
        rendezvousList:rendezvousListReducer,
        rendezvousDetails:rendezvousDetailsReducer,
        rendezvousDelete:rendezvousDeleteReducer,
        rendezvousSave:rendezvousSaveReducer,
        serviceList:serviceListReducer,
        serviceDelete:serviceDeleteReducer,
        serviceDetails:serviceDetailsReducer,
        serviceSave:serviceSaveReducer,
        customerList:customerListReducer,
        customerSave:customerSaveReducer,
        customerDelete:customerDeleteReducer,
        userSignin:userSigninReducer,
        userRegister:userRegisterReducer,
        userSave:userSaveReducer,
        userDelete:userDeleteReducer,
        scheduler : scheduleReducer,
        scheduleRappel:scheduleRappelReducer,
        profilList:profilListReducer,
        profilSave:profilSaveReducer,
        profilDelete:profilDeleteReducer,
        cart:cartReducer,
        factureList:factureListReducer,
        factureSave:factureSaveReducer,
        factureDelete:factureDeleteReducer
       
});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['categoryList','categorySave','categoryDelete','rendezvousList','rendezvousDetails','cart',
    'rendezvousDelete','serviceList','serviceDelete','serviceDetails','customerList','customerSave','customerDelete',
    'serviceSave','userRegister','userSave','userDelete','scheduler','scheduleRappel','profilList','profilSave','profilDelete'] // navigation will not be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(persistedReducer, composeEnhancer(applyMiddleware(thunk)))
export const persistor =  persistStore(store) 

