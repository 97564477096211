import dayjs from "dayjs";


export function getMonth(month = dayjs().month(),year=dayjs().year()) {
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    month = Math.floor(month)  
    const firstDayOfTheMonth = dayjs(new Date(year, month, 0)).day();
    let currentMonthCount = 0 - firstDayOfTheMonth;
    const daysMatrix = new Array(5).fill([]).map(() => {
        return new Array(7).fill(null).map(() => {
            currentMonthCount++
            return dayjs(new Date(year, month, currentMonthCount))
        })
    })
    return daysMatrix
}

export function translate(day) {
    let days = ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"];
    let jours = ["Lun","Mar","Mer","Jeu","Ven","Sam","Dim"];
    let newDay = "";

    for(let i = 0; i < days.length; i++){
        if(day === days[i]){
           newDay = jours[i]
        }
    }
    return newDay
}

export function translateLongDay(day) {
    let days = ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"];
    let jours = ["Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi","Dimanche"];
    let newDay = "";

    for(let i = 0; i < days.length; i++){
        if(day === days[i]){
           newDay = jours[i]
        }
    }
    return newDay
}
export function translateSmallCalendar(day) {
    let days = ["Mo","Tu","We","Th","Fr","Sa","Su"];
    let jours = ["L","M","M","J","V","S","D"];
    let newDay = "";

    for(let i = 0; i < days.length; i++){
        if(day === days[i]){
           newDay = jours[i]
        }
    }
    return newDay
}


export function translateMonth(month) {
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let mois = [ "Janvier" , "Février" , "Mars" , "Avril" , "Mai" , "Juin" , "Juillet" , "Août" , "Septembre" , "Octobre" , "Novembre" , "Décembre" ];
    let newMonth = "";

    for(let i = 0; i < months.length; i++){
        if(month === months[i]){
            newMonth = mois[i]
        }
    }
    return newMonth
}