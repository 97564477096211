import React, { useEffect,useState } from 'react';
import { Container, Grid, Toolbar, Button, IconButton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from '@mui/x-data-grid'
import {CancelOutlined, EditOutlined, PointOfSale, ShoppingCartCheckout, Visibility} from '@mui/icons-material'
import styled from 'styled-components'
import ConfirmDialog from '../components/ConfirmDialog';
import { Box, height } from '@mui/system';
import Popup from '../components/Popup';
import { toast } from 'react-toastify';
import {Link, useNavigate} from 'react-router-dom'
import { publiqueRequest } from '../requestMethods';
import { deleteCustomer, listCustomers, saveCustomer } from '../actions/customerActions';
import CustomerForm from '../components/Forms/CustomerForm';
import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';




function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display:"flex",
        justifyContent:"space-between"
      }}
    >
       <GridToolbarContainer className={gridClasses.toolbarContainer}>
           <GridToolbarExport color="success" />
        </GridToolbarContainer>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: 'auto',
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          '& .MuiSvgIcon-root': {
            mr: 0.5,
          },
          '& .MuiInput-underline:before': {
            borderBottom: 1,
            borderColor: 'divider',
          },
        }}
      />
    </Box>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  
};




export const MainContainer = styled.div`
    width: auto;
    height: 100vh;
    margin-left: 12rem;
    position: relative; 
   // padding: 0 1rem;
    color:#000;
    background-color: #e8eaeb;
   
    @media screen  and (max-width:1028px){
       height: 100%;
    }

    @media screen  and (max-width:768px){
        margin-left: 0px;
        padding: 0 0rem;
    }
`;

 function FicheClients(){

  
    const dispatch = useDispatch();
    const [ficheClients,setFicheClients] = useState([]);
    const [searchText, setSearchText] = useState('');

    const customerList = useSelector(state => state.customerList);

    const customerSave = useSelector(state => state.customerSave);
    const {customer:messageSave} = customerSave;

    const customerDelete = useSelector(state => state.customerDelete);
    const {customer:messageDelete} = customerDelete;


    const [confirmDialog, setConfirmDialog] = useState({ open: false});
    const [openPopup, setOpenPopup] = useState(false);
    const [recordForEdit, setRecordForEdit] = useState(null);

    const navigate = useNavigate();
    const userSignin = useSelector((state) => state.userSignin);
    
   
    
   useEffect(() => {
      if(userSignin.userInfo == null){
        navigate('/connexion')
      }
    
    }, [userSignin]) 


        const requestSearch = (searchValue) => {
          if(searchValue!==""){
            setSearchText(searchValue);
            const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
            const filteredRows = ficheClients.filter((row) => {
              return Object.keys(row).some((field) => {
                return searchRegex.test(row[field].toString());
                
              });  
            
            });
          
            setFicheClients(filteredRows);
          }else{
            setSearchText("")
            getCustomers();
          }
         
          
        }; 

        const getCustomers = async () => {
          try {
          const res = await publiqueRequest.get("/customers.php/");
               setFicheClients(res.data);
          } catch (err) {
            console.log(err)
          }
        }; 

        useEffect(() => {
              getCustomers()
        }, [messageSave,messageDelete])

       const openInPopup = item => {
        setRecordForEdit(item)
        setOpenPopup(true)
        };


       const onDelete = (id) => { 
        setConfirmDialog({
            ...confirmDialog,
            open: false
        });
       
        ;
      if(dispatch(deleteCustomer(id))){
          toast("Patient supprimé avec succès!!!", {
            type: "info",
            theme:"dark"
          });
      }else{
        toast("Erreur lors de la suppression!!!", {
          type: "error",
          theme:"dark"
        });
      }  
       
       };

      const addOrEdit = async (obj,resetForm) => {
        try {
            
            if (obj._id === "") {
                 
                 if(dispatch(saveCustomer(obj))){
                   toast("Fiche patient ajoutée  avec succès!!!", {
                       type: "success",
                       theme:"dark"
                   });
              
                  }
              }else{
            
                if(dispatch(saveCustomer(obj))){
                 toast("Fiche patient mise à jour avec succès!!!", {
                     type: "success",
                     theme:"dark"
                 });
            
                }
             }
            resetForm()
            setRecordForEdit(null)
            setOpenPopup(false)
            getCustomers();
        } catch (error) {
            console.error(error);
          }
       }
 

       const columns = [
        {
          field: "action",
          headerName: "Action",
          width: 150,
          renderCell: (params) => {
            
            return (
              <>
                
                <IconButton 
                  style={{color:'blue'}}
                  onClick={() => {openInPopup(params.row)}}
                  
                  >
                        <EditOutlined fontSize="small"  />
                </IconButton>
             
                {
                 userSignin.userInfo.rules === "true" && (
                <IconButton 
                  style={{color:'red'}} 
                  onClick={() => {
                    setConfirmDialog({
                        open: true,
                        onConfirm: () => {onDelete(params.row._id) }
                      })
                    }}
                  >
                        <CancelOutlined fontSize="small"  />
                </IconButton>
                 )}
                <Link to={'/fiche-client/'+params.row._id}>
                      <IconButton style={{color:'blue'}} >
                              <Visibility fontSize="small"  />
                      </IconButton>
                </Link>
              
              </>
            );
          },
        },
        {
          field: "facturer",
          headerName: "Facturer",
          width: 150,
          renderCell: (params) => {
            
            return (
              <>
                <Link 
                      to={'/facturation/'+params.row._id} 
                      style={{
                        textDecoration:"none",
                        
                        
                      }} 

                  > 
                  <Button 
                    variant="contained" 
                    style={{
                        backgroundColor:"#7faf02",
                        textTransform:"none"
                    }}
                  >
                      Facturer
                  </Button>
                   
                </Link>
              </>
            );
          },
        },
        {field: "firstname", headerName: "Nom", width: 200 },
        {field: "lastname",headerName: "Prénom",width: 200},
        {field: "genre",headerName: "Genre",width:100},
        {field: "date_naissance",headerName: "Date de naissance",width: 200},
        {field: "adresse",headerName: "Adresse",width: 200},
        {field: "telephone",headerName: "Téléphone",width: 100},
        {field: "profession",headerName: "Profession",width: 200},
        {field: "observation",headerName: "Observation",width: 200},
       
      ];

    return (
        <>
        <MainContainer>
            <Container   maxWidth={false}>
                <Grid container  >
                      <Grid  item  xs={12}>
                             <Toolbar>
                                
                                <Box display="flex" justifyContent="space-between"  sx={{flexGrow:1,display:{xs:'flex',md:'flex'}}} >
                                <Typography
                                  variant="h6"
                                  noWrap
                                  component="div"
                                  sx={{mr:2,display:{xs:'none',md:'flex'}}}
                                 >
                                   Fiches Patients
                                  </Typography>
                                  <Button variant="outlined" color="success"
                                    onClick={() => {setOpenPopup(true); setRecordForEdit(null); }}
                                  >
                                    Ajouter
                                  </Button>
                                </Box>
                            </Toolbar>
                      </Grid>
                      <Grid  item xs={12} >
                    
                        <div style={{flex:4,height:'75vh', backgroundColor:'white'}} >
                                <DataGrid
                                    components={{ Toolbar: QuickSearchToolbar }}
                                    rows={ficheClients}
                                    columns={columns}
                                    getRowId={(row) => row._id}
                                    pageSize={10}
                                    componentsProps={{
                                      toolbar: {
                                        value: searchText,
                                        onChange: (event) => requestSearch(event.target.value),
                                        clearSearch: () => requestSearch(''),
                                      },
                                    }}
                                />
                        </div>
                      </Grid>

                </Grid>
                <Popup
                   title="Formulaire des patients"
                   openPopup={openPopup}
                   setOpenPopup={setOpenPopup}
                 > 
                   <CustomerForm
                       recordForEdit={recordForEdit}
                       addOrEdit={addOrEdit}
                   />
                </Popup>
                <ConfirmDialog
                  confirmDialog={confirmDialog}
                  setConfirmDialog={setConfirmDialog}
                />
            </Container>

        </MainContainer>
        </>
       
       
    );
}


export default FicheClients;