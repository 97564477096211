import React from 'react';
import './Footer.css';


function Footer(props) {
  return (
    <div className='footer-container'>

      <section className='social-media'>
        <div className='social-media-wrap'>
          
          <small className='website-rights'>2022 © FONQ MEDIPLUS by <a target='_blank' style={{color:'#7faf02'}} href="https://fonqsolutions.com/">FONQ SOLUTIONS</a> </small>
         
        </div>
      </section>
    </div>
  );
}

export default Footer;