import {
     ADD_TO_CART,
     REMOVE_FROM_CART,
     ADD_QUANTITY,
     SUB_QUANTITY,
     EDIT_CART ,
     EDIT_ITEM,
     EDIT_COEF,
     EDIT_SOINS,
     EDIT_TEETH,
     CLEAR_CART,
     EDIT_ASSURANCE,
     FACTURE_DELETE_FAIL,
    FACTURE_DELETE_REQUEST,
    FACTURE_DELETE_SUCCESS,
    FACTURE_DETAILS_FAIL,
    FACTURE_DETAILS_REQUEST,
    FACTURE_DETAILS_SUCCESS,
    FACTURE_LIST_FAIL,
    FACTURE_LIST_REQUEST, 
    FACTURE_LIST_SUCCESS, 
    FACTURE_SAVE_FAIL, 
    FACTURE_SAVE_REQUEST,
    FACTURE_SAVE_SUCCESS
} from "../constants/factureConstants";


const initialState = {
     addedItems: [],
     total: 0,
     patient: 0,
     assurance : 0,
     notification:"",  
 }
 


 function factureListReducer(state = { factures: [] }, action) {
    switch (action.type) {
    case FACTURE_LIST_REQUEST: 
      return { loading: true,factures: [] };
    case FACTURE_LIST_SUCCESS:
      return { loading: false, success: true, factures: action.payload };
    case FACTURE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
    }
}


function factureDeleteReducer(state = { facture: {} }, action) {
    switch (action.type) {
       case FACTURE_DELETE_REQUEST:
           return { loading: true }; 
       case FACTURE_DELETE_SUCCESS:
           return { loading: false, facture: action.payload, success: true };
       case FACTURE_DELETE_FAIL:
           return { loading: false, error: action.payload };
       default:
           return state;
     }
   }
   
   function factureSaveReducer(state = { facture: {} }, action) {
     switch (action.type) {
      case FACTURE_SAVE_REQUEST:
        return { loading: true };
      case FACTURE_SAVE_SUCCESS:
        return { loading: false, facture: action.payload, success: true };
      case FACTURE_SAVE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
      }
     }

 
 const cartReducer = (state = initialState, action) => {
    
     switch (action.type) {
         case ADD_TO_CART:
             let addedItem = action.payload.details;
             let existed = state.addedItems.find(item => action.payload.id === item._id)
             if(existed){
                 
                    let error = "nok"
                    return{
                        ...state,
                        total:state.total,
                        notification:error
                    }
                
             }else{
                 let newTotal = state.total + addedItem.price;
                 let addPatient = (newTotal * (100 - state.assurance))/100;
                 return {
                     ...state,
                     addedItems: [...state.addedItems, addedItem],
                     total: newTotal,
                     patient: addPatient,
                     notification:"success"
                 }
             }
           case REMOVE_FROM_CART:
             let itemToRemove = state.addedItems.find(item => action.payload.id === item._id);
             let newItems = state.addedItems.filter(item => action.payload.id !== item._id);
             let newTotal = state.total - (itemToRemove.price);
             let removePatient = (newTotal * (100 - state.assurance))/100;
            
             return{
                 ...state,
                 addedItems: newItems,
                 total: newTotal,
                 patient: removePatient
             }
             case CLEAR_CART:
                return{
                    ...state,
                    addedItems:[],
                    total: 0,
                    patient: 0,
                    assurance:0
                }
             case EDIT_ASSURANCE:
                let newAssurance = action.payload.assurance;
                let newPatient = (state.total * (100 - Number(action.payload.assurance)))/100;
               
                return{
                    ...state,
                    patient: newPatient,
                    assurance: newAssurance
                   
                }
        case EDIT_ITEM:
             let itemToUpdate = action.payload.details;
             let lastPrice = Number(itemToUpdate.price);
             let currentPrice =  Number(state.addedItems.find(item => item._id === action.payload.id).price);
             state.addedItems.find(item => item._id === action.payload.id).price = action.payload.data.price;
             state.addedItems.find(item => item._id === action.payload.id).intervention = action.payload.data.intervention;
             state.addedItems.find(item => item._id === action.payload.id).coefficient = action.payload.data.coefficient;
             state.addedItems.find(item => item._id === action.payload.id).teeth = action.payload.data.teeth;
             let newTotalByPrice = state.total - currentPrice + Number(action.payload.data.price);
             let editPatient = (newTotalByPrice * (100 - state.assurance))/100;
          
             return{
                ...state,
                total: newTotalByPrice,
                patient:  editPatient
             }

        case EDIT_CART:
                let newNewTotal = 0;
                let addedItemUpdate = state.addedItems.find(item => item._id === action.payload.id);
                if(addedItemUpdate.teeth !== action.payload.details.teeth){
                     addedItemUpdate.teeth = action.payload.details.teeth
                }else if(addedItemUpdate.coefficient !== action.payload.details.coefficient){
                     addedItemUpdate.coefficient = action.payload.details.coefficient
                }else if(addedItemUpdate.price !== action.payload.details.price){
                     newNewTotal = state.total - addedItemUpdate.price + action.payload.details.price
                     addedItemUpdate.price = action.payload.details.price;
                }else if(addedItemUpdate.intervention !== action.payload.details.intervention){
                      addedItemUpdate.intervention = action.payload.details.intervention
                }
                
                  return{
                    ...state,
                    total:newNewTotal
                }
               
         default:
             return state    
     }
 }


 
  
 export  {
    factureDeleteReducer ,
    factureSaveReducer ,
    factureListReducer,
    cartReducer
}