import { 
    CAT_DELETE_FAIL,
    CAT_DELETE_REQUEST,
    CAT_DELETE_SUCCESS,
    CAT_DETAILS_FAIL,
    CAT_DETAILS_REQUEST,
    CAT_DETAILS_SUCCESS,
    CAT_LIST_FAIL,
    CAT_LIST_REQUEST,
    CAT_LIST_SUCCESS,
    CAT_SAVE_FAIL,
    CAT_SAVE_REQUEST,
    CAT_SAVE_SUCCESS
 } from "../constants/catConstants"



 function categoryListReducer(state = { categories: [] }, action) {
  switch (action.type) {
  case CAT_LIST_REQUEST: 
    return { loading: true,categories: [] };
  case CAT_LIST_SUCCESS:
    return { loading: false, success: true, categories: action.payload };
  case CAT_LIST_FAIL:
    return { loading: false, error: action.payload };
  default:
    return state;
  }
}


function categoryDeleteReducer(state = { category: {} }, action) {
 switch (action.type) {
    case CAT_DELETE_REQUEST:
        return { loading: true }; 
    case CAT_DELETE_SUCCESS:
        return { loading: false, category: action.payload, success: true };
    case CAT_DELETE_FAIL:
        return { loading: false, error: action.payload };
    default:
        return state;
}
}


function categorySaveReducer(state = { category: {} }, action) {
switch (action.type) {
 case CAT_SAVE_REQUEST:
   return { loading: true };
 case CAT_SAVE_SUCCESS:
   return { loading: false, success: true, category: action.payload };
 case CAT_SAVE_FAIL:
   return { loading: false, error: action.payload };
 default:
   return state;
 }
}

export {
    categorySaveReducer,
    categoryDeleteReducer,
    categoryListReducer
}