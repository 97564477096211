import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { publiqueRequest } from '../../requestMethods';



class ApexChart extends React.Component {

   
    constructor(props) {
      super(props);
      this.state = {
      
        series: [{
          name: 'Total',
          data:[]  
        }],
        options: { 
          chart: {
            height: 350,
            type: 'bar',
          },
          plotOptions: {
            bar: {
              borderRadius: 5,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "%";
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          
          xaxis: {
            categories: [],
            position: 'top',
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                }
              }
            },
            tooltip: {
              enabled: true,
            }
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val + "%";
              }
            }
          
          },
          title: {
            text: 'Total de rendez-vous par mois',
            floating: true,
            offsetY: 330,
            align: 'center',
            style: {
              color: '#444'
            }
          }
        },
      
      
      };
      
    }
 
    componentDidMount(){
      const getStat = async () => {
        try {
          const months= [];
          const total= [];
          const monthsTab = [
              "Jan",
              "Fev",
              "Mar",
              "Avr",
              "Mai",
              "Jui",
              "Juil",
              "Aut",
              "Sep",
              "Oct",  
              "Nov",
              "Dec",
            ]
      
          await publiqueRequest.get("/rendezvous-year.php/").then(
            (Response)=>{
              for(const obj of Response.data){
                months.push(monthsTab[obj.mois - 1]);
                total.push(obj.total)
              }
             
              this.setState({
                series: [{
                  name: 'Total',
                  data:total
                }],
                options:{
                  chart: {
                    height: 250,
                    type: 'bar',
                    animations: {
                      speed: 500
                    },
                    
                  },
                  plotOptions: {
                    bar: {
                      borderRadius: 5,
                      dataLabels: {
                        position: 'top', // top, center, bottom
                      },
                      bar: {
                        distributed: true
                      }
                    }
                  },
                  dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                      return val + "";
                    },
                    offsetY: -20,
                    style: {
                      fontSize: '12px',
                      colors: ["#304758"]
                    }
                  },
                  
                  xaxis: {
                    categories:months,
                    position: 'top',
                    axisBorder: {
                      show: true
                    },
                    axisTicks: {
                      show: false
                    },
                    crosshairs: {
                      fill: {
                        type: 'gradient',
                       
                        gradient: {
                          colorFrom: '#D8E3F0',
                          colorTo: '#BED1E6',
                          stops: [0, 100],
                          opacityFrom: 0.4,
                          opacityTo: 0.5,
                        }
                      }
                    },
                    tooltip: {
                      enabled: true,
                    }
                  },
                  yaxis: {
                    axisBorder: {
                      show: false
                    },
                    axisTicks: {
                      show: false,
                    },
                    labels: {
                      show: false,
                      formatter: function (val) {
                        return val + "%";
                      }
                    }
                  
                  },
                  fill:{
                    colors:['#00aeea'],
                  },
                  title: {
                    text: 'Total de rendez-vous par mois',
                    floating: true,
                    offsetY: 330,
                    align: 'center',
                    style: {
                      color: '#444'
                    }
                  }
                 }
              })
             
            
            //  console.log(series)
           //   console.log(options)
             // console.log(total)
            }
          );
          
        } catch (err) {
          console.log(err)
        }
          }
          getStat();
     }

    render() {
       
      return (
        

        <div id="chart">
            <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
        </div>
      )
    }
}

export default ApexChart ;
 
