import { Grid } from '@mui/material'
import React, { Fragment } from 'react'
import Day from '../days/Day'
 
function Month({month}) {
  
    return (
        <>
            {
                month.map((row,i) =>(
                
                    <Fragment key={i}>
                        {
                            row.map((day,idx)=>(
                                <Grid item lg={1.7} md={1.7} sm={1.7} xs={1.7}   key={idx}>
                                        <Day day={day} rowIndx={i}  />
                                </Grid>
                                
                            ))
                        }
                    </Fragment>
                ))
            }
        </>
    )
}

export default Month
