import { 
  ADD_TO_CART,
  REMOVE_FROM_CART,
  SUB_QUANTITY,
  ADD_QUANTITY,
  EDIT_ITEM,
  EDIT_ASSURANCE,
  FACTURE_DELETE_FAIL,
  FACTURE_DELETE_REQUEST,
  FACTURE_DELETE_SUCCESS,
  FACTURE_DETAILS_FAIL,
  FACTURE_DETAILS_REQUEST,
  FACTURE_DETAILS_SUCCESS,
  FACTURE_LIST_FAIL,
  FACTURE_LIST_REQUEST, 
  FACTURE_LIST_SUCCESS, 
  FACTURE_SAVE_FAIL, 
  FACTURE_SAVE_REQUEST,
  FACTURE_SAVE_SUCCESS,
  CLEAR_CART
} from "../constants/factureConstants";
import { userRequest } from "../requestMethods";




const deleteFacture = (factureId) => async (dispatch) => {
 
  try {
    dispatch({ type: FACTURE_DELETE_REQUEST, payload: factureId });
    const { data } = await userRequest.delete('/factures.php/?_id=' + factureId);
    dispatch({ type: FACTURE_DELETE_SUCCESS, payload: data , success:true});
  } catch (error) { 
    dispatch({ type: FACTURE_DELETE_FAIL, payload: error.message });
  }
}; 

const saveFacture = (facture) => async (dispatch) => {  
 try {
    dispatch({ type: FACTURE_SAVE_SUCCESS, payload: facture });
      const { data } = await userRequest.post('/factures.php/', facture);   
      dispatch({ type: FACTURE_SAVE_SUCCESS, payload: data,success:true}); 
   } catch (error) {
    dispatch({ type: FACTURE_SAVE_FAIL, payload: error.message });
  }
};



const listFacture = () => async(dispatch) => {
  
  try {

      dispatch({type: FACTURE_LIST_REQUEST})
      const {data}  = await userRequest.get('/factures.php/')
      dispatch({type: FACTURE_LIST_SUCCESS,payload: data})
       
  } catch (error) {
      dispatch({type: FACTURE_LIST_FAIL,payload: error.message})
  }
     
}

export const addToCart = (id) => async (dispacth) => { 
    console.log(id);
    try {
      const res = await userRequest.get("rendez-vous.php/?_id="+id);
            dispacth({
                type: ADD_TO_CART,
                payload:{
                    details:res.data,
                    id
                } 
            });
     } catch (error) {
        console.log(error)
    }

};



export const editItem = (id,data) => async (dispacth) =>  {
  try {
          const res = await userRequest.get("rendez-vous.php/?_id="+id);
          dispacth({
              type: EDIT_ITEM,
              payload:{
                  details:res.data,
                  id,
                  data:data
              } 
          })
   } catch (error) {
      console.log(error)
  }
};

export const editAssurance = (assurance) => async (dispacth) =>  {
  try {
          dispacth({
              type: EDIT_ASSURANCE,
              payload:{
                assurance
              } 
          })
   } catch (error) {
      console.log(error)
  }
};


export const removeItem = (id) => async (dispacth) =>  {
  try {
          dispacth({
              type: REMOVE_FROM_CART,
              payload:{
                  id,
              } 
          })
   } catch (error) {
      console.log(error)
  }
};

export const clearCart = () => async (dispacth) =>  {
  try {
          dispacth({
              type: CLEAR_CART,
          })
   } catch (error) {
      console.log(error)
  }
};

export const substractQuantity = (id) => async (dispacth) =>  {

  dispacth({
    type: SUB_QUANTITY,
    payload:{
      id
    }
  })
 
};

export const addQuantity = (id) => async (dispacth) => {
  dispacth({
    type: ADD_QUANTITY,
    payload:{
      id
    }
 
  })
 
};

export {
  listFacture,
  saveFacture,
  deleteFacture,
}