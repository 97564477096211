import { 
    PROFIL_DELETE_FAIL,
    PROFIL_DELETE_REQUEST,
    PROFIL_DELETE_SUCCESS,
    PROFIL_DETAILS_FAIL,
    PROFIL_DETAILS_SUCCESS,
    PROFIL_DETAILS_REQUEST,
    PROFIL_LIST_FAIL,
    PROFIL_LIST_REQUEST,
    PROFIL_LIST_SUCCESS,
    PROFIL_SAVE_FAIL,
    PROFIL_SAVE_SUCCESS,
    PROFIL_SAVE_REQUEST
 } from "../constants/profilConstants"

function profilListReducer(state = { profil: [] }, action) {
    switch (action.type) {
    case PROFIL_LIST_REQUEST: 
      return { loading: true,profil: [] };
    case PROFIL_LIST_SUCCESS:
      return { loading: false, success: true, profil: action.payload };
    case PROFIL_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
    }
  }




function profilDeleteReducer(state = { profil: {} }, action) {
 switch (action.type) {
    case PROFIL_DELETE_REQUEST:
        return { loading: true }; 
    case PROFIL_DELETE_SUCCESS:
        return { loading: false, profil: action.payload, success: true };
    case PROFIL_DELETE_FAIL:
        return { loading: false, error: action.payload };
    default:
        return state;
}
}

function profilDetailsReducer(state = { profil: {} }, action) {
switch (action.type) {
 case PROFIL_DETAILS_REQUEST:
   return { loading: true };
 case PROFIL_DETAILS_SUCCESS:
   return { loading: false, profil: action.payload };
 case PROFIL_DETAILS_FAIL:
   return { loading: false, error: action.payload };
 default:
   return state;
}
}

function profilSaveReducer(state = { profil: {} }, action) {
switch (action.type) {
 case PROFIL_SAVE_REQUEST:
   return { loading: true };
 case PROFIL_SAVE_SUCCESS:
   return { loading: false,profil: action.payload, success: true,  };
 case PROFIL_SAVE_FAIL:
   return { loading: false, error: action.payload };
 default:
   return state;
 }
}

export {
    profilListReducer ,
    profilDetailsReducer ,
    profilDeleteReducer,
    profilSaveReducer 
}