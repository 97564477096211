import React from 'react';

const GlobalContext = React.createContext({
    monthIndex:0,
    setMonthIndex: (index) => {},
    smallCalendarMonth:0,
    setSmallCalendarMonth : (index) => {},
    yearIndex:null,
    setYearIndex: (index) => {},
    smallCalendarYear:null,
    setSmallCalendarYear : (index) => {},
    daySelected:null,
    setDaySelected: (day) => {},
    showEventModal:false,
    setShowEventModal: () => {},
    showDialog:false,
    setShowDialog: () => {},
    dispatchInfoEvent: ({type,payload}) => {},
    savedInfoEvents:[],
    savedEvents:[],
    setSavedEvents:()=>{},
    selectedEvent:null,
    setSelectedEvent: () => {},
    setColors:() => {},
    colors: [],
    updatedColor: () => {},
    filteredEvents:[],
    getRdv : () => {},
    fetchData:[],
    setFetchData: () => {},


});

export default GlobalContext;