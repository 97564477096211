import styled from 'styled-components'

export const Container = styled.div`
    background-color: #131313;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 12rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
  
    @media screen  and (max-width:768px){
        display:none;
    }
`;

export const ProfilContainer = styled.div`
    margin-top: 1rem;
    z-index: 10;
    margin-bottom: 10px; 
    margin-left: -50px;
`;

export const ProfileImg = styled.img`
    height: 6rem;
    width: 6rem;
    display: block;
    border-radius: 50px;
    object-fit: cover;
    text-align: left;
`;

export const ProfileName = styled.h1`
    font-size: 0.5rem;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
`;


export const SidebarNav = styled.nav`
  width: 12rem;
  height: 60vh;
  display: flex;
  justify-content: center;
  overflow: auto;
  align-items: center;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 350ms;

  @media screen  and (max-width:768px){
        display:none;
    }
`;

export const SidebarWrap = styled.div`
  width: 100%;
  overflow: auto;
`;