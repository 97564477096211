import { 
    CUSTOMER_DELETE_FAIL,
    CUSTOMER_DELETE_REQUEST,
    CUSTOMER_DELETE_SUCCESS,
    CUSTOMER_LIST_FAIL,
    CUSTOMER_LIST_REQUEST,
    CUSTOMER_LIST_SUCCESS,
    CUSTOMER_SAVE_FAIL,
    CUSTOMER_SAVE_REQUEST,
    CUSTOMER_SAVE_SUCCESS 
} from "../constants/customerConstants";


 
 function customerListReducer(state = { customers: [] }, action) {
  switch (action.type) {
  case CUSTOMER_LIST_REQUEST: 
    return { loading: true,customers: [] };
  case CUSTOMER_LIST_SUCCESS:
    return { loading: false, success: true, customers: action.payload };
  case CUSTOMER_LIST_FAIL:
    return { loading: false, error: action.payload };
  default:
    return state;
  }
}


function customerDeleteReducer(state = { customer: {} }, action) {
 switch (action.type) {
    case CUSTOMER_DELETE_REQUEST:
        return { loading: true }; 
    case CUSTOMER_DELETE_SUCCESS:
        return { loading: false, customer: action.payload, success: true };
    case CUSTOMER_DELETE_FAIL:
        return { loading: false, error: action.payload };
    default:
        return state;
}
}


function customerSaveReducer(state = { customer: {} }, action) {
switch (action.type) {
 case CUSTOMER_SAVE_REQUEST:
   return { loading: true };
 case CUSTOMER_SAVE_SUCCESS:
   return { loading: false,  customer: action.payload ,success: true,};
 case CUSTOMER_SAVE_FAIL:
   return { loading: false, error: action.payload };
 default:
   return state;
 }
}

export {
    customerSaveReducer,
    customerDeleteReducer,
    customerListReducer
}