import React, { useEffect,useState } from 'react';
import { Container, Grid, Toolbar, Button, IconButton, Typography,TextField} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses
} from '@mui/x-data-grid'
import {CancelOutlined, EditOutlined} from '@mui/icons-material'
import styled from 'styled-components'
import ConfirmDialog from '../components/ConfirmDialog';
import { Box } from '@mui/system';
import Popup from '../components/Popup';
import { deleteService, listService, saveService } from '../actions/serviceActions';
import { toast } from 'react-toastify';
import {useNavigate} from 'react-router-dom'
import { publiqueRequest } from '../requestMethods';
import ServiceForm from '../components/Forms/ServiceForm';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';





export const MainContainer = styled.div`
    width: auto;
    height: 100vh;
    margin-left: 12rem;
    position: relative; 
   // padding: 0 1rem;
    color:#000;
    background-color: #e8eaeb;

    @media screen  and (max-width:1028px){
       height: 100%;
    }
   

    @media screen  and (max-width:768px){
        margin-left: 0px;
        padding: 0 0rem;
    }
`;

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display:"flex",
        justifyContent:"space-between"
      }}
    >
       <GridToolbarContainer className={gridClasses.toolbarContainer}>
           <GridToolbarExport color="success" />
        </GridToolbarContainer>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: 'auto',
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          '& .MuiSvgIcon-root': {
            mr: 0.5,
          },
          '& .MuiInput-underline:before': {
            borderBottom: 1,
            borderColor: 'divider',
          },
        }}
      />
    </Box>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  
};

 function Services(){
 
    const dispatch = useDispatch();
    const [services,setServices] = useState([]);
    const [isMounted, setIsMounted] = useState(false);
    const [searchText, setSearchText] = useState('');

    const serviceList = useSelector(state => state.serviceList);
    const {loading,services:servicesData, error} = serviceList;

    const serviceSave = useSelector(state => state.serviceSave);
    const {loading: loadingSave,success: successSave, error:errorSave,service:messageSave} = serviceSave;

    const serviceDelete = useSelector(state => state.serviceDelete);
    const {loading: loadingDelete,success: successDelete, error:errorDelete,service:messageDelete} = serviceDelete;


    const [confirmDialog, setConfirmDialog] = useState({ open: false});
    const [openPopup, setOpenPopup] = useState(false);
    const [recordForEdit, setRecordForEdit] = useState(null);

    const navigate = useNavigate();
    const userSignin = useSelector((state) => state.userSignin);
    
    
   useEffect(() => {
      if(userSignin.userInfo == null){
        navigate('/connexion')
      }
    }, [userSignin])


      const getServices = async () => {
        try {
        const res = await publiqueRequest.get("/services.php/");
        setServices(res.data);
        } catch (err) {
          console.log(err)
        }
      }; 
    
     useEffect(() => {
           getServices()
       }, [messageSave,messageDelete])
     
       const openInPopup = item => {
        setRecordForEdit(item)
        setOpenPopup(true)
      };

      const onDelete = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            open: false
        });
       
        if(dispatch(deleteService(id))){
            toast("Intervention supprimée avec succès!!!", {
              type: "info",
              theme:"dark"
            });
        }else{
          toast("Erreur lors de la suppression!!!", {
            type: "error",
            theme:"dark"
          });
        }  
      
     };

      const addOrEdit = async (obj,resetForm) => {
       
        try {
 
            if (obj._id === "") {
                 
                 if(dispatch(saveService(obj))){
                   toast("Intervention ajoutée  avec succès!!!", {
                       type: "success",
                       theme:"dark"
                   });
              
                  }
              }else{
                
                if(dispatch(saveService(obj))){
                 toast("Intervention mise à jour avec succès!!!", {
                     type: "success",
                     theme:"dark"
                 });
            
                }
             }
            resetForm()
            setRecordForEdit(null)
            setOpenPopup(false)
           
        } catch (error) {
            console.error(error);
          }
       }

       const requestSearch = (searchValue) => {
        if(searchValue!==""){
          setSearchText(searchValue);
          const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
          const filteredRows = services.filter((row) => {
            return Object.keys(row).some((field) => {
              return searchRegex.test(row[field].toString());
              
            });  
          
          });
        
          setServices(filteredRows);
        }else{
          setSearchText("")
          getServices();
        }
       
        
      }; 
      
 

       const columns = [
         
        {
          field: "action",
          headerName: "Action",
          width: 150,
          renderCell: (params) => {
            return (
              <>
                
                <IconButton 
                  style={{color:'blue'}}
                  onClick={() => {openInPopup(params.row)}}
                  
                  >
                        <EditOutlined fontSize="small"  />
                </IconButton>

                {
                 userSignin.userInfo.rules === "true" && (
                <IconButton 
                  style={{color:'red'}} 
                  onClick={() => {
                    setConfirmDialog({
                        open: true,
                        onConfirm: () => {onDelete(params.row._id) }
                      })
                    }}
                  >
                        <CancelOutlined fontSize="small"  />
                </IconButton>
                 )}
                
              
              </>
            );
          },
        },
        {field: "name", headerName: "Nom", width: 650 },
        {field: "coefficient",headerName: "Coefficient",width: 120},
        {field: "price",headerName: "Prix",width: 120},
      
      ];

    return (
        <>
        <MainContainer>
            <Container   maxWidth={false}>
                <Grid container  >
                      <Grid  item xs={12}>
                      <Toolbar>
                                
                                <Box display="flex" justifyContent="space-between"  sx={{flexGrow:1,display:{xs:'flex',md:'flex'}}} >
                                <Typography
                                  variant="h6"
                                  noWrap
                                  component="div"
                                  sx={{mr:2,display:{xs:'none',md:'flex'}}}
                                 >
                                   Interventions
                                  </Typography>
                                  <Button variant="outlined" color="success"
                                    onClick={() => {setOpenPopup(true); setRecordForEdit(null); }}
                                  >
                                    Ajouter
                                  </Button>
                                </Box>
                            </Toolbar>
                      </Grid>
                    
                      <Grid  item xs={12} >
                        <div style={{flex:4,height:'75vh', backgroundColor:'white'}} >
                                <DataGrid
                                    components={{ Toolbar: QuickSearchToolbar }}
                                    rows={services}
                                    disableSelectionOnClick
                                    columns={columns}
                                    getRowId={(row) => row._id}
                                    pageSize={10}
                                    checkboxSelection
                                    componentsProps={{
                                      toolbar: {
                                        value: searchText,
                                        onChange: (event) => requestSearch(event.target.value),
                                        clearSearch: () => requestSearch(''),
                                      },
                                    }}
                                />
                        </div>
                      </Grid>

                </Grid>
                <Popup
                   title="Formulaire des interventions"
                   openPopup={openPopup}
                   setOpenPopup={setOpenPopup}

                 > 
                   <ServiceForm
                       recordForEdit={recordForEdit}
                       addOrEdit={addOrEdit}
                      
                   />
                </Popup>
                <ConfirmDialog
                  confirmDialog={confirmDialog}
                  setConfirmDialog={setConfirmDialog}
                />
            </Container>

        </MainContainer>
        </>
       
       
    );
}


export default Services;