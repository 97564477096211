import React, { Fragment, useContext } from 'react'
import GlobalContext from '../../context/GlobalContext'
import Typography from '@mui/material/Typography'
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import { Grid } from '@mui/material';


const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: pink[600],
      '&:hover': {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: pink[600],
    },
  }));
  
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

function Labels() {
    const {colors, updatedColor} = useContext(GlobalContext) 
   
    return (
        <Fragment>
            <Typography 
                variant="subtitle2" 
                component="p" 
                color="initial"
                align='center'
                p={2}
                >
                 Mes rendez-vous
            </Typography>
            <Grid container style={{
                width: '100%',
                overflow: 'auto',
            }} 
            >
            {
                colors.map(({color:lbl,checked},idx)=>(
                <Grid  key={idx} item lg={3} xs={3}>
                    <Typography 
                       
                        variant="label"
                        component="label"
                        style={{
                            display:" block",
                            position: "relative",
                            cursor: "pointer",
                            fontSize: "22px"
                        }}
                    >
                        <Switch 
                                {...label}  
                                style={{color:`${lbl}`}} 
                                checked={checked}
                                onChange={()=>updatedColor({color:lbl,checked:!checked})}
                         /> 
                  
                    </Typography>
                </Grid>
                ))
            }
            </Grid>
        </Fragment>
    )
}

export default Labels
