import React, { useEffect,useState,useRef } from 'react';
import { 
    Container,
    Divider, 
    Grid, 
    Paper, 
    Toolbar, 
    Typography, 
    Button, 
    Box, 
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import styled from 'styled-components'
import {  useLocation, useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {  PictureAsPdf} from '@mui/icons-material';
import ReactToPrint from 'react-to-print';
import { publiqueRequest,baseHostUrlFiles,userRequest, baseHostUrlProfil } from '../requestMethods';
import { styled as styledMui} from '@mui/material/styles';
import { format } from 'date-fns';





export const MainContainer = styled.div`
    width: auto;
    height: 100vh;
    margin-left: 12rem;
    position: relative; 
    margin-top: 14px;
   // padding: 0 1rem;
    color:#000;
    background-color: #e8eaeb;

    @media screen  and (max-width:1028px){
       height: 100vh;
    }
   

    @media screen  and (max-width:768px){
        margin-left: 0px;
        padding: 0 0rem;
    }
`;

export const DivBox = styled.div`
      padding: 0 2rem;
    @media screen  and (max-width:768px){
        margin-left: 0px;
        padding: 0 0rem;
        height: 100vh;
    }
`;

  


  const Input = styledMui('input')({
    display: 'none',
  });

function Facture() {

    const location = useLocation();
    const dispatch = useDispatch();
    const [facture,setFacture] = useState({});
    const [customer,setCustomer] = useState({});
    const [factureDetails,setFactureDetails] = useState([]);
    const [factureDate,setFactureDate] = useState("")
    const factureId = location.pathname.split("/")[2];
    const customerId = location.state;
    const [patientId,setPatientId] = useState("");
    const [picture,setPicture] = useState("");
    const [profil,setProfil] = useState([]);

   

    const navigate = useNavigate();
    const userSignin = useSelector((state) => state.userSignin);

    const componentRef = useRef();

   console.log(location)
    useEffect(() => {
        if(userSignin.userInfo == null){
          navigate('/connexion')
        }
      }, [userSignin])

      useEffect(() => {
        let isAmounted = true;
        const getProfil = async () => {
            try {
             const res = await publiqueRequest.get("/profil.php/");
              setProfil(res.data);
              setPicture(res.data[0].photo)
            } catch (err) {
              console.log(err)
            }
          }; 
          getProfil();
         return ()=>{isAmounted = false}
       }, []);

      /* useEffect(() => {
        
        const getCustomer = async () => {
            try {
              const res = await publiqueRequest.get("/customers.php/?_id="+customerId);
              setCustomer(res.data);
            }catch (err) {
              console.log(err)
            }
          }; 
         getCustomer();
        
       }, []);*/

      useEffect(() => {
        let isAmounted = true;
        const getFacture = async () => {
            try {
             const res = await publiqueRequest.get("/factures.php/?_id="+factureId)
               setFacture(res.data)
               setFactureDate(res.data.date_facture.split("-")[0])
               const rescustomer = await publiqueRequest.get("/customers.php/?_id="+res.data.id_patient);
               setCustomer(rescustomer.data);
              
            } catch (err) {
              console.log(err)
            }
          }; 
         getFacture();
         return ()=>{isAmounted = false}
       }, []);

     
       useEffect(() => {
        let isAmounted = true;
            getFactureDetails();
         return ()=>{isAmounted = false}
       }, []);


     const getFactureDetails = async () => {
        try {
         const res = await publiqueRequest.get("/factures.php/?id_facture="+factureId);
           setFactureDetails(res.data);
        } catch (err) {
          console.log(err)
        }
      };
      
      
      

       function renderDetails(){
        if(factureDetails.length > 0){
          return(
                factureDetails.map((item)=>(
                <TableRow key={item._id}  style={{border:'2px solid #000'}} >
                        <TableCell style={{fontSize:12,border:'2px solid #000'}}>
                             {item?.date}
                        </TableCell>
                        <TableCell  style={{fontSize:12,border:'2px solid #000'}} >
                          {item?.teeth}
                        </TableCell>
                        <TableCell  style={{fontSize:12,border:'2px solid #000'}} >
                          {item?.intervention}
                        </TableCell>
                        <TableCell style={{fontSize:12,border:'2px solid #000'}}>
                           {item?.coefficient}
                        </TableCell>
                        <TableCell style={{fontSize:12,border:'2px solid #000'}}> 
                           {item?.price}
                        </TableCell>
                        <TableCell style={{fontSize:12,border:'2px solid #000'}}> 
                           {item?.price}
                        </TableCell>
                       
                </TableRow>
                 
               ))
            )   
           
        }
      } 
      


    


   
   
    return ( 
        <MainContainer>
            <Container   maxWidth={false} >
              
                <Grid container  spacing={2}>
                    <Grid  item xs={12} >
                        <Toolbar
                            style={{
                                justifyContent:'space-between'
                            }}
                        >
                           
                             <ReactToPrint
                                    trigger={() => 
                                       <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<PictureAsPdf/>}
                                    
                                        style={{
                                            textTransform:'none'
                                        }}
                                        
                                      >
                                        Imprimer
                                      </Button>
                                    }
                                    content={() => componentRef.current}
                             />
                           
                        </Toolbar>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                            <Paper 
                                style={{
                                    width:'100%',
                                    justifyContent:'center',
                                    marginTop:20,
                                    position: "relative"
                                   
                                }}
                                elevation={0}
                                ref={componentRef}
                            >
                                 <Box style={{margin:"3rem 1rem"}}  p={2} display="flex" justifyContent="space-between" >
                                        <div>
                                          {
                                           profil ?
                                            profil.map((item) => (
                                              <div key={item._id} >
                                              <Typography> <b> {item.name}</b> </Typography>
                                              <Typography> <b>Chirugien - Dentiste</b> </Typography>
                                              <Typography> <b>{item.adresse} </b> </Typography>
                                              <Typography> <b>{item.contact_1}</b>  </Typography>
                                             
                                             
                                              </div>
                                            ))
                                           :
                                           <div>
                                              <Typography> Cabinet Dentaire </Typography>
                                              <Typography> Tel : </Typography>
                                              <Typography> Adresse : </Typography>
                                           </div>
                                          }
                                        </div>
                                        <div  style={{alignItems:'center',display:"block",justifyContent:'center'}} >
                                             
                                                    <Typography> <b>{customer?.firstname}</b></Typography>
                                                    <Typography> <b>{customer?.lastname}</b></Typography>
                                                    <Typography> <b>{customer?.telephone}</b></Typography>
                                                    <Typography> <b>{customer?.adresse} </b></Typography>
                                                    <Typography> <b>Fait le {format(new Date(), 'dd/MM/yyyy')}  </b></Typography>
                                             
                                        </div>
                                </Box>
                                  <Box style={{display:"flex",justifyContent:"center"}} >
                                    <Typography
                                      style={{textAlign:"center", border:"2px solid #000",width:320}}
                                      variant="h4"
                                    >
                                      Facture N° {factureId}/{factureDate}
                                    </Typography>
                                  </Box>
                                  <Box p={2} style={{display:"flex",justifyContent:"center",margin:"0 1rem"}} >
                                    <Typography
                                      style={{fontWeight:600}}
                                      variant="p"
                                    >
                                     {facture?.message}
                                    </Typography>
                                  </Box>
                                
                                 <DivBox >
                                    <Table   aria-label="simple table">
                                        <TableHead>
                                           <TableRow style={{backgroundColor:"#d5d5d5", border:'2px solid #000'}}  >
                                                <TableCell  style={{border:'2px solid #000'}}> <b>Date de visite</b> </TableCell>
                                                <TableCell  style={{border:'2px solid #000'}}> <b>Dent</b> </TableCell>
                                                <TableCell  style={{border:'2px solid #000'}}> <b>Nature des soins</b> </TableCell>
                                                <TableCell  style={{border:'2px solid #000'}}> <b>Coef</b> </TableCell>
                                                <TableCell  style={{border:'2px solid #000'}}> <b>Montant en Fcfa</b> </TableCell>
                                                <TableCell  style={{border:'2px solid #000'}}> <b>Total en Fcfa</b> </TableCell>
                                           </TableRow> 
                                         </TableHead>
                                         <TableBody>
                                               {renderDetails()}
                                               <TableRow  style={{backgroundColor:"#d5d5d5", border:'2px solid #000'}}  >
                                                <TableCell>  </TableCell>
                                                <TableCell> </TableCell>
                                                <TableCell> </TableCell>
                                                <TableCell>  </TableCell>
                                                <TableCell  style={{border:'2px solid #000'}}> <b>Total : </b>  </TableCell>
                                                <TableCell  style={{border:'2px solid #000'}}> <b>{facture?.total}</b> Fcfa</TableCell>
                                           </TableRow>
                                           <TableRow  style={{backgroundColor:"#d5d5d5", border:'2px solid #000'}}  >
                                                <TableCell>  </TableCell>
                                                <TableCell> </TableCell>
                                                <TableCell> </TableCell>
                                                <TableCell>  </TableCell>
                                                <TableCell  style={{border:'2px solid #000'}} > <b>Assurance : </b> {facture?.assurance} % </TableCell>
                                                <TableCell  style={{border:'2px solid #000'}} > <b>{(Number(facture?.assurance)*Number(facture?.total))/100}</b> </TableCell>
                                           </TableRow>
                                           <TableRow  style={{backgroundColor:"#d5d5d5", border:'2px solid #000'}} >
                                                <TableCell>  </TableCell>
                                                <TableCell> </TableCell>
                                                <TableCell> </TableCell>
                                                <TableCell>  </TableCell>
                                                <TableCell  style={{border:'2px solid #000'}}> <b>Patient : </b> {Number(100-facture?.assurance)} %</TableCell>
                                                <TableCell  style={{border:'2px solid #000'}}> <b>{facture?.patient}</b> </TableCell>
                                           </TableRow>
                                           </TableBody>
                                    </Table>
                                    <Typography p={2} style={{textAlign:"end"}}>
                                        <b> LE DOCTEUR </b>
                                    </Typography>
                                 </DivBox>
                            </Paper>
                    </Grid>

                
                </Grid>
              
            </Container>
        </MainContainer>
    )
}

export default Facture
