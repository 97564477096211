export const CAT_LIST_REQUEST = 'CAT_LIST_REQUEST';
export const CAT_LIST_SUCCESS = 'CAT_LIST_SUCCESS';
export const CAT_LIST_FAIL = 'CAT_LIST_FAIL';

export const CAT_DETAILS_REQUEST = 'CAT_DETAILS_REQUEST';
export const CAT_DETAILS_SUCCESS = 'CAT_DETAILS_SUCCESS';
export const CAT_DETAILS_FAIL = 'CAT_DETAILS_FAIL';

export const CAT_SAVE_FAIL = 'CAT_SAVE_FAIL';
export const CAT_SAVE_SUCCESS = 'CAT_SAVE_SUCCESS';
export const CAT_SAVE_REQUEST = 'CAT_SAVE_REQUEST'

export const CAT_DELETE_FAIL = 'CAT_DELETE_FAIL';
export const CAT_DELETE_SUCCESS = 'CAT_DELETE_SUCCESS';
export const CAT_DELETE_REQUEST = 'CAT_DELETE_REQUEST'