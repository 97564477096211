export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const SUB_QUANTITY = "SUB_QUANTITY";
export const ADD_QUANTITY = "ADD_QUANTITY"; 
export const EDIT_ITEM = "EDIT_ITEM"; 
export const EDIT_ASSURANCE = "EDIT_ASSURANCE"; 
export const EDIT_CART = "EDIT_CART"; 
export const FACTURE_DELETE_FAIL = "FACTURE_DELETE_FAIL"; 
export const FACTURE_DELETE_REQUEST = "FACTURE_DELETE_REQUEST"; 
export const FACTURE_DELETE_SUCCESS = "FACTURE_DELETE_SUCCESS"; 
export const FACTURE_DETAILS_FAIL = "FACTURE_DETAILS_FAIL"; 
export const FACTURE_DETAILS_REQUEST = "FACTURE_DETAILS_REQUEST"; 
export const FACTURE_DETAILS_SUCCESS = "FACTURE_DETAILS_SUCCESS"; 
export const FACTURE_LIST_FAIL = "FACTURE_LIST_FAIL"; 
export const FACTURE_LIST_REQUEST = "FACTURE_LIST_REQUEST"; 
export const FACTURE_LIST_SUCCESS = "FACTURE_LIST_SUCCESS";  
export const FACTURE_SAVE_FAIL = "FACTURE_SAVE_FAIL"; 
export const FACTURE_SAVE_REQUEST = "FACTURE_SAVE_REQUEST"; 
export const FACTURE_SAVE_SUCCESS = "FACTURE_SAVE_SUCCESS"; 
export const CLEAR_CART = "CLEAR_CART"

   