
import { 
    SRV_DELETE_FAIL,
    SRV_DELETE_REQUEST,
    SRV_DELETE_SUCCESS,
    SRV_DETAILS_FAIL,
    SRV_DETAILS_SUCCESS,
    SRV_LIST_FAIL,
    SRV_LIST_REQUEST,
    SRV_LIST_SUCCESS,
    SRV_SAVE_FAIL,
    SRV_SAVE_SUCCESS

 } from "../constants/srvConstants"
import { publiqueRequest, userRequest } from "../requestMethods"


const listService = () => async(dispatch) => {
  
    try {

        dispatch({type: SRV_LIST_REQUEST})
        const {data}  = await userRequest.get('/services.php/')
        dispatch({type: SRV_LIST_SUCCESS,payload: data})
         
    } catch (error) {
        dispatch({type: SRV_LIST_FAIL,payload: error.message})
    }
       
}

const deleteService = (serviceId) => async (dispatch) => {
 
  try {
    dispatch({ type: SRV_DELETE_REQUEST, payload: serviceId });
    const { data } = await userRequest.delete('/services.php/?_id=' + serviceId);
    dispatch({ type: SRV_DELETE_SUCCESS, payload: data , success:true});
  } catch (error) { 
    dispatch({ type: SRV_DELETE_FAIL, payload: error.message });
  }
};



  
const saveService = (service) => async (dispatch) => {  
  try {
    dispatch({ type: SRV_SAVE_SUCCESS, payload: service });
    if(!service._id){
      const { data } = await userRequest.post('/services.php/', service);   
      dispatch({ type: SRV_SAVE_SUCCESS, payload: data,success:true}); 

    }else{
      const { data } = await userRequest.put('/services.php/',service);    
    dispatch({ type: SRV_SAVE_SUCCESS, payload: data,success:true});
    }
  } catch (error) {
    dispatch({ type: SRV_SAVE_FAIL, payload: error.message });
  }
};  



const detailsService = (serviceId) => async (dispatch) => {
    try {
      dispatch({ type: SRV_DETAILS_SUCCESS, payload: serviceId });
      const { data } = await userRequest.get('/services.php/?_id=' + serviceId);
      dispatch({ type: SRV_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: SRV_DETAILS_FAIL, payload: error.message });
    }
  };



export {
    listService,
    detailsService,
    saveService,
    deleteService
}