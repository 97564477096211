import React from 'react'
import Labels from './Labels'

function Sidebar() {
    return (
        <aside  
            style={{
                position:'relative',
                textAlign:'center'
            }} 
        >
          
            <Labels/>
        </aside>
    )
}

export default Sidebar
