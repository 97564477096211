import React, { useEffect,useState } from 'react';
import { Container, Grid, Toolbar, IconButton, Typography, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from '@mui/x-data-grid'
import {CancelOutlined, Schedule, Visibility} from '@mui/icons-material'
import styled from 'styled-components'
import ConfirmDialog from '../components/ConfirmDialog';
import { Box } from '@mui/system';
import Popup from '../components/Popup';
import { deleteRdv, saveRdv } from '../actions/rendezvousActions';
import { toast } from 'react-toastify';
import {Link, useNavigate} from 'react-router-dom'
import { publiqueRequest } from '../requestMethods';
import { scheduleRappel } from '../actions/scheduleActions';
import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import { deleteFacture, saveFacture } from '../actions/factureActions';


export const MainContainer = styled.div`
    width: auto;
    height: 100vh;
    margin-left: 12rem;
    position: relative; 
   // padding: 0 1rem;
    color:#000;
    background-color: #e8eaeb;

    @media screen  and (max-width:1028px){
       height: 100%;
    }
   

    @media screen  and (max-width:768px){
        margin-left: 0px;
        padding: 0 0rem;
    }
`;

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display:"flex",
        justifyContent:"space-between"
      }}
    >
       <GridToolbarContainer className={gridClasses.toolbarContainer}>
           <GridToolbarExport color="success" />
        </GridToolbarContainer>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: 'auto',
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          '& .MuiSvgIcon-root': {
            mr: 0.5,
          },
          '& .MuiInput-underline:before': {
            borderBottom: 1,
            borderColor: 'divider',
          },
        }}
      />
    </Box>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  
};


 function Factures(){
 
    const dispatch = useDispatch();
    const [factures,setFactures] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [rerender, setRerender] = useState(false);

  

    const factureSave = useSelector(state => state.factureSave);
    const {loading: loadingSave,facture:messageSave,success: successSave, error:errorSave} = factureSave;

    const factureDelete = useSelector(state => state.serviceDelete);
    const {loading: loadingDelete,facture:messageDelete,success: successDelete, error:errorDelete} = factureDelete;



    const [confirmDialog, setConfirmDialog] = useState({ open: false});
    const [openPopup, setOpenPopup] = useState(false);
    const [recordForEdit, setRecordForEdit] = useState(null);

    const navigate = useNavigate();
    const userSignin = useSelector((state) => state.userSignin);


    const requestSearch = (searchValue) => {
      if(searchValue!==""){
      setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredRows = factures.filter((row) => {
        return Object.keys(row).some((field) => {
           return searchRegex.test(row[field].toString());
          
        });
       
      });
       setFactures(filteredRows);
      }else{
        setSearchText("")
        getFactures();
      }
    };
    
    useEffect(() => {
      if(userSignin.userInfo == null){
        navigate('/connexion')
      }
    }, [userSignin])


      const getFactures = async () => {
        try {
          const res = await publiqueRequest.get("/factures.php/");
              setFactures(res.data);
          } catch (err) {
            console.log(err)
          }
      }; 


       useEffect(() => {
            getFactures();
        
       }, [rerender,successDelete])

       const openInPopup = item => {
        setRecordForEdit(item)
        setOpenPopup(true)
      };

      const onDelete = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            open: false
        });
        if(dispatch(deleteFacture(id))){
          toast("Facture supprimée avec succès!!!", {
            type: "info",
            theme:"dark"
          });
        }
       
        setRerender(!rerender)
        getFactures();
        
     };

    
      const addOrEdit = async (obj,resetForm) => {
       
        try {
 
            if (obj._id === "") {
                
                 if(dispatch(saveFacture(obj))){
                   toast("Facture ajoutée  avec succès!!!", {
                       type: "success",
                       theme:"dark"
                   });
              
                  }
              }else{
                
                if(dispatch(saveRdv(obj))){
                 toast("Facture ajoutée  mise à jour avec succès!!!", {
                     type: "success",
                     theme:"dark"
                 });
            
                }
             }
            resetForm()
            setRecordForEdit(null)
            setOpenPopup(false)
            getFactures();
        } catch (error) {
            console.error(error);
          }
       }
 

       const columns = [
       
     
       
        {
          field: "action",
          headerName: "Action",
          width: 100,
          renderCell: (params) => {
            return (
              <>
              
             {
                 userSignin.userInfo.rules === "true" && (
                <IconButton 
                  style={{color:'red'}} 
                  onClick={() => {
                    setConfirmDialog({
                        open: true,
                        onConfirm: () => {onDelete(params.row._id) }
                      })
                    }}
                  >
                        <CancelOutlined fontSize="small"/>
                </IconButton>
                 )}
                <Link to={'/facture/'+params.row._id} state={params.row.id_patient}>
                      <IconButton style={{color:'blue'}}>
                              <Visibility fontSize="small"/>
                      </IconButton>
                </Link>
              </>
            );
          },
        },
        {field: "_id", headerName: "Num facture", width: 120 },
        {field: "nom_patient", headerName: "Nom Patient", width: 200 },
        {field: "assurance",headerName: "% Assurance ",width: 120,
         renderCell:(params)=>{
           return(
            <>
              { <span>{params.row.assurance} % </span> }
            </>
           )
         }},
        {field: "patient", headerName: "Montant Patient", width: 150,
         renderCell:(params)=>{
            return(
            <>
              { <span>{params.row.patient} Fcfa </span> }
            </>
            )
          }}, 
        {field: "total",headerName: "Total Fcfa",width: 150,
        renderCell:(params)=>{
          return(
          <>
            { <span>{params.row.total} Fcfa </span> }
          </>
          )
        }}, 
        {field: "date_facture",headerName: "Date",width: 120},
      ];

    return (
        <>
        <MainContainer>
            <Container   maxWidth={false}>
                <Grid container  >
                      <Grid  item xs={12}>
                      <Toolbar>
                                
                                <Box display="flex" justifyContent="space-between"  sx={{flexGrow:1,display:{xs:'flex',md:'flex'}}}>
                                  <Typography
                                      variant="h6"
                                      noWrap
                                      component="div"
                                      sx={{mr:2,display:{xs:'none',md:'flex'}}}
                                   >
                                       Factures
                                  </Typography>
                                </Box>
                            </Toolbar>
                      </Grid>
                      <Grid  item xs={12} >
                        <div style={{flex:4,height:'75vh', backgroundColor:'white'}} >
                                <DataGrid
                                    components={{ Toolbar: QuickSearchToolbar }}
                                    rows={factures}
                                    disableSelectionOnClick
                                    columns={columns}
                                    getRowId={(row) => row._id}
                                    pageSize={8}
                                    checkboxSelection
                                    componentsProps={{
                                      toolbar: {
                                        value: searchText,
                                        onChange: (event) => requestSearch(event.target.value),
                                        clearSearch: () => requestSearch(''),
                                      },
                                    }}
                                />
                        </div>
                      </Grid>

                </Grid>
                <ConfirmDialog
                  confirmDialog={confirmDialog}
                  setConfirmDialog={setConfirmDialog}
                />
            </Container>

        </MainContainer>
        </>
       
       
    );
}


export default Factures;