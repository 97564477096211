import React,{useState,useEffect} from 'react'
import {
    Container,
    ProfilContainer,
    ProfileImg,
    ProfileName,
    SidebarNav,
    SidebarWrap,
} from './Elements'
import SubMenu from './SubMenu'
import { SidebarData } from './SidebarData';
import { baseHostUrlProfil, publiqueRequest } from '../../requestMethods';
import Img from '../../images/Fonq-3.png';
  


  
const Sidebar = () => {

  const [sidebar, setSidebar] = useState(true);
  const showSidebar = () => setSidebar(!sidebar);
  const [picture,setPicture] = useState("");
  const [profil,setProfil] = useState([]);

  useEffect(() => {
    let isAmounted = true;
    const getProfil = async () => {
        try {
         const res = await publiqueRequest.get("/profil.php/");
          setProfil(res.data);
          setPicture(res.data[0].photo)
        } catch (err) {
          console.log(err)
        }
      }; 
      getProfil();
     return ()=>{isAmounted = false}
   }, []);

    return (
        <>
          <Container>
                
                  <ProfilContainer >
                       <ProfileImg  src={picture ? baseHostUrlProfil+`${picture}` : Img}  />
                  </ProfilContainer>
                  <SidebarNav sidebar={sidebar}>
                    <SidebarWrap>
                      {SidebarData.map((item, index) => {
                        return <SubMenu  item={item} key={index} />;
                        })
                      }
                    </SidebarWrap>
                  </SidebarNav>
                  <ProfilContainer >
                       <ProfileImg  src={Img}  />
                  </ProfilContainer>
                  
           </Container>
        </>
    )
}

export default Sidebar
