import React,{useEffect} from 'react'
import {
     Button,
     Grid,
     Box, 
     Container, 
     TextField,
    } from '@mui/material'
import useForm from './useForm'


const initialFieldValues = {
    _id: '',
    name: '',
    coefficient:'',
    price:''
    
}




function ServiceForm({recordForEdit,addOrEdit,...props}) {

   
    const validate = () => {
        let temp = { ...errors }
        temp.name = values.name ? "" : "Le nom de l'intervention  est requis"
        temp.coefficient = values.coefficient ? "" : "Le coefficient  est requis"
        setErrors({
            ...temp
        })
        return Object.values(temp).every(x => x === "")
    }

    var {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleInputFileChange,
        resetForm
    } = useForm(initialFieldValues)

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values,resetForm)
        } 
    }

    useEffect(() => {
        if (recordForEdit !== null){
            setValues({
                ...recordForEdit
            })
            setErrors({})
        } 
    }, [recordForEdit])


    return (
        <Container>
            <Grid container>
             <form 
                noValidate  
                autoComplete="off" 
                onSubmit={handleSubmit}
             >
                                       
                                       <Grid  item lg={12} xs={12}>
                                               
                                               <TextField 
                                                   style={{marginBottom:10}} 
                                                   fullWidth 
                                                   variant="outlined" 
                                                   name="name" 
                                                   label="Nom" 
                                                   value={values.name}
                                                   type="text"
                                                   onChange={handleInputChange}
                                                   {...(errors.name && { error: true, helperText: errors.name })}
                                               />
                                        
                                              

                                                <TextField 
                                                   style={{marginBottom:10}} 
                                                   fullWidth 
                                                   variant="outlined"
                                                   name="coefficient" 
                                                   label="Coefficient" 
                                                   value={values.coefficient}
                                                   type="text"
                                                   onChange={handleInputChange}
                                                   {...(errors.coefficient && { error: true, helperText: errors.coefficient })}
                                               />
                                           
                                              <TextField 
                                                   style={{marginBottom:10}} 
                                                   fullWidth 
                                                   variant="outlined"
                                                   name="price" 
                                                   label="Prix" 
                                                   value={values.price}
                                                   type="text"
                                                   onChange={handleInputChange}
                                                   {...(errors.price && { error: true, helperText: errors.price })}
                                               />
                                               
                                               <Box p={2}>
                                                   <Button
                                                   style={{backgroundColor:'#7faf02'}}
                                                   variant="contained"
                                                   fullWidth
                                                   size="large"
                                                   color="primary"
                                                   type="submit"
                                                   >
                                                       {recordForEdit ? "Modifier" : "Ajouter"}
                                                   </Button>
                                               </Box>
                                       </Grid>
                                   
                                 </form>
                </Grid>
        </Container>
    )
}

export default ServiceForm