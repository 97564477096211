import React,{useEffect} from 'react'
import {
     Button,
     Grid,
     Box, 
     Container, 
     TextField,
     Select, 
     MenuItem, 
     InputLabel, 
     FormControl,
     InputAdornment,
    } from '@mui/material'
import useForm from './useForm'


const initialFieldValues = {
    _id:null,
    teeth:'',
    intervention:'',
    coefficient:'',
    price:null

}




function FactureForm({recordForEdit,addOrEdit,...props}) {

   
    const validate = () => {
        let temp = { ...errors }
        temp.coefficient = values.coefficient ? "" : "La coefficient est requise"
        temp.intervention = values.intervention ? "" : "Le soins est requis"
        temp.price = values.price ? "" : "Le montant est requis"
       
       
        setErrors({
            ...temp
        })
        return Object.values(temp).every(x => x === "")
    }

    var {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleInputFileChange,
        resetForm
    } = useForm(initialFieldValues)

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values,resetForm)
        } 
       
    }

    useEffect(() => {
        if (recordForEdit !== null){
            setValues({
                ...recordForEdit
            })
            setErrors({})
        } 
    }, [recordForEdit])

  

    return (
      
          
             <form 
                noValidate 
                autoComplete="off" 
                onSubmit={handleSubmit}
             >
             
                       <TextField 
                          style={{marginBottom:10}} 
                          id="teeth"
                          label="Dent"
                          type="text"
                          name="teeth"
                          fullWidth
                           value={values.teeth}
                          inputProps={{
                              startAdornment:
                                <InputAdornment position="start">
                                   Dent
                                </InputAdornment> 
                          }}
                          onChange={handleInputChange}
                            InputLabelProps={{
                            shrink: true,
                         }}
                      />
             
                <TextField 
                          style={{marginBottom:10}} 
                          id="soins"
                          size="small"
                          label="Nature des soins"
                          type="text"
                          rows="4"
                          name="intervention"
                          value={values.intervention}
                          fullWidth
                          inputProps={{
                              startAdornment:
                                <InputAdornment position="start">
                                   Soins
                                </InputAdornment> 
                          }}
                          onChange={handleInputChange}
                            InputLabelProps={{
                            shrink: true,
                         }}
                      />
                        <TextField 
                          style={{marginBottom:10}} 
                          id="coefficient"
                          label="Coefficient"
                          type="text"
                          name="coefficient"
                          value={values.coefficient}
                          fullWidth
                          inputProps={{
                              startAdornment:
                                <InputAdornment position="start">
                                   Soins
                                </InputAdornment> 
                          }}
                          onChange={handleInputChange}
                            InputLabelProps={{
                            shrink: true,
                           }}
                         />
                 
                    <TextField 
                          style={{marginBottom:10}} 
                          id="montant"
                          size="small"
                          label="Montant"
                          type="number"
                          name="price"
                          value={values.price}
                          fullWidth
                          inputProps={{
                              startAdornment:
                                <InputAdornment position="start">
                                   Soins
                                </InputAdornment> 
                          }}
                          onChange={handleInputChange}
                            InputLabelProps={{
                            shrink: true,
                         }}
                      />
                   <Box 
                        p={2} 
                        display="flex" 
                        justifyContent="center" 
                    >
                         <Button
                            style={{
                                backgroundColor:'#7faf02',
                                textTransform:'none',
                                color:"#fff",
                                width:200

                            }}
                            variant="contained"
                            type="submit"
                            size="large"
                            >
                               Modifier
                         </Button>            
                    </Box>
                      
                    
                                 
                                           
        </form>

    )
}

export default FactureForm